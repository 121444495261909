import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import alertContext from "../../contexto/alerta/alertContext";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import NuevoRcargo from "./NuevoRcargo";
import Rcargostd from "./Rcargostd";
import Spinner from "../elementos/Spinnermodal";
import cuentaContext from "../../contexto/cuenta/cuentaContext";
import CargoContext from "../../contexto/cargo/cargoContext";
import Cheque from "./ChequeTicket";
import Prompt from "../../assets/Prompt";

const Rcargosth = () => {
  ////////////////////////////////////////////
  // CONTEXT
  const cafeteriaContext = useContext(CafeteriaContext);
  const { traerCheque, cheque, actualizaCheque, loading, setLoading, cargos } =
    cafeteriaContext;
  const { setAlert } = useContext(alertContext);
  const { traerCuentaByHab } = useContext(cuentaContext);
  const { nuevoCargo } = useContext(CargoContext);
  const navigate = useNavigate();
  const ref = React.createRef();

  ///////////////////////////////////////////
  // STATE
  const {
    factura,
    //apertura,
    //cierre,
    mesa,
    rcargos,
    //roomservice,
    habitacion,
    status,
    impresa,
    importe,
    //pago,
    //propina,
  } = cheque;

  const { id } = useParams();
  const [date, setDate] = useState(new Date());
  const [impDesc, setImpDesc] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [enaHab, setenaHab] = useState(true);
  const [descuento, setDescuento] = useState(0);
  const [roomservice, setRoomservice] = useState(0);
  const [cambio, setCambio] = useState(0);
  const [mp, setMp] = useState("00");
  const [hab, setHab] = useState("0");
  const [facturable, setFacturable] = useState(false);
  const [total, setTotal] = useState(0);
  const [gtotal, setGtotal] = useState(0);
  const [pago, setPago] = useState(0);
  const [propina, setPropina] = useState(0);
  const [prompt, setPrompt] = useState(false);
  const [cajero, setCajero] = useState("");

  let tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds

  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  let [hour, minutes] = [
    (date.getHours() < 10 ? "0" : "") + date.getHours(),
    (date.getMinutes() < 10 ? "0" : "") + date.getMinutes(),
  ];

  /////////////////////////////////////////////
  //METODOS
  const moneyForm = (raw) => {
    const numero = parseFloat(raw);
    if (!isNaN(numero)) {
      const res = "$" + numero.toFixed(2);
      return res;
    } else {
      return "Sin Cargos";
    }
  };

  //////////////////////////////////////////////
  //AGREGAR CARGO A HABITACION
  const submitCargo = async (usr) => {
    const importe = impDesc / 1.16;
    const iva = importe * 0.16;

    const res = await traerCuentaByHab(hab);

    if (
      hab === 0 ||
      res.data.cierre !== "0000-00-00 00:00:00" ||
      parseFloat(res.data.credito) <= 0
    ) {
      setAlert("La habitación no tiene Autorizado Cargos", "danger");
      setLoading(false);
      throw new Error("No ingresó la clave de usuario");
    }
    if (res.success) {
      const cargo = {
        habitacion: hab,
        cuenta_id: res.data.cuenta,
        concepto: "Consumo",
        metodo_pago: "Cargo a Habitación",
        importe: importe,
        iva: iva,
        ieh: 0,
        referencia: "Cheque: " + cheque.id,
        comentario: "propina:" + parseFloat(propina).toFixed(2),
        cajero: usr,
      };
      const resp = await nuevoCargo(cargo);

      if (resp.success) {
        setAlert("Cuenta transferida", "success");
        setCajero("");
        return true;
      } else return setAlert("Error en la transferencia", "danger");
    } else return setAlert("Error en la transferencia", "danger");
  };

  const submitClave = (event) => {
    event.preventDefault();
    setPrompt(true);
  };

  //////////////////////////////////////////////
  //CAMBIO DE STATUS DE CUENTA
  const statusCuenta = async (user) => {
    //let obj = cheque;
    let obj = { id: cheque.id };
    setLoading(true);
    if (
      (parseFloat(impDesc) + parseFloat(propina)).toFixed(2) > parseFloat(pago)
    ) {
      setLoading(false);
      return setAlert("La cuenta no está balanceada", "danger");
    }
    if (cheque.status === "abierta") {
      obj = {
        ...obj,
        cierre: new Date(Date.now() - tzoffset)
          .toISOString()
          .slice(0, 19)
          .replace("T", " "),
        status: "cerrada",
        impresa: mp,
        descuento: descuento,
        importe: impDesc,
        habitacion: hab,
        cerro: user,
      };
      if (mp === "05") {
        if (hab !== "0" && hab !== null && hab !== "")
          if (await submitCargo(user)) obj = { ...obj, status: "transferida" };
          else {
            setLoading(false);
            setPrompt(false);
            return setAlert("Falló transferencia", "danger");
          }
      }
      if (parseInt(impDesc) === 0 || parseInt(cheque.importe) === 0) {
        obj = { ...obj, status: "cancelada", usuario: user };
      }
    } else {
      obj = {
        ...obj,
        cierre: null,
        status: "abierta",
        aperturo: user,
      };
    }
    await actualizaCheque(obj);
    traerCheque(id);
    if (obj.status === "abierta") setDisabled(false);
    else setDisabled(true);
  };

  //////////////////////////////
  //FUNCION QUE INICIA PROCESO DE FACTURACION
  const facturar = async () => {
    navigate(`/cfacturacion/${id}`);
  };

  ////////////////////////////////////
  //SELECTOR DE METODO DE PAGO
  const selector = (e) => {
    let desc = 0,
      hab = true;
    switch (e.target.value) {
      case "05":
        hab = false;
        break;
      case "06":
        desc = 50;
        break;
      case "07":
        desc = 20;
        break;
      default:
        setenaHab(true);
        desc = 0;
        break;
    }
    setDescuento(desc);
    setMp(e.target.value);
    setenaHab(hab);
  };

  const calc = () => {
    let tot = 0,
      granTotal = 0,
      efe = 0,
      prop = 0,
      abono = 0,
      pago = 0,
      cam = 0,
      tar = 0;
    if (rcargos !== undefined && typeof rcargos !== "string") {
      rcargos?.forEach((row) => {
        if (row.status === 1)
          switch (row.clave) {
            case 501:
              pago += parseFloat(row.importe);
              break;
            case 502:
              efe += parseFloat(row.importe);
              break;
            case 503:
            case 504:
              tar += parseFloat(row.importe);
              break;
            case 505:
              prop += parseFloat(row.importe);
              break;
            default:
              tot += parseFloat(row.importe);
              break;
          }
      });
    }
    granTotal =
      parseFloat(total) * ((100 - descuento) / 100) + parseFloat(prop);
    abono = pago + efe + tar;

    if (efe > granTotal - tar) cam = efe - (granTotal - tar);

    setGtotal(granTotal.toFixed(2));
    setPropina(prop);
    setPago(abono);
    setTotal(tot);
    setCambio(cam);
  };
  ////////////////////////////////
  //onChange Habitación
  const onChangeHab = (e) => {
    setHab(e.target.value);
  };

  useEffect(() => {
    calc();
    if (typeof cheque.id === "undefined") traerCheque(id);
    setHab(habitacion);
    setRoomservice(cheque?.roomservice);
    if (habitacion !== 0 || impresa === 5 || roomservice === 1) {
      setenaHab(true);
      setHab(habitacion);
    }
    if (cheque !== undefined && cheque.descuento > 0) {
      setDescuento(cheque.descuento);
      cheque.descuento = 0;
    }
    if (status === "abierta" && descuento > 0 && descuento < 100) {
      let descRecalc = importe * ((100 - descuento) / 100);
      setImpDesc(descRecalc);
      //calcTotal();
    } else {
      setImpDesc(importe);
      //calcTotal();
    }
    if (status === "cerrada") setFacturable(true);
    else setFacturable(false);

    if (status !== "abierta") setDisabled(true);
    else setDisabled(false);
    setInterval(() => setDate(new Date()), 30000); //reloj
  }, [cheque, descuento, impDesc, cargos]);

  return (
    <div className="xmodalOver">
      <Prompt
        onClose={setPrompt}
        show={prompt}
        setKey={setCajero}
        onValidKey={statusCuenta}
      />
      <h1 className="">
        <p>Cheque número: {id} </p>

        {date.toLocaleDateString("es-ES", options)}
        <p className="floatRight"> {hour + ":" + minutes}hrs </p>
      </h1>
      <div>
        <hr />

        <h2 className="grid-5">
          <div>
            <p>Mesa: {mesa}</p>
          </div>
          <div>
            <p className={roomservice === 1 ? "" : "noprint"}>
              Room Service:{roomservice === 1 ? "Si" : "No"}
            </p>
          </div>
          <div>
            <p className={roomservice || mp === 5 ? "" : "noprint"}>
              Habitación: {habitacion}
            </p>
          </div>
          <div>
            <p className="">Status: {status}</p>
          </div>
          <div>
            <p className="noprint">Factura: {factura}</p>
          </div>
        </h2>
        <hr />
        <NuevoRcargo />
        {loading && <Spinner />}
      </div>
      <br />
      <table className="w-100">
        <thead>
          <tr>
            <th className="noprint" width="7%">
              Id
            </th>
            <th width="10%">Cantidad</th>
            <th width="10%">Clave</th>
            <th width="20%">Descripción</th>
            <th width="10%">Precio</th>
            <th width="10%">Importe</th>
            <th width="10%">Fecha</th>
            <th width="10%">Hora</th>
            <th width="5%">Cajero</th>
            <th className="noprint">Cancelar</th>
          </tr>
        </thead>
        <tbody>
          {typeof cheque.rcargos === "object" ? (
            cheque.rcargos.map((res, index) => (
              <Rcargostd key={index} idc={index} cargo={res} />
            ))
          ) : (
            <tr>
              <td>{cheque.rcargos}</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2" className="noprint" />
            <th scope="row">Consumo:</th>
            <td>{moneyForm(total)}</td>
            <th>Propina:</th>
            <td>{moneyForm(propina)}</td>
            <th>Pagos:</th>
            <td>{moneyForm(pago)}</td>
            <th>Total: </th>
            <td>
              <strong className="medium">{moneyForm(gtotal)}</strong>
            </td>
          </tr>
          <tr>
            <th colSpan="2">
              <select
                value={mp}
                onChange={selector}
                disabled={disabled}
                required
                className="noprint"
              >
                <option value="00">- Pago Especial -</option>
                <option value="05">Cargo Habitación</option>
                <option value="06">Cargo a Empleado</option>
                <option value="07">Cortesía</option>
                <option value="99">Cuentas X Cobrar</option>
              </select>
            </th>
            <th colSpan="1" className="noprint">
              <input
                type="text"
                value={hab === 0 ? "" : hab}
                disabled={enaHab}
                placeholder="Habitación"
                onChange={onChangeHab}
              />
            </th>
            <th colSpan="1" className="noprint">
              <div style={{ display: "none" }}>
                <Cheque
                  desc={descuento}
                  hab={hab}
                  mp={mp}
                  cheque={cheque}
                  ref={ref}
                  total={gtotal}
                />
              </div>
              <ReactToPrint
                trigger={() => (
                  <button className="btn btn-primary">Imprimir</button>
                )}
                content={() => ref.current}
              />
            </th>
            <th>
              <button
                id="status"
                className="btn-primary btn noprint"
                onClick={submitClave}
                disabled={
                  status === "abierta" || status === "cerrada" ? false : true
                }
              >
                {status === "abierta"
                  ? "Cerrar Cuenta"
                  : status === "cerrada"
                  ? "Reabrir Cuenta"
                  : status}
              </button>
            </th>
            <th>
              <button
                id="facturar"
                className={
                  !facturable
                    ? "btn-block btn-danger noprint"
                    : "btn-block btn-success noprint"
                }
                disabled={!facturable}
                onClick={facturar}
              >
                Facturar
              </button>
            </th>
            <th colSpan="2" className="noprint">
              <p>DESCUENTO: {descuento}%</p>
              <input
                type="text"
                readOnly={disabled}
                value={descuento}
                onChange={(e) => setDescuento(e.target.value)}
              />
            </th>
            <th colSpan="2">
              <p className="medium">CAMBIO: {moneyForm(cambio)}</p>
            </th>
          </tr>
        </tfoot>
      </table>
      <span colSpan="2" className="floatRight fas"></span>
      <br />
      <hr />
    </div>
  );
};

export default Rcargosth;
