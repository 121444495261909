import React, { useContext, useEffect, useState } from "react";
import CuentaContext from "../../contexto/cuenta/cuentaContext.js";
import Facturaconceptosth from "./Facturaconceptosth.js";
import logo from "../../assets/logo.png";

const Estadodecuenta = React.forwardRef((props, ref) => {
  //////////////////////////////////////////////
  //context
  const cuentaContext = useContext(CuentaContext);
	const { traerCuenta, cuenta } = cuentaContext;
	////////////////////////////
	//destruc
	const {
		apellido,
		aperturo,
		cerro,
		ciudad,
		cp,
		conceptos,
		cuenta: cuenta_id,
		direccion,
		estado,
		habitaciones,
		iva,
		ieh,
		llegada,
		mail,
		nombre,
		regimen,
		rfc,
		rsocial,
		salida,
		status,
		subtotal,
	} = cuenta;
  let habitacionarr = "";
  let total = parseFloat(subtotal) + parseFloat(iva) + parseFloat(ieh);
  ////////////////////////////////////////
  //Empresa o publico en general
  const nombrecompleto = apellido+ " "+nombre;
  const frsocial = rsocial !== null ? rsocial : "PUBLICO EN GENERAL";
  const fdireccion = direccion !== null ? direccion : "Av. Adolfo López Mateos #320 Oriente";
  const fcp = cp !== null ? cp : "20000";
  const fciudad = ciudad !== null ? ciudad: "Aguascalientes";
  const festado = estado !== null ? estado : "Aguascalientes";
  const frfc = rfc !== null ? rfc : "XAXX010101000";
  const fregimen = regimen !== null ? regimen : 616;
  /////////////////////////////////////
  //conceptos
  if( typeof habitaciones !== "undefined"){
    habitaciones.forEach((row) => {
      if (habitacionarr === "") {
        habitacionarr = row.habitacion;
      } else {
        habitacionarr = ", " + row.habitacion;
      }
    });
  }

	////////////////////////////
	//propinas
	let propinas = 0;
	if (typeof conceptos !== "undefined" && conceptos !== "Cuenta sin cargos") {
		conceptos.forEach((row, i) => {
			if (row.concepto === "propina") {
				propinas += parseFloat(row.importe);
			}
		});
	}
	////////////////////////////
	//cnegocios
	let cnegocios = 0;
	if (typeof conceptos !== "undefined" && conceptos !== "Cuenta sin cargos") {
		conceptos.forEach((row, i) => {
			if (row.concepto === "CentroNegocios") {
				cnegocios += parseFloat(row.importe);
			}
		});
	}
	////////////////////////////
	//hospedaje
	let hospedaje = 0;
	if (typeof conceptos !== "undefined" && conceptos !== "Cuenta sin cargos") {
		conceptos.forEach((row, i) => {
			if (row.concepto === "habitacion") {
				hospedaje += parseFloat(row.importe);
			}
		});
	}
	////////////////////////////
	//cafeteria
	let cafeteria = 0;
	if (typeof conceptos !== "undefined" && conceptos !== "Cuenta sin cargos") {
		conceptos.forEach((row, i) => {
			if (row.concepto === "Consumo") {
				cafeteria += parseFloat(row.importe);
			}
		});
	}
	////////////////////////////
	//bar
	let bar = 0;
	if (typeof conceptos !== "undefined" && conceptos !== "Cuenta sin cargos") {
		conceptos.forEach((row, i) => {
			if (row.concepto === "bar") {
				bar += parseFloat(row.importe);
			}
		});
	}
	////////////////////////////
	//lavanderia
	let lavanderia = 0;
	if (typeof conceptos !== "undefined" && conceptos !== "Cuenta sin cargos") {
		conceptos.forEach((row, i) => {
			if (row.concepto === "lavanderia") {
				lavanderia += parseFloat(row.importe);
			}
		});
	}

	///////////////////////////////
	//money
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};

  return (
  <>
    <div style={{ padding:"5mm"}}>
      <header className='grid-3 p'>
        <div className='gspan-2 grid-2'>
          <h1 className='gspan-2' style={{fontSize:"5mm"}}>Real Plaza Aguascalientes S.A. De C.V.</h1>
          <div>
            <p className='medium my-03'>Adolfo López Mateos 320 Ote.</p>
            <p className='medium my-03'>C.P. 20000 Zona Centro.</p>
            <p className='medium my-03'>Tel. (449) 968-27-27</p>
            <p className='medium my-03'>Aguascalientes Ags. México.</p>
          </div>
          <div>
            <p className='medium my-03'>www.realplazaags.com</p>
            <p className='medium my-03'>RFC RPA210618HE5</p>
          </div>
        </div>
        <div style={{ width: "65%" }}>
          {" "}
          <img src={logo} alt='Logo nav' />
        </div>
        <div className='gspan-3 all-center-row w-100'>
          <p className='small'>Expedido en Aguascalientes Ags.</p>
          <p className='small'>601 Régimen General de Ley Personas Morales</p>
          <p className='medium my-03'>Estado de cuenta Solo Informativo</p>
        </div>
      </header>
      <section className='datos grid-2 grad p'>
        <article className='receptor'>
          <h3 className='medium my-03'>Cuenta: </h3>
          <p className='medium my-03'>
            Nombre: <strong className='medium my-03'>{nombrecompleto} </strong>
          </p>
          <p className='medium my-03'>
            Compañia: <strong className='medium my-03'>{frsocial}</strong>
          </p>
          <p className='medium my-03'>
            Direccion: <strong className='medium my-03'>{fdireccion}</strong>
          </p>
          <p className='medium my-03'>
            Código postal: <strong className='medium my-03'>{fcp}</strong>
          </p>
          <p className='medium my-03'>
            Estado país:{" "}
            <strong className='medium my-03'>
              {fciudad !== "" && fciudad + ","} {festado !== "" && festado + ","}{" "}
              México
            </strong>
          </p>
        </article>
        <article className='fechas'>
          <h3 className='floatright'>Folio:</h3>
          <p className='medium my-03'>
            RFC: <strong className='medium my-03'>{frfc}</strong>
          </p>
          <p className='medium my-03'>
            Régimen: <strong className='medium my-03'>{fregimen}</strong>
          </p>
          <p className='medium my-03'>
            Entrada: <strong className='medium my-03'>{llegada}</strong> Salida: <strong>{salida}</strong>
          </p>
          <p className='medium my-03'>
            forma de pago: <strong className='medium my-03'>01</strong>
          </p>
          <p className='medium my-03'>
            Método de Pago: <strong className='medium my-03'>PUE</strong>
          </p>
        </article>
      </section>
      <section style={{minHeight:"110mm"}}>
        {
          (typeof conceptos !== "undefined" && conceptos === "Cuenta sin cargos") ?"Sin movimientos" : <Facturaconceptosth />
        }
        
        <p className='small'>Comentario: Estado de cuenta.</p>
      </section>
      <section
        className='p my-1 w-100 pbrake'
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <table className="tightable" style={{width:"50%", marginLeft:"50%"}}>
          <tbody>
          <tr>
            <td className="text-right " colSpan={4}><p className='medium my-03'>Hab</p></td>
          </tr>
          <tr>
            <td className="text-right " colSpan={4}><p className='medium my-03'>{habitacionarr}</p></td>
          </tr>
          <tr>
            <td className="text-right "><p className='medium my-03'>Hospedaje</p></td>
            <td className="text-right "><h3 className='medium my-03'>{moneyForm(hospedaje)}</h3></td>
            <td className="text-right "> <p className='medium my-03'>I.V.A. 16%</p></td>
            <td className="text-right "> <h3 className='medium my-03'>{moneyForm(iva)}</h3></td>
          </tr>
          <tr>
            <td className="text-right "><p className='medium my-03'>Cafetería</p></td>
            <td className="text-right "><h3 className='medium my-03'>{moneyForm(cafeteria)}</h3></td>
            <td className="text-right "><p className='medium my-03'>Imp. Est.3%</p></td>
            <td className="text-right "><h3 className='medium my-03'>{moneyForm(ieh)}</h3></td>
          </tr>
          <tr>
            <td className="text-right "><p className='medium my-03'>Bar</p></td>
            <td className="text-right "><h3 className='medium my-03'>{moneyForm(bar)}</h3></td>
            <td className="text-right "><p className='medium my-03'>Propinas</p></td>
            <td className="text-right "><h3 className='medium my-03'>{moneyForm(propinas)}</h3></td>
          </tr>
          <tr>
            <td className="text-right "><p className='medium my-03'>Lavandería</p></td>
            <td className="text-right "><h3 className='medium my-03'>{moneyForm(lavanderia)}</h3></td>
            <td className="text-right "><p className='medium my-03'>
              <strong>Total</strong>
            </p></td>
            <td className="text-right "><h3 className='medium my-03'>{moneyForm(total-propinas)}</h3></td>
          </tr>
          <tr>
          <td className="text-right "> <p className='medium my-03'>C. Negocios</p></td>
            <td className="text-right "><h3 className='medium my-03'>{moneyForm(cnegocios)}</h3></td>
            <td className="text-right "><p className='small my-03'>
              <strong className='small'>Total con propina:</strong>
            </p></td>
            <td className="text-right "><h3 className='small my-03'>{moneyForm(total)}</h3></td>
          </tr>
          </tbody>
        </table>
      </section>
      <div className='w-100'>
        <p className='small'>Uso de CFDI por definir</p>
      </div>
    </div>
  </>
  )
});

export default Estadodecuenta;
