import React, { useContext, useState } from "react";
import CuentaContext from "../../contexto/cuenta/cuentaContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";

const BuscCargosByCta = () => {
	//////////////////////////////
	// Contextos
	const cuentaContext = useContext(CuentaContext);
	const { traerCuenta, limpiarEsclavas } = cuentaContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	//////////////////////////////
	// States
	const [cuenta, setCuenta] = useState("");

	/////////////////////////////
	// onChanges
	const onChangeCta = (e) => setCuenta(e.target.value);

	/////////////////////////////
	//métodos
	const onSubHab = async (e) => {
		e.preventDefault();
		limpiarEsclavas();
		const resp = await traerCuenta(cuenta);
		console.log(resp);
		resp.success
			? setAlert(`Se encontró la siguiente cuenta`, "success")
			: setAlert(`No se encontraron cuentas con esos parámetros`, "danger");
	};

	return (
		<section>
			<form onSubmit={onSubHab} className='grid-2'>
				<input
					type='text'
					name='cuenta'
					value={cuenta}
					onChange={onChangeCta}
					className='my'
				/>
				<input
					type='submit'
					className='btn btn-dark btn-block my'
					value='Buscar por cuenta'
				/>
			</form>
		</section>
	);
};

export default BuscCargosByCta;
