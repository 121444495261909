import React, { useState, useContext } from "react";
import Registrosth from "../registros/Registrosth.js";
import RegistroContext from "../../contexto/registro/registroContext.js";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import RegistroSingle from "../registros/RegistroSingle.js";
import RegistroBusqueda from "../registros/RegistroBusqueda.js";
import RegistroDirecto from "../registros/RegistroDirecto.js";

const Registros = () => {
	const registroContext = useContext(RegistroContext);
	const facturacionContext = useContext(FacturacionContext);
	const { limpiarEmpresa } = facturacionContext;
	const { modal } = registroContext;
	const [newRegistro, setNewRegistro] = useState(false);

	const toggleNewReserva = () => {
		if (newRegistro) {
			setNewRegistro(false);
		} else {
			setNewRegistro(true);
			limpiarEmpresa();
		}
	};
	return (
		<div>
			<h1>
				Registros
				<span className='floatRight'>
					{newRegistro ? (
						<i className='fas fa-minus-square' onClick={toggleNewReserva}></i>
					) : (
						<i className='fas fa-plus-square' onClick={toggleNewReserva}></i>
					)}
				</span>
			</h1>
			{newRegistro && <RegistroDirecto togg={toggleNewReserva} />}
			<RegistroBusqueda />
			<Registrosth />
			{modal ? <RegistroSingle /> : null}
		</div>
	);
};

export default Registros;
