import {
	USUARIOS,
	USUARIO,
	SET_LOADING,
	CLEARU,
	UNSET_LOADING,
} from "../types.js";
const userReducer = (state, action) => {
	switch (action.type) {
		case SET_LOADING:
			return {
				...state,
				userLoading: true,
			};
		case UNSET_LOADING:
			return {
				...state,
				userLoading: false,
			};
		case USUARIOS:
			return {
				...state,
				usuarios: action.payload,
				userLoading: false,
			};
		case USUARIO:
			return {
				...state,
				usuario: action.payload,
				userLoading: false,
			};
		case CLEARU:
			return {
				...state,
				usuarios: [],
				userLoading: false,
			};
		default:
			return state;
	}
};
export default userReducer;
