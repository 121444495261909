import { CHEQUE, CHEQUES, CARGOS, LOADING } from "../types";

const CargoReducer = (state, action) => {
  switch (action.type) {
    case CHEQUE:
      return { ...state, cheque: action.payload };
    case CHEQUES:
      return { ...state, cheques: action.payload };
    case CARGOS:
      return { ...state, cargos: action.payload };
    case LOADING:
      return { ...state, loading: action.payload };
    case "FACTURAS":
      return {
        ...state,
        vistaFacturas: action.payload,
        vistaReporte: !action.payload,
      };
    case "REPORTES":
      return {
        ...state,
        vistaFacturas: false,
        vistaReporte: action.payload,
      };
    default:
      return state;
  }
};

export default CargoReducer;
