import React from "react";
import Rentasth from "./Rentasth";
const sabana = () => {
	const ahora = new Date();
	const dia = ahora.toLocaleDateString("es-Mx", {
		day: "numeric",
		month: "long",
		year: "numeric",
	});
	const hora = ahora.toLocaleTimeString();
	console.log(dia);
	return (
		<div>
			<div className='grid-2'>
				<h2>Sábana de Rentas</h2>
				<p className='text-right'>
					Habitaciones activas a: <strong>{dia + " " + hora}</strong>
				</p>
			</div>
			<Rentasth />
		</div>
	);
};

export default sabana;
