import React, { useContext, useEffect, useState } from "react";
import facturacionContext from "../../contexto/facturacion/facturacionContext";
import { conceptos } from "../../assets/conceptosSerieC";
import base from "../../assets/Factura4.json";
import AlertContext from "../../contexto/alerta/alertContext";

const Facturacioncth = (props) => {
  ////////////////////////////////////
  //CONTEXTO
  const { factura, generarFactura, traerFolio, folio } =
    useContext(facturacionContext);
  const { setAlert } = useContext(AlertContext);
  ////////////////////////////////////
  //STATE
  const [id, setId] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [propina, setPropina] = useState(0);
  const [iva, setIva] = useState(0);
  const [ish, setIsh] = useState(0);
  const [cantidad, setCantidad] = useState(1);
  const [precio, setPrecio] = useState(0);
  const [select, setSelect] = useState("");
  const [conceptos2, setConceptos] = useState([]);
  const [fact, setFact] = useState(base);
  ///////////////////////////////////
  //METODOS
  const selector = (e) => {
    setSelect(e.target.value);
  };

  const moneyForm = (raw) => {
    const numero = parseFloat(raw);
    if (!isNaN(numero)) {
      const res = "$" + numero.toFixed(2);
      return res;
    } else {
      return "Sin Cargos";
    }
  };

  const agregarConcepto = () => {
    if (select.length === 0) {
      setAlert("seleccione un concepto", "danger");
      return;
    }
    let iva = 0,
      importe = 0,
      ish = 0;
    const conceptos1 = JSON.parse(JSON.stringify(conceptos));

    let cptos = conceptos2;
    let concepto = conceptos1[select];
    setId(id + 1);
    if (concepto.ClaveProdServ === "90111800") {
      importe = (cantidad * precio) / 1.19;
      iva = Math.round(importe * 16) / 100;
      ish = Math.trunc(importe * 3) / 100;
    } else if (concepto.ClaveProdServ === "90111601") {
      importe = cantidad * precio;
      iva = 0;
      ish = 0;
    } else {
      importe = (cantidad * precio) / 1.16;
      iva = Math.round(importe * 16) / 100;
      ish = 0;
    }
    concepto.NoIdentificacion = folio;
    importe = Math.round((cantidad * precio - iva - ish) * 100) / 100;
    concepto.ValorUnitario =
      Math.round(((precio * cantidad - ish - iva) / cantidad) * 100) / 100;
    concepto.ish = ish;
    concepto.Cantidad = cantidad;
    concepto.Importe = importe;

    concepto.Impuestos.Traslados = [
      {
        ...concepto.Impuestos.Traslados[0],
        Base: importe,
        Impuesto: "002",
        Importe: iva,
      },
    ];
    console.log(concepto);
    //conceptos.push(concepto);
    cptos = [...cptos, concepto];
    setConceptos(cptos);
    //calculo()
    setCantidad(1);
    setPrecio(0);
  };

  const calculo = () => {
    let ivat = 0,
      importet = 0,
      importeh = 0,
      isht = 0,
      prop = 0;
    let f = fact;
    if (conceptos2.length > 0)
      conceptos2.forEach((res) => {
        importet += res.Importe;
        if (res.ClaveProdServ === "90111800") importeh += res.Importe;
        if (res.ClaveProdServ === "90111601") prop += res.Importe;
        ivat += res.Impuestos.Traslados[0].Importe;
        isht += res.ish;
      });

    const trasladoPropina = {
      Base: prop,
      Impuesto: "002",
      TipoFactor: "Tasa",
      TasaOCuota: "0.000000",
      Importe: 0,
    };
    setSubtotal(importet);
    setIva(ivat);
    setIsh(isht);
    f.Comprobante.Serie = props.serie;
    f.Comprobante.Folio = folio;
    f.Comprobante.SubTotal = importet;
    f.Comprobante.Total = importet + ivat + isht;
    f.Comprobante.Conceptos = conceptos2;
    f.Comprobante.Impuestos.TotalImpuestosTrasladados = ivat;

    f.Comprobante.Impuestos.Traslados = [
      {
        Base: importet - prop,
        Impuesto: "002",
        TipoFactor: "Tasa",
        TasaOCuota: "0.160000",
        Importe: ivat,
      },
    ];
    if (prop > 0) f.Comprobante.Impuestos.Traslados.push(trasladoPropina);
    f.Comprobante.Complemento = [
      {
        ImpuestosLocales: {
          version: "1.0",
          TotaldeRetenciones: "0.00",
          TotaldeTraslados: isht,
          TrasladosLocales: [
            {
              Base: importeh,
              TipoFactor: "Tasa",
              ImpLocTrasladado: "ISH",
              TasadeTraslado: "0.03",
              Importe: isht,
            },
          ],
        },
      },
    ];

    setFact({
      ...fact,
      Comprobante: { ...fact.Comprobante, ...f.Comprobante },
      CamposPDF: { ...f.CamposPDF },
      logo: f.logo,
    });
    generarFactura({
      ...factura,
      Comprobante: { ...factura.Comprobante, ...f.Comprobante },
      CamposPDF: { ...f.CamposPDF },
      propina: prop,
      logo: f.logo,
    });
  };

  const Elemento = ({ ele, id }) => {
    return (
      <tr>
        <td>{ele.NoIdentificacion}</td>
        <td>{ele.ClaveProdServ}</td>
        <td>{ele.Cantidad}</td>
        <td>{ele.Descripcion}</td>
        <td>{moneyForm(ele.ValorUnitario)}</td>
        <td>{moneyForm(ele.Importe)}</td>
        <td>
          <button className="noprint" onClick={() => eliminaElemento(id)}>
            <img src="/Eliminar.png" alt="icon"></img>
          </button>
        </td>
      </tr>
    );
  };

  const eliminaElemento = (id) => {
    let lista = conceptos2;
    lista.splice(id, 1);
    setConceptos(lista);
    calculo();
  };

  ///////////////////////////////////
  //clear
  const clearForm = () => {
    setCantidad(1);
    setPrecio(0);
    setSelect("");
    setConceptos([]);
    props.setClear(false);
  };

  useEffect(() => {
    traerFolio(props.serie);
    const gfactura = async () => {
      if (Object.keys(factura).length === 0) await generarFactura(base);
    };
    if (Object.values(factura) === 0) gfactura();
    calculo();
    if (props.clear) {
      clearForm();
    }
  }, [conceptos2, props.serie, props.clear]);

  return (
    <>
      <div className="grid-4 noprint">
        <div>
          <label htmlFor="concepto">Concepto de venta</label>
          <select onChange={selector} name="concepto" required>
            <option value={15}>--Seleccione--</option>
            {conceptos.map((row, i) => {
              return (
                <option key={i} value={i}>
                  {row.Descripcion}
                </option>
              );
            })}
          </select>
        </div>

        <div>
          <label htmlFor="monto">Monto del servicio</label>
          <input
            name="monto"
            type="number"
            min="0"
            size={10}
            value={precio}
            onChange={(e) => setPrecio(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="cantidad">Cantidad</label>
          <input
            name="cantidad"
            type="number"
            size={10}
            min="1"
            value={cantidad}
            onChange={(e) => setCantidad(e.target.value)}
          ></input>
        </div>
        <button className="btn btn-primary" onClick={agregarConcepto}>
          Agregar Concepto
        </button>
      </div>
      <br />
      <table className="w-100">
        <thead>
          <tr className="">
            <th width="10%">REFERENCIA</th>
            <th width="10%">CLAVE</th>
            <th width="10%">CANTIDAD</th>
            <th width="30%">CONCEPTO</th>
            <th width="10%">P. UNITARIO</th>
            <th width="10%">IMPORTE</th>
            <th className="noprint" width="10%">
              Eliminar
            </th>
          </tr>
        </thead>
        <tbody>
          {conceptos2.length > 0 ? (
            conceptos2.map((res, i) => {
              return <Elemento key={i} ele={res} id={i} />;
            })
          ) : (
            <tr>
              <td></td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="4" />
            <th>SUBTOTAL</th>
            <td>${parseFloat(subtotal).toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan="4" />
            <th>IVA</th>
            <td>{moneyForm(iva)}</td>
          </tr>
          {ish > 0 ? (
            <tr>
              <td colSpan="4" />
              <th>ISH</th>
              <td>{moneyForm(ish)}</td>
            </tr>
          ) : (
            <tr></tr>
          )}
          <tr>
            <td colSpan="4" />
            <th>TOTAL</th>
            <td>{moneyForm(ish + subtotal + iva)}</td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default Facturacioncth;
