import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const Dona = (props) => {
	console.log(props.data);

	var data = {
		labels: ["Disponibles", "Ocupadas", "Sucias", "Fuera de Servicio"],
		datasets: [
			{
				label: "Habitaciones",
				data: [
					props.data.disponibles,
					props.data.ocupadas,
					props.data.sucias,
					props.data.fds,
				],
				backgroundColor: [
					"rgba(144,238,144, 1)",
					"rgba(211,211,211, 1)",
					"rgba(255, 192, 203, 1)",
					"rgba(173,216,230, 1)",
				],
				borderColor: [
					"rgba(99, 193, 91, 1)",
					"rgba(190,190,190, 1)",
					"rgba(229, 139, 154, 1)",
					"rgba(138,192,209, 1)",
				],
				borderWidth: 1,
			},
		],
	};
	return <Doughnut data={data} />;
};

export default Dona;
