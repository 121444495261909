import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CuentaContext from "../../contexto/cuenta/cuentaContext.js";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import Facturar33 from "../facturas/Facturar33.js";
import Facturarglobal from "../facturas/Facturarglobal.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
const Facturas = () => {
	const cuentaContext = useContext(CuentaContext);
	const facturacionContext = useContext(FacturacionContext);
	const alertContext = useContext(AlertContext);
	const { cuenta, traerCuenta, traerCuentaByHab } = cuentaContext;
	const { limpiarEmpresa } = facturacionContext;
	const { setAlert } = alertContext;
	const [cuentaXFa, setCuentaXFa] = useState(0);
	const [tipo, setTipo] = useState(0);
	const [fac, setFac] = useState(false);
	const { folio } = useParams();
	const cuentaL = Object.keys(cuenta).length;
	//////////////////////////////////
	// set Cuenta
	const setCuenta = (e) => {
		setCuentaXFa(e.target.value);
	};

	//////////////////////////////////
	const buscarCuentaph = () => {
		if (folio == 0 && cuentaXFa == 0) {
			setAlert("ingrese una habitacion para buscar la cuenta", "danger");
		} else if (folio > 0) {
			traerCuentaByHab(folio);
		} else if (cuentaXFa > 0) {
			traerCuentaByHab(cuentaXFa);
		}
	};

	/////////////////////////////////
	const tipotogg = (e) => {
		limpiarEmpresa();
		let re = e.target.value;
		re === "PUB" ? setTipo(2) : setTipo(1);
	};
	////////////////////////////////
	useEffect(() => {
		if (Object.keys(cuenta).length === 0) {
			if (folio !== null) {
				traerCuenta(folio);
			}
		}
	}, [cuenta]);

	return (
		<div>
			{cuentaL === 0 ? (
				<div className='text-center mt-05'>
					<p className='small'>
						Busque una cuenta para facturar utilizando el botón y selecione el tipo de
						comprobante
					</p>
				</div>
			) : null}
			<div className='grid-3 noprint'>
				<h2>Facturación{`de cuenta ${cuentaXFa}`}</h2>
				<div>
					<div className='all-centered-row'>
						<p style={{ display: "inline-block" }}>Tipo:</p>
						<input
							className='m-1'
							type='radio'
							id='tipofac1'
							name='tipofac'
							value='G03'
							onChange={tipotogg}
						/>
						<label htmlFor='tipofac'>RFC</label>
						<input
							className='m-1'
							type='radio'
							id='tipofac2'
							name='tipofac'
							value='PUB'
							onChange={tipotogg}
						/>
						<label htmlFor='tipofac'>Público</label>
					</div>
				</div>
				<div className='grid-3 mt-05 mb-1'>
					<div>
						<input
							type='number'
							value={cuentaXFa}
							onChange={setCuenta}
							placeholder='habitacion'
							className='p'
						/>
					</div>
					<button onClick={buscarCuentaph} className='btn btn-dark p gspan-2'>
						<p className='small'>Buscar por Habitación</p>
					</button>
				</div>
			</div>
			{tipo === 1 ? <Facturar33 /> : tipo === 2 ? <Facturarglobal /> : null}
		</div>
	);
};

export default Facturas;
