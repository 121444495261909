import React, { useState, useContext, useEffect } from "react";
import Relog from "../elementos/Relog";
import CargoContext from "../../contexto/cargo/cargoContext.js";
import Cargosthlight from "../cargos/Cargosthlight.js";
import AlertContext from "../../contexto/alerta/alertContext";
import Abonosthlight from "../cargos/Abonosthlight.js";
import Reportefacturath from "../facturas/Reportefacturath";
import Busquedarep from "../cargos/Busquedarep";
import clavesctrl from "../../assets/clavesctrl.js";
import ReactToPrint from "react-to-print";

const Corte = () => {
	const cargoContext = useContext(CargoContext);
	const alertContext = useContext(AlertContext);
	const { cerrarTurno } = cargoContext;
	const { setAlert } = alertContext;
	const [turno, setTurnoPadre] = useState("");
	const [showBtn, setShowBtn] = useState(false);
	const [tab, setTab] = useState("caja");
	const hoyrw = new Date();
	const hoyr = new Date(hoyrw.getTime() - hoyrw.getTimezoneOffset() * 60000);
	const hoy = hoyr.toISOString().split("T")[0];
	const meses = [
		"dud",
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];
	let phoy = hoy.slice(0, 10).split("-");
	const [fechab, setFechab] = useState(
		phoy[2] + " de " + meses[parseInt(phoy[1])] + " de " + phoy[0]
	);
	///////////////////////////////
	const [usr, setUsr] = useState("");
	const dialogo = document.getElementById("diag");
	const onChangeUsr = (e) => setUsr(e.target.value);
	const refcaja = React.createRef();
	/////////////////////////////////////
	// validador
	const usrValidator = (usr, set) => {
		return new Promise((resolve, reject) => {
			let resp = set.find((row) => parseInt(usr) === row.clave);
			console.log(resp);
			if (typeof resp !== "undefined") {
				dialogo.close();
				resolve(resp.usuario);
			} else {
				dialogo.close();
				reject(Error("No tiene privilegios para realizar esta operación"));
			}
		});
	};
	///////////////////////////////////
	//modal tog
	const modalUp = (e) => {
		e.preventDefault();
		dialogo.showModal();
	};

	///////////////////////////////
	//cerrarTurnooC
	const cerrarTurnoOnC = async () => {
		usrValidator(usr, clavesctrl)
			.then((r) => {
				const resp = cerrarTurno(r);
				resp
					.then((r) => {
						if (r.data.success) {
							setAlert("el turno ha sido cerrado", "success");
						} else {
							setAlert(r.data.messages[0], "danger");
						}
					})
					.catch((r) => {
						setAlert("fallo al cierre de turno verifique con  soporte", "danger");
					});
			})
			.catch(() => {
				setAlert("No tiene privilegios para realizar esta operación", "danger");
			});
	};

	//////////////////////////////
	// tab control
	const tabControl = (e) => {
		setTab(e.target.value);
	};

	/////////////////////////////
	// fecha control
	const fechaControl = (fecha) => {
		let fechaf = fecha.split("-");
		let fechax =
			fechaf[2] + " de " + meses[parseInt(fechaf[1])] + " de " + fechaf[0];
		setFechab(fechax);
	};

	useEffect(() => {}, [fechab, showBtn]);
	return (
		<div>
			<header className='grid-3 noprint'>
				<h1>Corte</h1>
				<button className='my-1 btn btn-dark' onClick={modalUp}>
					Cerrar Turno
				</button>
				<div className='text-right'>
					<Relog />
				</div>
				<div className='w-100 text-center'>
					<button onClick={cerrarTurnoOnC}></button>
				</div>
			</header>

			<section className=''>
				<Busquedarep
					fechaData={fechaControl}
					setTurnoPadre={setTurnoPadre}
					setShowBtn={setShowBtn}
				/>
			</section>
			<section>
				<button className='btn btn-dark noprint' onClick={tabControl} value='caja'>
					Corte de Caja
				</button>
				<button
					className='btn btn-dark noprint'
					onClick={tabControl}
					value='transacciones'
				>
					Transacciones
				</button>
				<button className={`btn btn-dark`} onClick={tabControl} value='facturas'>
					Facturas
				</button>
				{showBtn ? (
					<ReactToPrint
						trigger={() => (
							<button className='btn btn-dark cerrarCuentabtn floatRight'>
								Imprimir Corte
							</button>
						)}
						content={() => refcaja.current}
					/>
				) : null}
			</section>
			<section className={`tabs w-100  ${!showBtn && "noprint"}`} ref={refcaja}>
				{tab === "caja" && <Abonosthlight fecha={fechab} turno={turno} />}
				{tab === "transacciones" && <Cargosthlight fecha={fechab} />}
				{tab === "facturas" && <Reportefacturath fecha={fechab} />}
			</section>
			<dialog id='diag' className='modal-xs'>
				<form method='dialog'>
					<p className='medium text-center'>
						Está por cerrar el turno actual, asegurese de imprimir su corte antes,
						ingrese su clave para confirmar
					</p>
					<div className='my'>
						<input
							type='password'
							name='clave'
							value={usr}
							onChange={onChangeUsr}
							autoComplete='off'
						/>
						<button className='btn btn-dark w-100' onClick={cerrarTurnoOnC}>
							Validar
						</button>
					</div>

					<p className='mini text-center' style={{ color: "grey" }}>
						Presione Esc para cancelar
					</p>
				</form>
			</dialog>
		</div>
	);
};

export default Corte;
