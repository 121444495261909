import React, { useContext } from "react";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";

const Descfactd = ({
	factura: { id, serie, folio, fecha, folio_fiscal, rsocial, c_id, apellido, nombre},
	f,
}) => {
	const facturacionContext = useContext(FacturacionContext);
	const alertContext = useContext(AlertContext);
	const { reenviarFactura } = facturacionContext;
	const { setAlert } = alertContext;
	let ffecha = fecha.split(" ");
	let link =
		"https://www.realplaza.co/v1/salida/" +
		serie +
		folio +
		"-" +
		folio_fiscal +
		".pdf";
	let linkx =
		"https://www.realplaza.co/v1/salida/" +
		serie +
		folio +
		"-" +
		folio_fiscal +
		".xml";

	const file = serie + folio + "-" + folio_fiscal;

	let mail = async () => {
		const mail = prompt("inserte el correo a enviar");
		if (mail === null) {
			setAlert("No insertó un correo", "danger");
			return;
		}
		const resp = await reenviarFactura(file, mail);
		resp.success
			? setAlert("Facturas enviadas con éxito", "success")
			: setAlert(resp.data.messages[0], "danger");
	};
	let elapellido = apellido === null ? "": apellido;
	let elnombre = nombre === null ? "": nombre;
	return (
		<tr>
			<td>{c_id}</td>
			<td style={{ width: "10%" }}>{ffecha[0]}</td>
			<td>{rsocial}</td>
			<td>{elapellido+" "+elnombre}</td>
			<td>{serie + folio}</td>
			<td>{folio_fiscal}</td>
			<td>
				<a className='deslinkpdf' href={link} target='_blank' download>
					<i className='fas fa-solid fa-file-pdf'></i>
				</a>
			</td>
			<td>
				<a className='deslinkxml' href={linkx} target='_blank' download>
					<i className='fas fa-solid fa-file-code'></i>
				</a>
			</td>
			<td style={{ width: "5%" }}>
				<i className='fas fa-solid fa-envelope' onClick={mail}></i>
			</td>
		</tr>
	);
};

export default Descfactd;
