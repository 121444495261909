import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import CargosContext from "../../contexto/cargo/cargoContext.js";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import AlertContext from "../../contexto/alerta/alertContext";
import Prompt from "../../assets/Prompt";

const Cargostd = ({
	cargo: {
		id,
		cuenta_id,
		fecha,
		habitacion,
		inporte,
		abono,
		concepto,
		referencia,
		importe,
		iva,
		ieh,
		metodo_pago,
		comentario,
		cajero,
		folio,
		status,
	},
}) => {
	const cargoContext = useContext(CargosContext);
	const alertContext = useContext(AlertContext);
	const cuentaContext = useContext(CuentaContext);
	const { traerCuenta, cuenta, folios, limpiarCuenta } = cuentaContext;
	const { borrarCargo, cambiarFolio } = cargoContext;
	const { setAlert } = alertContext;
	const fechaS = fecha.split(" ");
	const llegadaF = fechaS[0].split("-");
	const [folval, setFolval] = useState(false);
	const [usr, setUsr] = useState("");

	const meses = [
		"dud",
		"Enero",
		"Feb",
		"Mar",
		"Abril",
		"Mayo",
		"Jun",
		"Jul",
		"Ago",
		"Sep",
		"Oct",
		"Nov",
		"Dic",
	];
	const fllegada =
		llegadaF[2] +
		"/" +
		meses[llegadaF[1].replace(/^0+/, "")] +
		"/" +
		llegadaF[0].substring(2, 4);
	const hora = fechaS[1].substring(0, 5);
	////////////////////////////////
	//formato de numero
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};
	////////////////////////////////
	// on change folio
	const foliOnChange = async (e) => {
		if (e.target.value !== 0) {
			let body = {
				cid: id,
				folio: {
					folio: e.target.value,
				},
			};
			setFolval(e.target.value);
			const resp = await cambiarFolio(body);
			if (resp.data.success) {
				setAlert("folio modificado", "success");
				limpiarCuenta();
			}
		}
	};
	///////////////////////////////////////
	const [prompt, setPrompt] = useState(false);
	const prompteador = (ev) => {
		ev.preventDefault();
		setPrompt(true);
	};
	///////////////////////////////////
	//borrar cargos
	const delOnClick = async (usr) => {
		if (usr !== null) {
			const resp = await borrarCargo(id, usr);
			resp.data.success
				? setAlert("cargo eliminado", "success")
				: setAlert("no se pudo eliminar el cargo", "danger");
			traerCuenta(cuenta_id);
		} else {
			setAlert("operacion cancelada");
		}
	};
	const total = parseFloat(importe) + parseFloat(iva) + parseFloat(ieh);
	return (
		<tr id={id} className={status === 1 ? "cancelada" : null}>
			<td>{habitacion}</td>
			<td>{fllegada}</td>
			<td>{hora}</td>
			<td>{concepto}</td>
			<td>{metodo_pago}</td>
			<td>{importe !== "0.0000" ? moneyForm(total) : null}</td>
			<td>{abono !== "0.0000" ? moneyForm(abono) : null}</td>
			<td>{referencia}</td>
			<td>{comentario}</td>
			<td>{cajero}</td>
			<td>
				{status === 0 ? (
					<select value={folval} onChange={foliOnChange}>
						<option key={0} value={0}>
							----
						</option>
						{Array.isArray(folios) &&
							folios.map((row, i) => {
								if (row.folio !== cuenta_id && row.status === "abierta") {
									return (
										<option key={i} value={row.folio}>
											{row.folio}
										</option>
									);
								}
							})}
					</select>
				) : null}
			</td>
			<td className='red'>
				{cuenta.status === "abierta" && status === 0 ? (
					<i className='fas fa-times-circle tacha' id={id} onClick={prompteador}></i>
				) : (
					<p>No disp.</p>
				)}
				<Prompt
					onClose={setPrompt}
					show={prompt}
					setKey={setUsr}
					onValidKey={delOnClick}
				/>
			</td>
		</tr>
	);
};

Cargostd.propTypes = {
	cargo: PropTypes.object.isRequired,
};
export default Cargostd;
