import React from "react";
import { Line } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from "chart.js";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler
);

const LinesChart = (props) => {
	console.log(props);

	var nosal = [];
	if (typeof props.dataarr.salidas !== "undefined") {
		props.dataarr.salidas.map((row, i) => {
			nosal[i] = row.valor;
		});
	} else {
		nosal = [0, 0, 0, 0, 0, 0, 0];
	}
	var nores = [];
	if (typeof props.dataarr.llegadas !== "undefined") {
		props.dataarr.llegadas.map((row, i) => {
			nores[i] = row.valor;
		});
	} else {
		nores = [0, 0, 0, 0, 0, 0, 0];
	}
	var labels = [];
	if (typeof props.dataarr.salidas !== "undefined") {
		props.dataarr.salidas.map((row, i) => {
			labels[i] = row.fecha;
		});
	} else {
		nosal = ["NA", "NA", "NA", "NA", "NA", "NA", "NA"];
	}

	var midata = {
		labels: labels,
		datasets: [
			// Cada una de las líneas del gráfico
			{
				label: "Salidas",
				data: nosal,
				tension: 0.5,
				fill: true,
				borderColor: "rgb(255, 99, 132)",
				backgroundColor: "rgba(255, 99, 132, 0.5)",
				pointRadius: 5,
				pointBorderColor: "rgba(255, 99, 132)",
				pointBackgroundColor: "rgba(255, 99, 132)",
			},
			{
				label: "Reservas",
				data: nores,
				tension: 0.5,
				fill: true,
				borderColor: "rgb(110,200,100)",
				backgroundColor: "rgba(110,200,100, 0.5)",
				pointRadius: 5,
				pointBorderColor: "rgba(110,200,100)",
				pointBackgroundColor: "rgba(110,200,100)",
			},
		],
	};

	var misoptions = {
		scales: {
			y: {
				min: 0,
			},
			x: {
				ticks: { color: "rgb(255, 99, 132)" },
			},
		},
		aspectRatio: 3,
	};

	return <Line data={midata} options={misoptions} />;
};

export default LinesChart;
