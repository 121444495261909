import React, { useReducer } from "react";
import axios from "axios";
import UserContext from "./userContext.js";
import UserReducer from "./userReducer.js";
import {
	SET_LOADING,
	USUARIOS,
	USUARIO,
	CLEARU,
	UNSET_LOADING,
} from "../types.js";

const UserState = (props) => {
	//inicializar en state

	const initialState = {
		usuarios: [],
		usuario: {},
		userFeed: null,
		userLoading: false,
	};

	//se declara dispatch
	const [state, dispatch] = useReducer(UserReducer, initialState);

	//traer access token
	const getaccessToken = async () => {
		return window.localStorage.getItem("access_token");
	};

	//////////////////////////////////////////////////////
	//1 traer todos los usuarios
	const traerUsuarios = async () => {
		let ufeedB = null;
		const accessToken = await getaccessToken();
		setUserLoading();
		await axios
			.get("/api/usuarios", {
				headers: {
					Authorization: `${accessToken}`,
				},
			})
			.then((resp) => {
				ufeedB = {
					success: resp.data.success,
					color: "success",
					message: resp.data.messages[0],
				};
				dispatch({ type: USUARIOS, payload: resp.data.data.usuarios });
				return ufeedB;
			})
			.catch((err) => {
				ufeedB = {
					success: err.response.data.success,
					mensaje: "No se encontraron usuarios " + err.response.data.messages[0],
					color: "danger",
				};
				return ufeedB;
			});
	};

	////////////////////////////////////////////
	//2 traer un solo usuario
	const traerUsuario = async (id) => {
		let ufeedB = null;
		const accessToken = await getaccessToken();
		setUserLoading();
		await axios
			.get(`/api/usuarios/${id}`, { headers: { Authorization: `${accessToken}` } })
			.then((resp) => {
				ufeedB = {
					success: resp.data.success,
					mensaje: resp.data.messages[0],
					color: "success",
				};
				dispatch({ type: USUARIO, payload: resp.data.data.userdata });
			})
			.catch((err) => {
				console.log(err.response);
				ufeedB = {
					success: err.response.data.succes,
					mensaje: err.response.data.messages[0],
					color: "danger",
				};
			});
		return ufeedB;
	};

	//////////////////////////////////////////
	//3 crear usuario
	const nuevoUsuario = async (User, Password, Nombre, Mail, Tel) => {
		let ufeedB = null;
		setUserLoading();
		const body = {
			fullname: Nombre,
			username: User,
			password: Password,
			mail: Mail,
			tel: Tel,
		};
		await axios
			.post(`/api/usuarios`, body, {
				headers: { "Content-Type": "application/json" },
			})
			.then((resp) => {
				ufeedB = {
					success: resp.data.success,
					username: resp.data.data.fullname,
					mensaje: resp.data.messages[0],
					color: "success",
				};
			})
			.catch((err) => {
				ufeedB = {
					success: err.response.data.success,
					mensaje: "ERROR " + err.response.data.messages[0],
					color: "danger",
				};
			});
		await unsetUserLoading();
		return ufeedB;
	};

	////////////////////////////////////////////////
	//3 borrar usuario
	const borrarUsuario = async (id) => {
		let ufeedB = null;
		const accessToken = await getaccessToken();
		setUserLoading();
		await axios
			.delete(`/api/usuarios/${id}`, {
				headers: { Authorization: `${accessToken}` },
			})
			.then(async (resp) => {
				ufeedB = {
					success: resp.data.success,
					mensaje: resp.data.messages[0],
					color: "success",
				};
				if (resp.data.success) {
					let nusers = state.usuarios.filter((user) => user.id !== id);
					dispatch({ type: USUARIOS, payload: nusers });
				}
				return ufeedB;
			})
			.catch((err) => {
				ufeedB = {
					success: err.response.data.success,
					mensaje: err.response.data.messages[0],
					color: "danger",
				};
				return ufeedB;
			});
	};

	//////////////////////////////////
	// parchar usuario
	const editarUsuario = async (id, User, Password, Nombre, Mail, Tel, Role) => {
		let ufeedB = null;
		let accessToken = await getaccessToken();
		setUserLoading();
		const body = {
			fullname: Nombre,
			username: User,
			password: Password,
			mail: Mail,
			tel: Tel,
			role: Role,
		};
		await axios
			.patch(`/api/usuarios/${id}`, body, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			})
			.then((resp) => {
				ufeedB = {
					success: true,
					mensaje: resp.data.messages,
					color: "success",
				};
				return ufeedB;
			})
			.catch((err) => {
				ufeedB = {
					success: true,
					mensaje: err.data.messages,
					color: "success",
				};
			});
		return ufeedB;
	};
	////////////////////////////////
	// 4 set loading
	const setUserLoading = () => dispatch({ type: SET_LOADING });
	////////////////////////////////
	// 5 Unset loading
	const unsetUserLoading = () => dispatch({ type: UNSET_LOADING });

	////////////////////////////////
	// cleear users
	const clearUsers = () => {
		dispatch({ type: CLEARU });
		return true;
	};
	return (
		<UserContext.Provider
			value={{
				usuarios: state.usuarios,
				usuario: state.usuario,
				userLoading: state.userLoading,
				traerUsuarios,
				traerUsuario,
				nuevoUsuario,
				borrarUsuario,
				clearUsers,
				unsetUserLoading,
				editarUsuario,
			}}
		>
			{props.children}
		</UserContext.Provider>
	);
};

export default UserState;
