import React, { useContext, useEffect, useState } from "react";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import Reportefacturatd from "./Reportefacturatd.js";
const Reportefacturath = ({ fecha }) => {
	const [factload, setFactload] = useState(false);
	let dummie = [
		{
			id: 0,
			serie: "A",
			folio: "0",
			forma_pago: "Sin Paagos",
			importe: 0,
			iva: 0,
			ieh: 0,
			total: 0,
			fecha: "2022-01-01 00:00",
			usr: "art",
			folio_fiscal: "sin folio",
			rfc: "Sin Datos",
			rsocial: "sin Datos",
		},
	];
	let serie = [];
	const facturacionContext = useContext(FacturacionContext);
	const { facturas } = facturacionContext;
	if (Array.isArray(facturas) && facturas.length > 0) {
		serie = facturas.filter((factura) => factura.serie === "A");
	}

	let gsubtotal = 0;
	let giva = 0;
	let gieh = 0;
	let gtotal = 0;

	if (Array.isArray(facturas) && facturas.length > 0) {
		facturas.map((row, i) => {
			gsubtotal = gsubtotal + row.importe;
			giva = giva + row.iva;
			gieh = gieh + row.ieh;
			gtotal = gtotal + row.total;
		});
	}

	///////////////////////////////
	//formato de numero
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};

	useEffect(() => {
		typeof facturas !== "undefined" && setFactload(true);
	}, [facturas]);
	return (
		<>
			<div className='w-100'>
				<h3 className='my-1'>Reporte de Facturas {fecha}</h3>
				<table id='car' className='w-100'>
					<thead>
						<tr>
							<th>Factura</th>
							<th>RFC</th>
							<th>Fecha</th>
							<th>Hospedaje</th>
							<th>Cafetería</th>
							<th>Lavandería</th>
							<th>Bar</th>
							<th>Propina</th>
							<th>IVA</th>
							<th>3%</th>
							<th>Total</th>
						</tr>
					</thead>
					<tbody>
						{typeof facturas === "undefined"
							? dummie.map((res) => <Reportefacturatd key={res.folio} factura={res} />)
							: serie.map((res) => <Reportefacturatd key={res.folio} factura={res} />)}
					</tbody>
					<tfoot>
						<tr>
							<th colSpan='7' />
							<th scope='row'>Subtotal</th>
							<th scope='row'>IVA</th>
							<th scope='row'>IEH</th>
							<th scope='row'>Total</th>
						</tr>
						<tr>
							<td colSpan='7' />
							<td scope='row'>
								<strong>{moneyForm(gsubtotal)}</strong>
							</td>
							<td scope='row'>
								<strong>{moneyForm(giva)}</strong>
							</td>
							<td scope='row'>
								<strong>{moneyForm(gieh)}</strong>
							</td>
							<td scope='row'>
								<strong>{moneyForm(gtotal)}</strong>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</>
	);
};

export default Reportefacturath;
