import React, { useContext, useState, Fragment, useEffect } from "react";
import ReservaContext from "../../contexto/reservacion/reservaContext";
import CuentaContext from "../../contexto/cuenta/cuentaContext.js";
import RegistroContext from "../../contexto/registro/registroContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
import Tarifas from "../../assets/tarifas.js";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import Rfcpicker from "../facturacion/Rfcpicker.js";
import Prompt from "../../assets/Prompt";

const NuevoRegistro = () => {
	/// contextos
	const reservaContext = useContext(ReservaContext);
	const cuentaContext = useContext(CuentaContext);
	const registroContext = useContext(RegistroContext);
	const alertContext = useContext(AlertContext);
	const facturacionContext = useContext(FacturacionContext);
	// destructura
	const { empresa, traerPublicoId } = facturacionContext;
	const { reservacion, unsetModalOpen, limpiarReservas } = reservaContext;
	const { crearCuenta } = cuentaContext;
	const { crearRegistro, habitaciones: habis, traerVaciasL } = registroContext;
	const { setAlert } = alertContext;
	const {
		folio,
		cliente_id,
		empresa_id,
		tarifa,
		nombre,
		apellido,
		grupo,
		info,
		llegada,
		hora,
		salida,
		personas,
		habitaciones,
	} = reservacion;

	///////////////////////////////////////
	const [usr, setUsr] = useState("");
	const [prompt, setPrompt] = useState(false);

	////////////////////////////////////////

	let empresaId = null;
	empresa_id === null && empresa !== null && (empresaId = empresa.id);
	const habsolo = habis.map((row) => {
		if (row.status === "vaciaL" || row.status === "asignadaL") {
			return parseInt(row.habitacion);
		}
	});

	const registrosRaw = new Array();
	let i = 0;
	while (registrosRaw.length < habitaciones) {
		registrosRaw.push({
			id: i,
			reserva_id: folio,
			cliente_id: cliente_id,
			empresa_id: empresaId,
			cuenta_id: null,
			titular: nombre,
			habitacion: "",
			tarifa: "S10D1",
			reservo: null,
		});
		i++;
	}

	//states
	const [registros, setRegistros] = useState(registrosRaw);
	const [credito, setCredito] = useState("");
	const [registrar, setRegistrar] = useState(false);

	/////////////////////////////////
	//
	const onChangeTarifa = async (index, e) => {
		e.preventDefault();
		e.persist();
		setRegistros((prev) => {
			return prev.map((row, i) => {
				if (i !== index) {
					return row;
				}
				return {
					...row,
					[e.target.name]: e.target.value,
				};
			});
		});
	};

	////////////
	//onchange
	const onChangeM = (index, e) => {
		e.preventDefault();
		e.persist();
		setRegistros((prev) => {
			return prev.map((row, i) => {
				if (i !== index) {
					return row;
				}
				return {
					...row,
					[e.target.name]: e.target.value,
				};
			});
		});
	};
	const onChangeCredito = (e) => setCredito(e.target.value);
	const prompteador = (ev) => {
		ev.preventDefault();
		setPrompt(true);
	};
	//////////////////////////////////
	// on submit
	const submitRregistro = async (usr) => {
		traerVaciasL();
		const publico = await traerPublicoId();
		//verificamos que las habitaciones esten disponibles o cuello
		registros.map((row) => {
			let check = habsolo.includes(parseInt(row.habitacion));
			if (check == false) {
				setAlert(
					"la habitacion " + row.habitacion + " no esta disponible, verifique",
					"danger"
				);
				throw new Error("fallo");
			}
		});
		//creamos el body para la cuenta
		let cuentaBdy = {
			cliente_id: cliente_id,
			reserva_id: folio,
			aperturo: usr,
		};
		credito > 0 && (cuentaBdy.credito = credito);
		empresa_id > 0
			? (cuentaBdy.empresa_id = empresa_id)
			: typeof empresa !== "undefined" && empresa.id > 0
			? (cuentaBdy.empresa_id = empresa.id)
			: (cuentaBdy.empresa_id = publico);
		//traemos la cuenta
		const cuentaResp = crearCuenta(cuentaBdy);
		cuentaResp
			.then((r) => {
				const cuenta_id = r.data.id;
				const nreg = registros.map((row) => {
					row.cuenta_id = cuenta_id;
					row.registro = usr;
					return row;
				});
				let body = {
					registros: nreg,
				};
				const regResp = crearRegistro(body);
				regResp
					.then((r) => {
						r.success
							? setAlert(r.messages[0], "success")
							: setAlert(r.messages[0], "danger");
					})
					.catch((r) => {
						setAlert("Fallo la creacion del registro", "danger");
					});
			})
			.catch((r) => {
				setAlert("Falló en crear la cuenta", "danger");
			});
		setUsr("");
		setRegistrar(false);
		limpiarReservas();
		unsetModalOpen();
	};
	/////////////////////////////////
	useEffect(() => {
		if (habis.length === 0) {
			traerVaciasL();
		}
		//eslint-disable-next-line
	}, [registrar, habis]);
	return (
		<>
			<Prompt
				onClose={setPrompt}
				show={prompt}
				setKey={setUsr}
				onValidKey={submitRregistro}
			/>
			<div className='mb-2'>
				<form onSubmit={prompteador}>
					<div className='grid-2'>
						<h2 className=''>Nuevo Registro</h2>
						<h3 className=''>{`${personas} personas en ${habitaciones} habitaciones`}</h3>
					</div>
					<div className='grid-4 w-100'>
						<div className=''>
							<div>
								<p className='bold'>A nombre de:</p>
								<p className='lead  my-0'>{nombre + " " + apellido}</p>
							</div>
							<Rfcpicker />
						</div>
						<div className=''>
							<div>
								<p className='bold'>Comentarios:</p>
								<p className='lead  my-0'>{info}</p>
							</div>
							<div className=''>
								<p className='bold'>Credito:</p>
								<input
									type='number'
									name='credito'
									min='1'
									step='any'
									value={credito}
									placeholder='Credito'
									onChange={onChangeCredito}
								/>
							</div>
						</div>
						<div className=''>
							<div className=''>
								<p className='bold'>Fecha de entrada: </p>
								<p className='lead  my-0'>{llegada}</p>
								<p className='bold'>Grupo:</p>
								<p className='lead  my-0'>{grupo}</p>
							</div>
						</div>
						<div className=''>
							<div className=''>
								<p className='bold'>Fecha de salida:</p>
								<p className='lead  my-0'>{salida}</p>
							</div>
						</div>
					</div>
					<div className='grid-3'>
						{registros.map((row, index) => {
							return (
								<Fragment key={index}>
									<div>
										<p className='small bold'>Titular:</p>
										<input
											type='text'
											name='titular'
											value={row.titular}
											placeholder='titular'
											onChange={(e) => onChangeM(index, e)}
										/>
									</div>
									<div>
										<p className='small bold'>Habitacion</p>
										<input
											type='number'
											name='habitacion'
											list='hab'
											value={row.habitacion}
											placeholder='no. habitacion'
											onChange={(e) => onChangeM(index, e)}
											required
										/>
										<datalist id='hab'>
											{habsolo.map((res, i) => {
												return (
													<option key={i} value={res}>
														{res}-
													</option>
												);
											})}
										</datalist>
									</div>
									<div>
										<p className='small bold'>Tarifa:</p>
										<select
											name='tarifa'
											id=''
											onChange={(e) => onChangeTarifa(index, e)}
										>
											{Tarifas.map((row) => {
												return (
													<option key={row.id} name={row.Nombre} value={row.Nombre}>
														{row.Nombre}
													</option>
												);
											})}
										</select>
									</div>
								</Fragment>
							);
						})}
					</div>
					<div className='text-center my'>
						<button className='btn btn-dark'>Registrar</button>
					</div>
				</form>
			</div>
		</>
	);
};

export default NuevoRegistro;
