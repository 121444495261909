import { useContext, useEffect, useState, useRef } from "react";
import clavesctrl from "./clavesctrl";
import alertContext from "../contexto/alerta/alertContext";

const Prompt = ({ onValidKey, setKey, onClose, mensaje, show }) => {
	const [clave, setClave] = useState("");
	const { setAlert } = useContext(alertContext);

	const handleKeyChange = (event) => {
		setClave(event.target.value);
	};
	const claveinput = useRef();
	const handleSubmit = (event) => {
		event.preventDefault();
		const resp = clavesctrl.find((user) => user.clave === parseInt(clave));

		if (resp !== undefined) {
			onValidKey(resp.usuario);
			setKey(resp.usuario);
			onClose(false);
			setClave("");
		} else {
			setAlert("Clave no válida", "danger");
			setClave("");
		}
	};
	useEffect(() => {
		if (show) {
			claveinput.current.focus();
		}
		const close = (e) => {
			if (e.keyCode === 27) {
				//props.onCloseModal()
				setClave("");
				close(onClose(false));
			}
		};
		window.addEventListener("keydown", close);
		return () => window.removeEventListener("keydown", close);
	}, [show]);

	return (
		<div
			className={show ? "modalOver modalPrompt" : " hid"}
			style={{ width: "auto" }}
		>
			<div className='modal-xs modalPrompt'>
				<button
					className='btn-primary btn-sm'
					onClick={() => onClose(false)}
					style={{ position: "fixed", top: "0", right: "0" }}
				>
					X
				</button>
				<form onSubmit={handleSubmit}>
					<label>
						{mensaje ?? "Ingrese su clave de usuario para confirmar:"}
						<input
							id='claveinput'
							ref={claveinput}
							type='password'
							name='clave'
							value={clave}
							onChange={handleKeyChange}
						/>
					</label>
					<button type='submit' className='btn-primary btn'>
						Confirmar
					</button>
				</form>
			</div>
		</div>
	);
};
export default Prompt;
