import React, { useState, useContext, useEffect } from "react";
import AutContext from "../../contexto/autentificacion/autContext.js";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../elementos/Spinner.js";

const Login = () => {
	const [user, setUser] = useState("");
	const [password, setPassword] = useState("");
	const autContext = useContext(AutContext);
	const navigate = useNavigate();
	const { logstatus, login, autloading, isloged } = autContext;

	const onChange = (e) => {
		if (e.target.name === "user") {
			setUser(e.target.value);
		} else if (e.target.name === "password") {
			setPassword(e.target.value);
		}
	};

	const onSubmit = (e) => {
		e.preventDefault();
		login(user, password);
		setUser("");
		setPassword("");
	};

	const hey = () => {
		isloged();
	};
	useEffect(() => {
		if (logstatus.loged) {
			navigate("/");
		}
		//eslint-disable-next-line
	}, [logstatus, autloading]);

	if (autloading === true) {
		return <Spinner />;
	} else {
		return (
			<div className='login container'>
				<form onSubmit={onSubmit} className='loginForm'>
					<h2 className='large'>Bienvenido</h2>
					<h3>Ingrese su nombre de Usuario y Contraseña</h3>
					<label className='my bold' htmlFor='user'>
						Nombre de usuario:
					</label>
					<input
						type='text'
						name='user'
						placeholder='Ingrese su usuario'
						value={user}
						onChange={onChange}
					/>
					<label className='my bold' htmlFor='password'>
						Contraseña:
					</label>
					<input
						type='password'
						name='password'
						placeholder='Ingrese su contraseña'
						value={password}
						onChange={onChange}
					/>
					<input
						type='submit'
						className='btn btn-block btn-dark my-2'
						value='Ingresar'
					/>
				</form>
			</div>
		);
	}
};

export default Login;
