import React, { useContext, useState } from "react";
import RegistroContext from "../../contexto/registro/registroContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";

const RegistroBusqueda = () => {
	//////////////////////////
	// contextos
	const registroContext = useContext(RegistroContext);
	const { traerRegistrosByNombre, traerRegistrosByHabitacion, unSetRegistros } =
		registroContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	////////////////////////
	// states
	const [busqApellido, setBusqApellido] = useState("");
	const [busqHabitacion, setBusqHabitacion] = useState("");

	///////////////////////
	// onchanges
	const onChangeBusqApellido = (e) => setBusqApellido(e.target.value);
	const onChangeBusqHabitacion = (e) => setBusqHabitacion(e.target.value);

	///////////////////////
	// métodos
	const SubmitBusqHabitacion = async (e) => {
		e.preventDefault();
		const resp = await traerRegistrosByHabitacion(busqHabitacion);
		resp.success
			? setAlert(
					`Se encontraron ${resp.data.data.length} Registros con ese parametro`,
					"success"
			  )
			: setAlert("La habitación no tiene registros activos", "danger");
	};
	const SubmitBusqApellido = async (e) => {
		e.preventDefault();
		const resp = await traerRegistrosByNombre(busqApellido);
		console.log(resp);
		resp.success
			? setAlert(
					`Se encontraron ${resp.data.length} Registros con ese parametro`,
					"success"
			  )
			: setAlert("No se encontraron registros", "danger");
	};
	return (
		<>
			<section id='busqueda' className='grid-2'>
				<article className='busqueda busqHabitacion'>
					<form onSubmit={SubmitBusqHabitacion}>
						<label htmlFor='busqHabitacion'>Buscar por Habitacion</label>
						<input
							type='number'
							name='busqHabitacion'
							min='0'
							max='2000'
							value={busqHabitacion}
							onChange={onChangeBusqHabitacion}
						/>
						<input
							type='submit'
							className='btn btn-dark btn-block my'
							value='Buscar'
						/>
					</form>
				</article>
				<article className='busqueda busqApellido'>
					<form onSubmit={SubmitBusqApellido}>
						<label htmlFor='busqApellido'>Buscar por Apellido</label>
						<input
							type='text'
							name='busqApellido'
							value={busqApellido}
							onChange={onChangeBusqApellido}
						/>
						<input
							type='submit'
							className='btn btn-dark btn-block my'
							value='Buscar'
						/>
					</form>
				</article>
			</section>
		</>
	);
};

export default RegistroBusqueda;
