import React from "react";

const Salidastd = ({
	filas: { id, hab, tarifa, nombre, cuenta, info, hora, usr, razon },
}) => {
	return (
		<>
			<tr>
				{/*<td></td>*/}
				<td>{hab}</td>
				<td>{tarifa}</td>
				<td>{nombre}</td>
				<td>{cuenta}</td>
				<td>{info}</td>
				<td>{hora}</td>
				<td>{usr}</td>
				<td>{razon}</td>
			</tr>
		</>
	);
};

export default Salidastd;
