import { useCallback, useContext } from "react";
import facturacionContext from "../../contexto/facturacion/facturacionContext";
import alertContext from "../../contexto/alerta/alertContext";
import baseGlobal from "../../assets/FacturaGlobalCafe4";
//import baseGlobal from "../../assets/FacturaGlobalCafe33"; //Facturacion 3.3
//import base from "../../assets/";

const useFacturacion = () => {
  const { setAlert } = useContext(alertContext);
  const { traerFolioB, timbrarFactura, loading, setLoading, traerFolio } =
    useContext(facturacionContext);
  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  const date = new Date(Date.now() - tzoffset).toISOString().slice(0, 19);

  ///////////////////////////////////////
  //METODOS
  const moneyForm = (raw) => {
    const numero = parseFloat(raw);
    if (!isNaN(numero)) {
      const res = "$" + numero.toFixed(2);
      return res;
    } else {
      return "Sin Cargos";
    }
  };
  ////////////////////////////////
  //FACTURA GLOBAL
  const facturacionG = useCallback(async (listaF, usr) => {
    let efe = 0,
      tcred = 0,
      tdeb = 0,
      prop = 0;
    let cerradas = [];
    let fpago = "",
      fp = "",
      fecha = "",
      comentario = "";
    let importe = 0,
      subtotal = 0,
      iva = 0;
    if (Object.keys(listaF).length === 0) {
      setAlert("No hay cheques que facturar", "danger");
      return;
    }
    fecha = listaF[0].cierre.split(" ");
    fecha = fecha[0].split("-");
    const folio = await traerFolioB();
    setLoading(true);
    listaF.forEach((res) => {
      cerradas.push(res.id);
      comentario +=
        "Folio: " +
        res.id.toString() +
        " Total: $" +
        parseFloat(res.importe).toFixed(2).toString() +
        " | ";
      importe += parseFloat(res.importe);
      res.rcargos.forEach((car) => {
        if (car.status === 1) {
          if (car.clave === 503) tcred += parseFloat(car.importe);
          if (car.clave === 504) tdeb += parseFloat(car.importe);
          if (car.clave === 502) efe = importe - tcred - tdeb; //efe += parseFloat(res.importe);
          if (car.clave === 505) prop += parseFloat(car.importe);
        }
      });
    });

    if (efe > tcred && efe > tdeb) {
      fpago = "01";
      fp = "Efectivo";
    } else if (tcred > efe && tcred > tdeb) {
      fpago = "04";
      fp = "T. Crédito";
    } else {
      fpago = "28";
      fp = "T. Débito";
    }

    subtotal = parseFloat(importe) / 1.16;
    iva = Math.round(subtotal * 16) / 100;
    subtotal = importe - iva;
    console.log("efe tc td", efe, tcred, tdeb, importe, comentario);
    console.log("PROPINA", prop, "SUB", subtotal, "TOTAL", importe);
    let user = usr;

    ///////LLENADO DE FORMATO
    baseGlobal.Comprobante.Fecha = date;
    baseGlobal.Comprobante.Folio = folio.folio;
    baseGlobal.Comprobante.FormaPago = fpago;
    baseGlobal.Comprobante.Total = importe + prop;
    baseGlobal.Comprobante.SubTotal = subtotal + prop;
    baseGlobal.Comprobante.InformacionGlobal.Meses = fecha[1];
    baseGlobal.Comprobante.InformacionGlobal.Año = fecha[0];
    baseGlobal.Comprobante.Conceptos[0].NoIdentificacion =
      fecha[0] + fecha[1] + fecha[2];
    baseGlobal.Comprobante.Conceptos[0].ValorUnitario = subtotal;
    baseGlobal.Comprobante.Conceptos[0].Importe = subtotal;
    baseGlobal.Comprobante.Conceptos[0].Impuestos.Traslados[0].Base = subtotal;
    baseGlobal.Comprobante.Conceptos[0].Impuestos.Traslados[0].Importe = iva;
    baseGlobal.Comprobante.Impuestos.TotalImpuestosTrasladados = iva;
    baseGlobal.Comprobante.Impuestos.Traslados = [
      {
        Base: subtotal,
        Impuesto: "002",
        TipoFactor: "Tasa",
        TasaOCuota: "0.160000",
        Importe: iva,
      },
    ];
    baseGlobal.CamposPDF = {
      tipoComprobante: "FACTURA",
      comentarios: comentario,
    };

    // Se checa si hay propina, si hay, se crea el traslado de impuesto y concepto
    if (prop > 0) {
      const trasladoPropina = {
        Base: prop,
        Impuesto: "002",
        TipoFactor: "Tasa",
        TasaOCuota: "0.000000",
        Importe: 0,
      };
      const conceptoPropina = {
        ClaveProdServ: "90111601",
        NoIdentificacion: fecha[0] + fecha[1] + fecha[2],
        Cantidad: "1",
        ClaveUnidad: "E48",
        Unidad: "Servicio",
        Descripcion: "Propina",
        ValorUnitario: prop,
        Importe: prop,
        ObjetoImp: "02",
        Impuestos: {
          Traslados: [
            {
              Base: prop,
              Impuesto: "002",
              TipoFactor: "Tasa",
              TasaOCuota: "0.000000",
              Importe: 0,
            },
          ],
        },
      };
      baseGlobal.Comprobante.Conceptos.push(conceptoPropina);
      baseGlobal.Comprobante.Impuestos.Traslados.push(trasladoPropina);
    }
    console.log(baseGlobal);
    const factura = {
      body: baseGlobal,
      //mail:"info@realplaza.com.mx",
      mail: "info@realplazaags.com.mx",
      usr: user,
      cheque_id: cerradas,
      pdf: {
        nombre: "PUBLICO GENERAL",
        rsocial: baseGlobal.Comprobante.Receptor.Nombre,
        rfc: baseGlobal.Comprobante.Receptor.Rfc,
        regimen: "616",
        direccion: "",
        cp: "20000",
        entrada: "",
        salida: listaF[0].cierre,
        fpago: fp,
        uso: fp !== 99 ? "03 Gastos en General" : "P01 Por Definir",
        ciudad: "AGUASCALIENTES",
        estado: "AGUASCALIENTES",
        pais: "MÉXICO",
        mpago: "PUE",
        conceptos: [
          {
            fecha: listaF[0].cierre,
            cantidad: "1",
            clave: "01010101",
            concepto: "Venta",
            importe: subtotal,
            iva: iva,
            ieh: 0,
          },
        ],
        hab: "",
        recibe: user,
        cerro: user,
        hospedaje: "0",
        cafeteria: moneyForm(subtotal),
        bar: "0",
        lavanderia: "0",
        cnegocios: "0",
        propinas: "",
        iva: moneyForm(iva),
        ieh: "0",
        subtotal: moneyForm(subtotal),
        total: moneyForm(importe),
        totalIva: moneyForm(importe + prop),
        comentarios: comentario,
      },
    };
    if (prop > 0) {
      const pdfProp = {
        fecha: listaF[0].cierre,
        cantidad: "1",
        clave: "90111601",
        concepto: "Propina",
        importe: prop,
        iva: 0,
        ieh: 0,
      };
      factura.pdf.conceptos.push(pdfProp);
    }

    let respuesta = await timbrarFactura(factura);
    if (respuesta.success) {
      window.location.reload();
      setAlert(respuesta.messages, "success");
    } else errorCode(respuesta.data.data);
    setLoading(false);
  }, []);

  const errorCode = useCallback(
    async (data) => {
      switch (data.code) {
        case "CFDI33118":
        case "CFDI40119":
          setAlert("No coinciden cantidades decimales", "danger");
          break;
        case "CFDI40158":
        case "CFDI40159":
        case "CFDI40157":
        case "CFDI40140":
        case "301":
          setAlert(
            "El régimen Fiscal no coincide con el registrado ante el SAT o no contiene un valor del catálogo c_RegimenFiscal",
            "danger"
          );
          break;
        case "CFDI40143":
        case "CFDI33132":
          setAlert(
            "Este RFC del receptor no existe en la lista de RFC inscritos no cancelados del SAT.",
            "danger"
          );
          break;
        case "CFDI40144":
        case "CFDI40145":
          setAlert(
            "La Razón social no exite o no coincide con un RFC registrado ante el SAT",
            "danger"
          );
          break;
        case "CFDI40147":
        case "CFDI40148":
        case "CFDI40149":
          setAlert("El código postal no es correcto", "danger");
          break;
        case "CFDI40161":
          setAlert(
            "El Uso de CFDI debe corresponder con el tipo de persona y régimen",
            "danger"
          );
          break;
        case "facturada":
          setAlert("La cuenta ya está facturada", "danger");
          break;
        case "folios":
          setAlert(
            "La cuenta aún tiene folios abiertos, cierre todos los folios extra antes de cerrar la cuenta",
            "danger"
          );
          break;
        default:
          setAlert("falla no identificada :" + data.messages[0], "danger");
      }
    },
    [setAlert]
  );
  const executeFunction = useCallback(
    (func, ...args) => {
      switch (func) {
        case "facturaB":
          //facturacionB(...args);
          break;
        case "facturaGlobalB":
          console.log(func);
          facturacionG(...args);
          break;
        case "facturaC":
          //facturacionC(...args);
          break;
        case "error":
          errorCode(...args);
          break;
        default:
          break;
      }
    },
    [facturacionG, errorCode]
  );

  return { executeFunction };
};
export default useFacturacion;
