import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import alertContext from "../../contexto/alerta/alertContext";
import Spinner from "../elementos/Spinnermodal";

 const Busqueda = (props) =>{
    /////////////////////////////////
    //CONTEXTO
    const cafeteriaContext = useContext(CafeteriaContext);
    const {traerCheque, traerChequesFecha, loading} = cafeteriaContext;
    const AlertContext = useContext(alertContext);
    const {setAlert} = AlertContext;

    const navigate = useNavigate();
    /////////////////////////////////
    //STATE
    const [busqueda,setBusqueda] = useState({type:"text", placeholder:"Cheque", value: ""})
    
    /////////////////////////////////
    //METODOS
    const onClickB = async (event) =>{
        event.preventDefault();
        let tz = new Date(Date.now()).getTimezoneOffset() * 60000;
        const hoy = new Date(Date.now()-tz).toISOString().slice(0,10);
        
        if(busqueda.type==="text"){
            const resp = await traerCheque(busqueda.value)
            if(resp.id===parseInt(busqueda.value))
                navigate(`/rcargos/${resp.id}`);
            else
                setAlert("No se encontró el cheque", "danger"); 
        }
        else{
            await traerChequesFecha(busqueda.value===""?hoy:busqueda.value);
            props.setFecha(busqueda.value===""?hoy:busqueda.value)
        }
    }

    const tipoBusqueda = (e) =>{      
        setBusqueda({type:e.target.id, placeholder: e.target.innerText, value: ""});
    }

    return(
    <div className="floatRight noprint">
        {loading && <Spinner/>}
        <div className="grid-1 all-center">
            <label className="floatRight">Busqueda por: 
                <label id="text" className="clickable btn-primary" onClick={tipoBusqueda}>Cheque</label> |
                <label id="date" className="clickable btn-primary" onClick={tipoBusqueda}>Fecha</label>
            </label>
            <input {...busqueda} onChange={(e)=>setBusqueda({...busqueda, value:e.target.value})}></input> 
        </div>
        <button className="floatRight btn-block btn-primary" onClick={onClickB}>Buscar</button>
        </div>
    )
}
export default Busqueda;