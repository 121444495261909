import React, { useContext, useEffect, useState } from "react";
import RegistroContext from "../../contexto/registro/registroContext.js";
import ReservaContext from "../../contexto/reservacion/reservaContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
import Spinner from "../elementos/Spinner.js";
import tarifas from "../../assets/tarifas";
import Prompt from "../../assets/Prompt.js";
import estados from "../../assets/estados";

const RegistroSingle = () => {
	const registroContext = useContext(RegistroContext);
	const { registro, unsetModalOpenReg, patchRegistro, unSetRegistros } =
		registroContext;
	const reservaContext = useContext(ReservaContext);
	const { patchReserva } = reservaContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const registroLength = Object.keys(registro).length;
	const [regis, setRegis] = useState({});
	const [prompt, setPrompt] = useState(false);
	const [usr, setUsr] = useState(false);

	const {
		registroid: registro_id,
		cliente_id,
		folio,
		nombre,
		apellido,
		habitacion,
		ciudad,
		estado,
		tel,
		mail,
		grupo,
		registro: usuario,
	} = registro;

	const tarsolo = tarifas.map((r) => {
		return r.Nombre;
	});

	const closeModal = () => {
		unsetModalOpenReg();
	};

	const actualizaReg = async (usr) => {
		let check2 = tarsolo.includes(regis.tarifa);
		if (!check2) {
			setAlert(
				"la Tarifa " + regis.tarifa + " tiene un error, verifique",
				"danger"
			);
			throw new Error("fallo");
		}
		const reg = {
			folio: registro.registroid,
			habitacion: habitacion,
			titular: regis.titular,
			tarifa: regis.tarifa,
			registro: usr,
			ciudad: regis.ciudad,
			estado: regis.estado,
			rsalida: regis.salida,
		};
		console.log(reg);

		const resp1 = await patchRegistro(reg);
		console.log(resp1);

		const reser = {
			folio: folio,
			info: regis.info,
			ref: regis.ref,
			grupo: regis.grupo,
		};
		const pres3 = await patchReserva(reser);
		if (resp1.success || pres3.success) {
			setAlert("registro actualizado", "success");
			unSetRegistros();
			unsetModalOpenReg();
		} else {
			setAlert("No se actualizó ningún dato", "danger");
		}
	};
	useEffect(() => {
		if (typeof registro !== "undefined") {
			console.log(registro);

			setRegis(registro);
		}
	}, [registro]);

	if (registroLength === 0) return <Spinner />;
	return (
		<>
			<div className='modalOver'>
				<Prompt
					onClose={setPrompt}
					show={prompt}
					setKey={setUsr}
					onValidKey={actualizaReg}
				/>
				<div className='modal grid-5'>
					<h2 className='gspan-5'>
						<p>
							{nombre} {apellido}
						</p>
						Registro: {registro_id}{" "}
						<span className='floatRight'>
							<i className='fas fa-times-circle' onClick={closeModal}></i>
						</span>
					</h2>
					<div className='gspan-2'>
						<p className='small bold'>Titular:</p>
						<input
							type='text'
							value={regis.titular}
							onDoubleClick={(e) => (e.target.readOnly = false)}
							readOnly={true}
							onChange={(e) => setRegis({ ...regis, titular: e.target.value })}
						></input>
						{/*Campo nombre*/}
						<label htmlFor='nombre'>Ciudad</label>
						<input
							name='ciudad'
							type='text'
							value={regis.ciudad}
							onDoubleClick={(e) => (e.target.readOnly = false)}
							readOnly={true}
							onChange={(e) => setRegis({ ...regis, ciudad: e.target.value })}
							className=''
						/>
						<p className='small bold'>Comentarios</p>
						<input
							type='text'
							value={regis.info}
							onDoubleClick={(e) => (e.target.readOnly = false)}
							readOnly={true}
							onChange={(e) => setRegis({ ...regis, info: e.target.value })}
						></input>
					</div>
					<div className='gspan-2'>
						<div className=' grid-2 gspan-2'>
							<div>
								<p className='small bold'>Grupo</p>
								<input
									type='text'
									value={regis.grupo}
									onDoubleClick={(e) => (e.target.readOnly = false)}
									readOnly={true}
									onChange={(e) => setRegis({ ...regis, grupo: e.target.value })}
								></input>
							</div>
							<div>
								<p className='small bold'>Tarifa:</p>
								<input
									type='text'
									list='tarifas'
									value={regis.tarifa}
									onDoubleClick={(e) => (e.target.readOnly = false)}
									readOnly={true}
									onChange={(e) => setRegis({ ...regis, tarifa: e.target.value })}
								></input>
								<datalist id='tarifas'>
									{tarifas.map((res) => {
										return (
											<option value={res.Nombre} key={res.IdTarifa}>
												{res.Nombre}
											</option>
										);
									})}
								</datalist>
							</div>
						</div>
						{/*Campo estado*/}
						<label htmlFor='estado'>Estado</label>
						<input
							type='text'
							name='estado'
							list='estado'
							value={regis.estado}
							onDoubleClick={(e) => (e.target.readOnly = false)}
							readOnly={true}
							onChange={(e) => setRegis({ ...regis, estado: e.target.value })}
							placeholder='Estado'
						/>
						<datalist id='estado'>
							{estados.map((row, i) => {
								return (
									<option key={i} value={row.estado}>
										{row.estado}
									</option>
								);
							})}
						</datalist>
						<p className='small bold'>Referencia</p>
						<input
							type='text'
							value={regis.ref}
							onDoubleClick={(e) => (e.target.readOnly = false)}
							readOnly={true}
							onChange={(e) => setRegis({ ...regis, ref: e.target.value })}
						></input>
					</div>
					<div className='text-right'>
						<p className='small bold'>LLegada</p>
						<input
							type='date'
							value={regis.llegada}
							onDoubleClick={(e) => (e.target.readOnly = false)}
							readOnly={true}
							onChange={(e) => setRegis({ ...regis, llegada: e.target.value })}
						></input>

						<p className='small bold'>Salida</p>
						<input
							type='date'
							value={regis.salida}
							onDoubleClick={(e) => (e.target.readOnly = false)}
							readOnly={true}
							onChange={(e) => setRegis({ ...regis, salida: e.target.value })}
						></input>
						<p className='small bold'>.</p>
						<button className='btn btn-dark w-100' onClick={() => setPrompt(true)}>
							Actualizar Datos
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default RegistroSingle;
