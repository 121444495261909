import React from "react";
import ReactPlayer from "react-player";
const Tutoriales = () => {
	return (
		<section className=''>
			<h1 className=''>Tutoriales</h1>
			<article>
				<h2>Reservas</h2>

				<ReactPlayer url='https://vimeo.com/810739365/008c427005' controls='true' />
			</article>
			<article>
				<h2>Registros</h2>
				<ReactPlayer url='https://vimeo.com/810739875/968ca2945a' controls='true' />
			</article>
		</section>
	);
};

export default Tutoriales;
