import React, { useContext } from "react";
import Cargosth from "../cargos/Cargosth";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import Cuentasth2 from "../../componentes/cuentas/Cuentasth";
const Cuentas = () => {
	const cuentaContext = useContext(CuentaContext);
	const { modal, cuenta } = cuentaContext;
	return (
		<>
			<Cuentasth2 />
			{modal && <Cargosth id={cuenta.id} />}
		</>
	);
};

export default Cuentas;
