import { LOG_FEEDBACK, LIMPIAR_LOG_FEEDBACK, SET_LOADING } from "../types.js";
const autReducer = (state, action) => {
	switch (action.type) {
		case LOG_FEEDBACK:
			return {
				...state,
				autloading: false,
				logstatus: action.payload,
			};
		case LIMPIAR_LOG_FEEDBACK:
			return {
				...state,
				autloading: false,
				logstatus: null,
			};
		case SET_LOADING:
			return {
				...state,
				autloading: true,
			};
		default:
			return state;
	}
};
export default autReducer;
