import {
	MODAL,
	LOADING,
	TARIFAS,
	CUENTA,
	CARGOS,
	LIMPIAR,
	ABONOS,
	RENTAS,
} from "../types.js";

const cargoReducer = (state, action) => {
	switch (action.type) {
		case CUENTA:
			return {
				...state,
				cuenta: action.payload,
			};
		case MODAL:
			return {
				...state,
				modal: action.payload,
			};
		case LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case TARIFAS:
			return {
				...state,
				tarifas: action.payload,
			};
		case CARGOS:
			return {
				...state,
				cargos: action.payload,
			};
		case RENTAS:
			return {
				...state,
				rentas: action.payload,
			};
		case ABONOS:
			return {
				...state,
				abonos: action.payload,
			};
		case LIMPIAR:
			return {
				...state,
				cargos: action.payload,
			};
		default:
			return state;
	}
};
export default cargoReducer;
