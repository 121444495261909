import React, { useContext, useEffect, useState } from "react";
import RegistroContext from "../../contexto/registro/registroContext.js";
import RepoCantd from "./RepoCantd.js";

const RepoCanth = () => {
	const registroContext = useContext(RegistroContext);
	const { cancelaciones } = registroContext;
	let dummie = [
		{
			id: 0,
			fid: "No",
			consec: "-",
			tipo: "Sin Tipo",
			referencia: "no hay datos",
			fechao: "no hay dato",
			fechac: "no hay dato",
			cancelo: "no hay dato",
		},
	];
	const meses = [
		"dud",
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];
	let mes = "";

	useEffect(() => {}, []);
	return (
		<div className='mb-2'>
			<table className='w-100 printabla'>
				<thead className='stickiehead'>
					<tr>
						<th>Cuenta o Cheque</th>
						<th>consec</th>
						<th>Tipo</th>
						<th>Referencia</th>
						<th>Fecha inicial</th>
						<th>Fecha cancelacion</th>
						<th>Canceló</th>
					</tr>
				</thead>
				<tbody>
					{Array.isArray(cancelaciones) && cancelaciones.length === 0
						? dummie.map((res) => <RepoCantd key={res.id} cancelacion={res} />)
						: cancelaciones.map((res, i) => <RepoCantd key={i} cancelacion={res} />)}
				</tbody>
			</table>
		</div>
	);
};

export default RepoCanth;
