import React, { useContext, useEffect, useState } from "react";
import RegistroContext from "../../contexto/registro/registroContext.js";
const BusqCan = (props) => {
	const registroContext = useContext(RegistroContext);
	const { traerCancelaciones, cancelaciones, unsetCancelaciones } =
		registroContext;
	let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
	let fecha = new Date(Date.now() - tzoffset).toISOString().slice(0, 7);
	const [mes, setMes] = useState(fecha);
	const [day, setDay] = useState(fecha);
	const [fload, setFload] = useState(false);
	const [feedback, setFeedback] = useState("");
	function daysInMonth(month, year) {
		return new Date(year, month, 0).getDate();
	}
	/////////////////////////////
	//mes change
	const onDateChange = (e) => {
		unsetCancelaciones();
		setMes(e.target.value);
		let tmes = e.target.value;
		let mesArr = tmes.split("-");
		console.log(mesArr);
		const l1 = e.target.value + "-01T00:00:01";
		const l2 =
			e.target.value + "-" + daysInMonth(mesArr[1], mesArr[0]) + "T23:59:59";
		traerCancelaciones(l1, l2);
		setFeedback(e.target.value);
	};

	////////////////////////////
	/// onDayChange
	/////////////////////////////
	//mes change
	const onDayChange = (e) => {
		unsetCancelaciones();
		setDay(e.target.value);
		let tmes = e.target.value;
		const l1 = e.target.value + "T00:00:01";
		const l2 = e.target.value + "T23:59:59";
		traerCancelaciones(l1, l2);
		setFeedback(e.target.value);
	};

	///////////////////////////
	///useEfect
	useEffect(() => {
		let mesArr = mes.split("-");
		const uel1 = mes + "-01T00:00:01";
		const uel2 = mes + "-" + daysInMonth(mesArr[1], mesArr[0]) + "T23:59:59";
		if (cancelaciones.length === 0 && !fload) {
			traerCancelaciones(uel1, uel2);
			setFload(true);
			setFeedback("Mes " + mesArr[1]);
		}
	});

	return (
		<>
			<form className='no-print'>
				<div>
					<label htmlFor='mes'>Buscar por mes</label>
					<input
						className='datemes'
						name='mes'
						type='month'
						value={mes}
						onChange={onDateChange}
					/>
				</div>
				{props.inter ? null : (
					<>
						<label htmlFor='mes'>Buscar por Día</label>
						<input
							className='datemes'
							name='mes'
							type='date'
							value={day}
							style={{ display: "inline-block" }}
							onChange={onDayChange}
						/>
					</>
				)}
			</form>
			<p className='small' style={{ marginRight: "20px", color: "grey" }}>
				Elementos cancelados del: {feedback}
			</p>
		</>
	);
};

export default BusqCan;
