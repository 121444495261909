import React, { useContext } from "react";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";

const Empresatd = ({ empresa: { id, rfc, rsocial, cp, mail } }) => {
	const facturacionContext = useContext(FacturacionContext);
	const { eliminarEmpresa, seleccionaEmpresa } = facturacionContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	/////////////////////////////////////////////////
	//borrar on click
	const delOnClick = async () => {
		let clave = prompt("Ingrese la clave de Administrador");
		if (clave === null) {
			setAlert("Operación cancelada", "danger");
			return;
		}
		let body = {
			id: id,
			clave: clave,
		};
		let resp = await eliminarEmpresa(body);
		console.log(resp);
		if (resp.success) {
			setAlert("Empresa ELiminada", "success");
		} else {
			setAlert(resp, "danger");
		}
	};
	//////////////////////////////////////////////////
	// cargar empresa en click
	const loadEmpresa = (e) => {
		e.preventDefault();
		seleccionaEmpresa(id);
	};

	return (
		<tr id={id} onClick={loadEmpresa} style={{ cursor: "pointer" }}>
			<td>{rfc}</td>
			<td>{rsocial}</td>
			<td>{cp}</td>
			<td>{mail}</td>
			<td>
				<i className='fas fa-times-circle tacha' id={id} onClick={delOnClick}></i>
			</td>
		</tr>
	);
};

export default Empresatd;
