import React, { useContext, useState } from "react";
import ReservaContext from "../../contexto/reservacion/reservaContext.js";
import PropTypes from "prop-types";

const Reservastd = ({
	reserva: {
		folio,
		nombre,
		apellido,
		llegada,
		salida,
		rsocial,
		grupo,
		info,
		ref,
		reg,
		cancelo,
	},
	setPrompt,
	setRid,
}) => {
	const reservaContext = useContext(ReservaContext);
	const { traerReservaSingle, setModalOpen, borrarReserva, limpiarReservas } =
		reservaContext;
	const llegadaF = llegada.split("-");
	const salidaF = salida.split("-");
	const meses = [
		"dud",
		"Enero",
		"Feb",
		"Mar",
		"Abril",
		"Mayo",
		"Jun",
		"Jul",
		"Ago",
		"Sep",
		"Oct",
		"Nov",
		"Dic",
	];

	const fllegada =
		llegadaF[2] +
		"/" +
		meses[llegadaF[1].replace(/^0+/, "")] +
		"/" +
		llegadaF[0].substring(2);
	const fsalida =
		salidaF[2] +
		"/" +
		meses[salidaF[1].replace(/^0+/, "")] +
		"/" +
		salidaF[0].substring(2);

	const oCtraerReserva = async (e) => {
		e.preventDefault();
		setModalOpen();
		traerReservaSingle(folio);
	};

	const delup = (e) => {
		e.stopPropagation();
		setRid(folio);
		setPrompt(true);
	};

	return (
		<tr
			onClick={oCtraerReserva}
			className={`clickable ${cancelo !== null && "reserva-danger noprint"} w-100`}
			style={{}}
		>
			<td>{folio}</td>
			<td>{apellido + " " + nombre}</td>
			<td>{fllegada}</td>
			<td>{fsalida}</td>
			<td>{rsocial}</td>
			<td>{grupo}</td>
			<td>{info}</td>
			<td className='red'>
				{cancelo !== null ? (
					cancelo
				) : reg === 1 ? (
					<i className='fas fa-check-circle check' id={folio}></i>
				) : (
					<i className='fas fa-times-circle tacha' id={folio} onClick={delup}></i>
				)}
			</td>
		</tr>
	);
};
Reservastd.propTypes = {
	reserva: PropTypes.object.isRequired,
};
export default Reservastd;
