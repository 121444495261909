import React, { useContext, useState, useEffect } from "react";
import CargoContext from "../../contexto/cargo/cargoContext";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext.js";
import Transtable from "./Transtable.js";
import Spinner from "../../componentes/elementos/Spinner.js";
const Transaccionesth = () => {
	////////////////////////
	///contextos
	const cargoContext = useContext(CargoContext);
	const cafeteriaContext = useContext(CafeteriaContext);
	const { traerCargosByFechas, cargos, limpiarCargos } = cargoContext;
	const { traerChequesFechas, cheques } = cafeteriaContext;
	const [inicial, setInicial] = useState(false);

	let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
	let hoy = new Date(Date.now() - tzoffset).toISOString().slice(0, 10);
	const [fecha, setFecha] = useState(hoy);
	const [li, setLi] = useState(hoy);
	const [ls, setLs] = useState(false);
	const [feed, setFeed] = useState(false);
	console.log(hoy);

	let end = "";
	if (!inicial) {
		let inl1 = li + "T00:00:01";
		let inl2 = li + "T23:59:59";
		traerCargosByFechas(inl1, inl2);
		traerChequesFechas(inl1, inl2);
		setInicial(true);
	}
	const dommy = {
		titulo: "Sin datos",
		filas: [
			{
				hab: "noHab",
				importe: "$0",
				ref: "sin Ref",
				consec: "0",
				folio: "0",
				fecha: "sin fecha",
				hora: "sin hora",
				cajero: "N/A",
				comentario: "sin dato",
				concepto: "habitacion",
				status: 0,
			},
		],
		total: 0,
	};
	const onliChange = (e) => {
		setLi(e.target.value);
	};
	const onlsChange = (e) => {
		setLs(e.target.value);
	};
	////////////////////////
	///// separador
	let habitaciones = { titulo: "", filas: [], total: 0 };
	let consumos = { titulo: "", filas: [], total: 0 };
	let lavanderias = { titulo: "", filas: [], total: 0 };
	let centronegocios = { titulo: "", filas: [], total: 0 };
	let propinas = { titulo: "", filas: [], total: 0 };
	let efectivos = { titulo: "", filas: [], total: 0 };
	let cxcs = { titulo: "", filas: [], total: 0 };
	let rems = { titulo: "", filas: [], total: 0 };
	let tarjetas = { titulo: "", filas: [], total: 0 };
	let transferencias = { titulo: "", filas: [], total: 0 };
	let habtotal = 0;
	let consumostotal = 0;
	let lavanderiastotal = 0;
	let centronegociostotal = 0;
	let efetotal = 0;
	let cxcstotal = 0;
	let remtotal = 0;
	let tarjetastotal = 0;
	let transfetotal = 0;
	let propinastotal = 0;

	cargos.map((row, i) => {
		////////////////////////
		//inicia rentas
		if (row.concepto === "habitacion") {
			habitaciones.titulo = "Rentas";
			let habrowie = {
				hab: row.habitacion,
				importe:
					"$" +
					(
						parseFloat(row.importe) +
						parseFloat(row.iva) +
						parseFloat(row.ieh)
					).toFixed(2),
				ref: row.referencia,
				consec: row.id,
				folio: row.id,
				cuenta_id: row.cuenta_id,
				fecha: row.fecha.slice(5, 11),
				hora: row.fecha.slice(11, 19),
				cajero: row.cajero,
				comentario: row.comentario,
				concepto: row.concepto,
				status: row.status,
			};
			if (row.status === 0) {
				habtotal =
					habtotal +
					parseFloat(row.importe) +
					parseFloat(row.iva) +
					parseFloat(row.ieh);
			}
			habitaciones.filas.push(habrowie);
			//////////////////
			//inicia consumos
		} else if (row.concepto === "Consumo") {
			consumos.titulo = "Consumos a Habitación";
			let consumorowie = {
				hab: row.habitacion,
				importe:
					"$" +
					(
						parseFloat(row.importe) +
						parseFloat(row.iva) +
						parseFloat(row.ieh)
					).toFixed(2),
				ref: row.referencia,
				consec: row.id,
				folio: row.id,
				cuenta_id: row.cuenta_id,
				fecha: row.fecha.slice(5, 11),
				hora: row.fecha.slice(11, 19),
				cajero: row.cajero,
				comentario: row.comentario,
				concepto: row.concepto,
				status: row.status,
			};
			if (row.status === 0) {
				consumostotal =
					consumostotal +
					parseFloat(row.importe) +
					parseFloat(row.iva) +
					parseFloat(row.ieh);
			}
			consumos.filas.push(consumorowie);
			///////////////////////
			///iniciia lavanderias
		} else if (row.concepto === "lavanderia") {
			lavanderias.titulo = "Lavandería";
			let lavarowie = {
				hab: row.habitacion,
				importe:
					"$" +
					(
						parseFloat(row.importe) +
						parseFloat(row.iva) +
						parseFloat(row.ieh)
					).toFixed(2),
				ref: row.referencia,
				consec: row.id,
				folio: row.id,
				cuenta_id: row.cuenta_id,
				fecha: row.fecha.slice(5, 11),
				hora: row.fecha.slice(11, 19),
				cajero: row.cajero,
				comentario: row.comentario,
				concepto: row.concepto,
				status: row.status,
			};
			if (row.status === 0) {
				lavanderiastotal =
					lavanderiastotal +
					(parseFloat(row.importe) + parseFloat(row.iva) + parseFloat(row.ieh));
			}
			lavanderias.filas.push(lavarowie);
			///////////////////////
			///iniciia cnegocios
		} else if (row.concepto === "CentroNegocios") {
			centronegocios.titulo = "Centro de Negocios";
			let lavarowie = {
				hab: row.habitacion,
				importe:
					"$" +
					(parseFloat(row.importe) + parseFloat(row.iva) + parseFloat(row.ieh)),
				ref: row.referencia,
				consec: row.id,
				folio: row.id,
				cuenta_id: row.cuenta_id,
				fecha: row.fecha.slice(5, 11),
				hora: row.fecha.slice(11, 19),
				cajero: row.cajero,
				comentario: row.comentario,
				concepto: row.concepto,
				status: row.status,
			};
			if (row.status === 0) {
				centronegociostotal =
					centronegociostotal +
					(parseFloat(row.importe) + parseFloat(row.iva) + parseFloat(row.ieh));
			}
			centronegocios.filas.push(lavarowie);
			///////////////////////
			///iniciia propinas
		} else if (row.concepto === "propina") {
			propinas.titulo = "Propinas";
			let propirowie = {
				hab: row.habitacion,
				importe:
					"$" +
					(parseFloat(row.importe) + parseFloat(row.iva) + parseFloat(row.ieh)),
				ref: row.referencia,
				consec: row.id,
				folio: row.id,
				cuenta_id: row.cuenta_id,
				fecha: row.fecha.slice(5, 11),
				hora: row.fecha.slice(11, 19),
				cajero: row.cajero,
				comentario: row.comentario,
				concepto: row.concepto,
				status: row.status,
			};
			if (row.status === 0) {
				propinastotal =
					propinastotal +
					(parseFloat(row.importe) + parseFloat(row.iva) + parseFloat(row.ieh));
			}
			propinas.filas.push(propirowie);
			///////////////////////
			///iniciia efectivo
		} else if (row.concepto === "abono" && row.metodo_pago === "Efectivo") {
			efectivos.titulo = "Efectivo Recepción (Depos)";
			let eferowie = {
				hab: row.habitacion,
				importe: "$" + parseFloat(row.abono),
				ref: row.referencia,
				consec: row.id,
				folio: row.id,
				cuenta_id: row.cuenta_id,
				fecha: row.fecha.slice(5, 11),
				hora: row.fecha.slice(11, 19),
				cajero: row.cajero,
				comentario: row.comentario,
				concepto: row.concepto,
				status: row.status,
			};
			if (row.status === 0) {
				efetotal = efetotal + parseFloat(row.abono);
			}
			efectivos.filas.push(eferowie);
			/////////////////////////
			///iniciia cxc
		} else if (
			row.concepto === "abono" &&
			row.metodo_pago === "cuentas por cobrar"
		) {
			cxcs.titulo = "Cuentas por cobrar";
			let cxcrowie = {
				hab: row.habitacion,
				importe: "$" + parseFloat(row.abono),
				ref: row.referencia,
				consec: row.id,
				folio: row.id,
				cuenta_id: row.cuenta_id,
				fecha: row.fecha.slice(5, 11),
				hora: row.fecha.slice(11, 19),
				cajero: row.cajero,
				comentario: row.comentario,
				concepto: row.concepto,
				status: row.status,
			};
			if (row.status === 0) {
				cxcstotal = cxcstotal + parseFloat(row.abono);
			}
			cxcs.filas.push(cxcrowie);
			//////////////////////////
			////inicia rembolsos
		} else if (row.concepto === "abono" && row.metodo_pago === "Reembolso") {
			rems.titulo = "Rembolsos";
			let remrowie = {
				hab: row.habitacion,
				importe: "$" + parseFloat(row.abono),
				ref: row.referencia,
				consec: row.id,
				folio: row.id,
				cuenta_id: row.cuenta_id,
				fecha: row.fecha.slice(5, 11),
				hora: row.fecha.slice(11, 19),
				cajero: row.cajero,
				comentario: row.comentario,
				concepto: row.concepto,
				status: row.status,
			};
			if (row.status === 0) {
				remtotal = remtotal + parseFloat(row.abono);
			}
			rems.filas.push(remrowie);
			//////////////////////////
			////inicia tarjetas
		} else if (
			row.concepto === "abono" &&
			(row.metodo_pago === "Tarjeta de Credito" ||
				row.metodo_pago === "Tarjeta de Debito")
		) {
			tarjetas.titulo = "Tarjetas de Credito y Debito";
			let tarrowie = {
				hab: row.habitacion,
				importe: "$" + parseFloat(row.abono),
				ref: row.referencia,
				consec: row.id,
				folio: row.id,
				cuenta_id: row.cuenta_id,
				fecha: row.fecha.slice(5, 11),
				hora: row.fecha.slice(11, 19),
				cajero: row.cajero,
				comentario: row.comentario,
				concepto: row.concepto,
				status: row.status,
			};
			if (row.status === 0) {
				tarjetastotal = tarjetastotal + parseFloat(row.abono);
			}
			tarjetas.filas.push(tarrowie);
			//////////////////////////
			////inicia tranferencia
		} else if (row.concepto === "abono" && row.metodo_pago === "Transferencia") {
			transferencias.titulo = "Tranferencias y Depósitos Bancarios";
			let tranferowie = {
				hab: row.habitacion,
				importe: "$" + parseFloat(row.abono),
				ref: row.referencia,
				consec: row.id,
				folio: row.id,
				cuenta_id: row.cuenta_id,
				fecha: row.fecha.slice(5, 11),
				hora: row.fecha.slice(11, 19),
				cajero: row.cajero,
				comentario: row.comentario,
				concepto: row.concepto,
				status: row.status,
			};
			if (row.status === 0) {
				transfetotal = transfetotal + parseFloat(row.abono);
			}
			transferencias.filas.push(tranferowie);
		}
		return true;
	});
	habitaciones.total = "$" + habtotal.toFixed(2);
	consumos.total = "$" + consumostotal.toFixed(2);
	lavanderias.total = "$" + lavanderiastotal.toFixed(2);
	centronegocios.total = "$" + centronegociostotal.toFixed(2);
	propinas.total = "$" + propinastotal.toFixed(2);
	efectivos.total = "$" + efetotal.toFixed(2);
	cxcs.total = "$" + cxcstotal.toFixed(2);
	tarjetas.total = "$" + tarjetastotal.toFixed(2);
	transferencias.total = "$" + transfetotal.toFixed(2);
	/////////////////////////////
	// cheques para consumo

	////////////////////////////
	/// onDayChange
	const traerTransacciones = (e) => {
		e.preventDefault();
		limpiarCargos();
		ls ? (end = ls) : (end = new Date().toISOString());
		console.log(end.substring(0, 19));
		setFeed(
			li.replace("T", " ") + " a " + end.substring(0, 16).replace("T", " ")
		);
		traerCargosByFechas(li, end.substring(0, 16));
		traerChequesFechas(li, end.substring(0, 16));
	};

	//////////////////////
	useEffect(() => {
		if (Object.keys(cargos).length === 0) {
		}
		if (Object.keys(cheques).length === 0) {
		}
	}, [cargos, cheques]);

	return (
		<>
			<div className=''>
				<h2>
					Listado de Transacciones de:
					<span className='medium'> {!feed ? li : feed}</span>
				</h2>
				<form className='text-right noprint grid-3' onSubmit={traerTransacciones}>
					<div>
						<label htmlFor='li'>Limite Interior</label>
						<input
							className='datemes'
							name='mes'
							type='datetime-local'
							value={li}
							style={{ display: "inline-block" }}
							onChange={onliChange}
						/>
					</div>
					<div>
						<label htmlFor='lf'>Limite Superior</label>
						<input
							className='datemes'
							name='mes'
							type='datetime-local'
							value={ls}
							style={{ display: "inline-block" }}
							onChange={onlsChange}
						/>
					</div>
					<button type='submit' className='btn btn-dark'>
						Buscar
					</button>
				</form>
			</div>
			{cargos.length < 1 && <Spinner />}
			{habitaciones.filas.length > 0 ? <Transtable load={habitaciones} /> : null}
			{consumos.filas.length > 0 ? <Transtable load={consumos} /> : null}
			{lavanderias.filas.length > 0 ? <Transtable load={lavanderias} /> : null}
			{centronegocios.filas.length > 0 ? (
				<Transtable load={centronegocios} />
			) : null}
			{propinas.filas.length > 0 ? <Transtable load={propinas} /> : null}
			{efectivos.filas.length > 0 ? <Transtable load={efectivos} /> : null}
			{rems.filas.length > 0 ? <Transtable load={rems} /> : null}
			{cxcs.filas.length > 0 ? <Transtable load={cxcs} /> : null}
			{tarjetas.filas.length > 0 ? <Transtable load={tarjetas} /> : null}
			{transferencias.filas.length > 0 ? (
				<Transtable load={transferencias} />
			) : null}
		</>
	);
};

export default Transaccionesth;
