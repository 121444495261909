import React, { useState, useEffect } from "react";

const Relog = () => {
	const [relogState, setRelogState] = useState();
	const [fechaState, setFechaState] = useState();

	useEffect(() => {
		setInterval(() => {
			const date = new Date();
			setRelogState(date.toLocaleTimeString());
			setFechaState(date.toISOString().split("T")[0]);
		}, 1000);
	}, []);
	return <h2>{fechaState + " " + relogState}</h2>;
};

export default Relog;
