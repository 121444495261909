import React, { useState, useEffect, useContext } from "react";
import Cafeteriatd from "./Cafeteriatd";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import formato from "../../assets/FacturaGlobalCafe33";
import facturacionContext from "../../contexto/facturacion/facturacionContext";
import alertContext from "../../contexto/alerta/alertContext";
import Spinner from "../elementos/Spinnermodal";
import Prompt from "../../assets/Prompt";
import useFacturacion from "../facturacion/useFacturacion";

const Cafeteriath = (props) => {
  ////////////////////////////////////////
  // CONTEXTO
  const cafeteriaContext = useContext(CafeteriaContext);
  const {
    cheque,
    cheques,
    traerChequesFecha,
    setVistaReporte,
    setVistaFacturas,
    vistaFacturas,
  } = cafeteriaContext;
  const {
    traerFolioB,
    timbrarFactura,
    loading,
    setLoading,
    traerFolio,
    //folio,
    //traerFacturasByFechas,
  } = useContext(facturacionContext);
  const { setAlert } = useContext(alertContext);
  const { executeFunction } = useFacturacion();
  ///////////////////////////////////////
  // STATE
  let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

  const [date, setDate] = useState(
    new Date(Date.now() - tzoffset).toISOString().slice(0, 19)
  );
  const [check, setCheck] = useState();
  const [importe, setImporte] = useState(0);
  const [propina, setPropina] = useState(0);
  const [prompt, setPrompt] = useState(false);
  const [cajero, setCajero] = useState("");
  const [pago, setPago] = useState(0);
  const [listaF, setListaF] = useState([]);
  const [status, setStatus] = useState("abierta");
  const [boton, setBoton] = useState([
    { id: "abierta", className: "btn btn-primary" },
    { id: "cerrada", className: "btn noprint" },
    { id: "facturada", className: "btn noprint" },
    { id: "transferida", className: "btn noprint" },
    { id: "cancelada", className: "btn noprint" },
  ]);
  ///////////////////////////////////////
  //METODOS
  const moneyForm = (raw) => {
    const numero = parseFloat(raw);
    if (!isNaN(numero)) {
      const res = "$" + numero.toFixed(2);
      return res;
    } else {
      return "Sin Cargos";
    }
  };
  /////////////////////////////////////////
  //ACTUALIZA LISTA POR STATUS Y TOTALES
  const lista = async (event) => {
    let botones = boton;
    let prop = 0,
      imp = 0,
      pag = 0;
    let cheq = [];
    cheques.forEach((row) => {
      if (row.status === event.target.id) cheq = [...cheq, row];
    });
    botones.forEach((res) => {
      if (event.target.id === res.id) res.className = "btn btn-primary";
      else res.className = "btn noprint";
    });
    cheq.forEach((res) => {
      imp += parseInt(res.importe);
      prop += parseInt(res.propina);
      pag += parseInt(res.pago);
    });
    setListaF(cheq);
    setBoton(botones);
    setStatus(event.target.id);
    setImporte(parseInt(imp));
    setPago(parseInt(pag));
    setPropina(parseInt(prop));
  };

  ////////////////////////////////
  //Vista Reportes
  const reporteTurno = () => {
    setVistaReporte(true);
    traerChequesFecha(props.fecha);
  };

  ////////////////////////////////
  //Vista Reportes
  const reporteFacturas = () => {
    setVistaReporte(false);
    setVistaFacturas(true);
    //traerFacturasByFechas(props.fecha, props.fecha);
  };

  const submitFactura = (event) => {
    setPrompt(true);
  };
  const facturaGlobal = (e) => {
    executeFunction("facturaGlobalB", listaF, e);
  };
  const calculo = () => {
    let imp = 0,
      prop = 0,
      pag = 0;
    let cheq = [];
    if (
      Object.keys(cheques).length > 0 &&
      cheques !== [] &&
      cheques[0].id !== 0
    ) {
      cheques.forEach((row) => {
        if (row.status === status) cheq = [...cheq, row];
      });
      cheq.forEach((res) => {
        imp += parseInt(res.importe);
        prop += parseInt(res.propina);
        pag += parseInt(res.pago);
      });
    }
    if ((listaF.length === 0 && cheq.length !== 0) || listaF !== cheq) {
      cheq = cheq.sort((a, b) => a.id - b.id);
      setListaF(cheq);
    }
    setImporte(parseInt(imp));
    setPago(parseInt(pag));
    setPropina(parseInt(prop));
  };

  useEffect(() => {
    /*if (
			props.fecha !== "" &&
			(Object.keys(cheques).length === 0 || cheques[0].id === 0)
		) {
			traerChequesFecha(props.fecha);
			traerFolio("B");
		}*/
    if (!check && props.fecha !== "") {
      traerChequesFecha(props.fecha);
      traerFolio("B");
      setCheck(cheques);
    }
    calculo();
  }, [status, cheque, cheques, props.fecha, vistaFacturas]);

  return (
    <>
      <Prompt
        onClose={setPrompt}
        show={prompt}
        setKey={setCajero}
        onValidKey={facturaGlobal}
        mensaje="Está por timbrar la factura global del día, ingrese su contraseña"
      />
      <button onClick={lista} {...boton[0]}>
        ACTIVAS
      </button>
      <button onClick={lista} {...boton[1]}>
        CERRADAS
      </button>
      <button onClick={lista} {...boton[2]}>
        TIMBRADAS
      </button>
      <button onClick={lista} {...boton[3]}>
        TRANSFERIDAS
      </button>
      <button onClick={lista} {...boton[4]}>
        CANCELADAS
      </button>
      {loading && <Spinner />}
      <table className="w-100">
        <thead>
          <tr>
            {/*						<th>Factura</th>	*/}
            <th>Cheque</th>
            <th>Fecha</th>
            <th>Hora</th>
            <th>Mesa</th>
            <th>Room S.</th>
            <th>Habitacion</th>
            <th>Importe</th>
            <th>Pago</th>
            <th>Propina</th>
            <th>Forma Pago</th>
          </tr>
        </thead>
        <tbody>
          {listaF.map((res) => (
            <Cafeteriatd key={res.id} cuenta={res} />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="5"></td>
            <th>Totales</th>
            <th>{moneyForm(importe)}</th>
            <th>{moneyForm(importe)}</th>
            <th>{moneyForm(propina)}</th>
          </tr>
        </tfoot>
      </table>
      <button className="btn noprint" onClick={reporteTurno}>
        VISTA REPORTE---
      </button>
      {status === "cerrada" ? (
        <button onClick={submitFactura} className="btn btn-primary noprint">
          Factura Global del Día
        </button>
      ) : null}
      {status === "facturada" ? (
        <button onClick={reporteFacturas} className="btn btn-primary noprint">
          Reporte de Facturas
        </button>
      ) : (
        <br />
      )}
    </>
  );
};

export default Cafeteriath;
