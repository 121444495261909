import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import Spinner from "../elementos/Spinner";
import AlertContext from "../../contexto/alerta/alertContext";
import Facturaconceptostd from "./Facturaconceptostd.js";

const Facturaconceptosth = () => {
	///////////////////////////////////////////////
	// CONTEXT
	const cuentaContext = useContext(CuentaContext);
	const { cuenta, traerCuenta, cerrarCuentaById } = cuentaContext;

	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	const [newCargo, setNewCargo] = useState(false);
	const { folio } = useParams();
	const [cuenta_id, setCuenta_id] = useState(folio);

	const {
		cuenta: id,
		apellido,
		nombre,
		tarifa,
		salida,
		mail,
		habitaciones,
		conceptos,
		subtotal,
		impuestos,
		abonado,
	} = cuenta;

	const subtotalf = parseFloat(subtotal);
	const impuestosf = parseFloat(impuestos);
	const abonadof = parseFloat(abonado);
	const subneto = subtotalf + impuestosf;
	const saldo = subneto - abonadof;
	let habiArr = [];
	let fsalida = "";
	let habitacionesF = "";
	let habtit;
	if (Object.keys(cuenta).length > 0 && typeof conceptos !== "undefined") {
		const {
			cabono,
			ccajero,
			ccomentario,
			cconcepto,
			cid,
			cieh,
			cimporte,
			civa,
			cmetodo_pago,
			creferencia,
		} = conceptos;
		habitaciones.map((row) => {
			habitacionesF === ""
				? (habitacionesF += row)
				: (habitacionesF += ", " + row);
		});
		habitaciones.length > 0
			? (habtit = "Habitaciones ")
			: (habtit = "Habitación ");
		const fechaS = salida.split(" ");
		const salidaF = fechaS[0].split("-");

		const meses = [
			"dud",
			"Enero",
			"Feb",
			"Mar",
			"Abril",
			"Mayo",
			"Jun",
			"Jul",
			"Ago",
			"Sep",
			"Oct",
			"Nov",
			"Dic",
		];
		fsalida =
			salidaF[0] + "/" + meses[salidaF[1].replace(/^0+/, "")] + "/" + salidaF[2];
		habiArr = [...new Set(habitaciones)];
	}

	///////////////////////////////
	//formato de numero
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};

	useEffect(() => {
		if (Object.keys(cuenta).length === 0) {
			traerCuenta(cuenta_id);
		}
	}, [cuenta]);
	if (Object.keys(cuenta).length === 0) return <Spinner />;
	return (
		<>
			<div className='xmodalOver'>
				<table className='w-100'>
					<thead>
						<tr>
							<th>Fecha</th>
							<th>Hora</th>
							<th>Clave</th>
							<th>Unidad</th>
							<th>Referencia</th>
							<th>Concepto</th>
							<th>
								Cargos <span className='minith'>(I.V.A. incluido)</span>
							</th>
							<th>Abonos</th>
						</tr>
					</thead>
					<tbody>
						{Array.isArray(conceptos)
							? conceptos.map((res) =>
									res.status === 0 ? (
										<Facturaconceptostd key={res.id} cargo={res} />
									) : null
							  )
							: null}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default Facturaconceptosth;
