import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CuentaContext from "../../contexto/cuenta/cuentaContext.js";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
import logo from "../../assets/logo.png";
import Spinner from "../elementos/Spinner.js";
import Facturaconceptosth from "./Facturaconceptosth.js";
import Base from "../../assets/FacturaPuGe.js";
import metodosdp from "../../assets/metodosdp.js";
import Conceptos from "../../assets/conceptos.js";
import { useParams } from "react-router-dom";

const Facturarglobal = () => {
	const cuentaContext = useContext(CuentaContext);
	const facturacionContext = useContext(FacturacionContext);
	const alertContext = useContext(AlertContext);
	const { traerCuenta, cuenta } = cuentaContext;
	const { traerFolioA, timbrarFactura } = facturacionContext;
	const { setAlert } = alertContext;
	const navigate = useNavigate();
	const [formaPago, setformaPago] = useState(null);
	const [metodoPago, setmetodoPago] = useState(null);
	const [comentario, setComentario] = useState("");
	const [spin, setSpin] = useState(false);
	const folio = useParams();
	let fpTxt = "";

	////////////////////////////
	//destruc
	const {
		abondado,
		apellido,
		apertura,
		aperturo,
		cerro,
		ciudad,
		cp,
		cliente_id,
		conceptos,
		credito,
		cuenta: cuenta_id,
		direccion,
		estado,
		grupo,
		habitaciones,
		hora,
		impuestos,
		iva,
		ieh,
		info,
		llegada,
		mail,
		nohabitaciones,
		nombre,
		personas,
		reserva_id,
		regimen,
		rfc,
		rsocial,
		salida,
		status,
		subtotal,
		tarifa,
		tel,
	} = cuenta;
	let total = parseFloat(subtotal) + parseFloat(iva) + parseFloat(ieh);
	let sattotal =
		parseFloat(subtotal) +
		parseFloat(iva) +
		parseFloat(Math.trunc(ieh * 100) / 100);
	let trueieh = parseFloat(Math.trunc(ieh * 100) / 100);
	let tmst = total - sattotal;
	let habitacionarr = "";
	//trueieh = trueieh + 0.01;
	////////////////////////////
	//metodo para traer cuenta
	const getCuenta = async () => {
		const resp = await traerCuenta(folio);
		if (resp.success) {
		}
	};
	habitaciones.map((row) => {
		if (habitacionarr === "") {
			habitacionarr = row.habitacion;
		} else {
			habitacionarr = ", " + row.habitacion;
		}
	});

	////////////////////////////
	//propinas
	let propinas = 0;
	if (typeof conceptos !== "undefined" && conceptos.length >= 1) {
		conceptos.map((row, i) => {
			if (row.concepto === "propina") {
				propinas += parseFloat(row.importe);
			}
		});
	}
	////////////////////////////
	//cnegocios
	let cnegocios = 0;
	if (typeof conceptos !== "undefined" && conceptos.length >= 1) {
		conceptos.map((row, i) => {
			if (row.concepto === "CentroNegocios") {
				cnegocios += parseFloat(row.importe);
			}
		});
	}
	////////////////////////////
	//hospedaje
	let hospedaje = 0;
	if (typeof conceptos !== "undefined" && conceptos.length >= 1) {
		conceptos.map((row, i) => {
			if (row.concepto === "habitacion") {
				hospedaje += parseFloat(row.importe);
			}
		});
	}
	////////////////////////////
	//cafeteria
	let cafeteria = 0;
	if (typeof conceptos !== "undefined" && conceptos.length >= 1) {
		conceptos.map((row, i) => {
			if (row.concepto === "Consumo") {
				cafeteria += parseFloat(row.importe);
			}
		});
	}
	////////////////////////////
	//bar
	let bar = 0;
	if (typeof conceptos !== "undefined" && conceptos.length >= 1) {
		conceptos.map((row, i) => {
			if (row.concepto === "bar") {
				bar += parseFloat(row.importe);
			}
		});
	}
	////////////////////////////
	//lavanderia
	let lavanderia = 0;
	if (typeof conceptos !== "undefined" && conceptos.length >= 1) {
		conceptos.map((row, i) => {
			if (row.concepto === "lavanderia") {
				lavanderia += parseFloat(row.importe);
			}
		});
	}

	///////////////////////////////
	//money
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};
	/////////////////////////////////
	// metodo para definir metodo
	const defForma = (e) => {
		e.preventDefault();
		setformaPago(e.target.value);
	};

	/////////////////////////////////
	// metodo para definir metodo
	const onChangeComentario = (e) => {
		setComentario(e.target.value);
	};

	///////////////////////////////////////
	//newtab
	const openInNewTab = (url) => {
		window.open(url, "_blank", "noopener,noreferrer");
	};

	////////////////////////////////
	//timbrar
	const timbrar = async () => {
		let usr = prompt(
			"Esta por timbrar la siguiente factura como PUBLICO GENERAL ingrese su contraseña para continuar"
		);
		if (usr === null) {
			return;
		}
		const serfol = await traerFolioA();
		var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
		var fechatim = new Date(Date.now() - tzoffset).toISOString().slice(0, 19);
		const sub = parseFloat(subtotal); //redondeo el subtotalque viene de la base de datos

		///importo la base que nos enviaron y voy poblando los datos necesarios
		Base.Comprobante.Serie = serfol.serie;
		Base.Comprobante.Folio = serfol.folio;
		Base.Comprobante.Fecha = fechatim;
		Base.Comprobante.FormaPago = formaPago;
		Base.Comprobante.SubTotal = sub.toFixed(2);
		Base.Comprobante.MetodoPago = metodoPago;
		Base.Comprobante.Total = total.toFixed(2);
		//Base.Comprobante.Receptor.Rfc = rfc;
		//Base.Comprobante.Receptor.Nombre = rsocial;
		//Base.Comprobante.Receptor.DomicilioFiscalReceptor = cp;
		//Base.Comprobante.Receptor.RegimenFiscalReceptor = regimen;
		Base.Comprobante.Impuestos.TotalImpuestosTrasladados =
			parseFloat(iva).toFixed(2);
		Base.Comprobante.Conceptos = construirConceptos(conceptos);
		let ivaf = [
			{
				Base: sub,
				Impuesto: "002",
				TipoFactor: "Tasa",
				TasaOCuota: "0.160000",
				Importe: parseFloat(iva), //soliamos ahcer tofix(2)
			},
		];

		let iehf = [
			{
				base: sub,
				ImpLocTrasladado: "ISH",
				TasadeTraslado: "0.03",
				Importe: trueieh, //soliamos ahcer tofix(2)
			},
		];

		Base.Comprobante.Impuestos.Traslados = ivaf;
		if (ieh > 0) {
			Base.Comprobante.Complemento[0].ImpuestosLocales.TotaldeTraslados = trueieh;
			Base.Comprobante.Complemento[0].ImpuestosLocales.TrasladosLocales = iehf;
		}
		setSpin(true);
		let body = {
			body: Base,
			mail: "info@realplazaags.com.mx",
			usr: usr,
			cuenta_id: cuenta_id,
			pdf: {
				nombre: nombre + " " + apellido,
				rsocial: "Público en general",
				rfc: "XAXX010101000",
				regimen: "616",
				uso: "03 Gastos en General",
				direccion: "",
				cp: "20000",
				entrada: llegada,
				salida: salida,
				fpago: formaPago,
				ciudad: "Aguascalientes",
				estado: "Aguascalientes",
				pais: "México",
				mpago: metodoPago,
				conceptos: conceptos.filter((r) => r.status == 0),
				hab: habitacionarr,
				recibe: aperturo,
				cerro: cerro,
				hospedaje: moneyForm(hospedaje),
				cafeteria: moneyForm(cafeteria),
				bar: moneyForm(bar),
				lavanderia: moneyForm(lavanderia),
				cnegocios: moneyForm(cnegocios),
				subtotal: moneyForm(subtotal),
				iva: moneyForm(iva),
				ieh: moneyForm(ieh),
				total: moneyForm(total),
				comentarios: comentario,
			},
		};
		const resp = await timbrarFactura(body);
		//console.log(resp.data.data.code);
		if (resp.success) {
			setAlert("Factura prcesada con éxito", "success");
			openInNewTab("https://www.realplaza.co/v1/salida/" + resp.messages[1]);
			navigate(`/cuentas`);
		} else {
			if (resp.data.data.code === "CFDI33118") {
				body.body.Comprobante.Complemento[0].ImpuestosLocales.TotaldeTraslados =
					trueieh + 0.01;
				body.body.Comprobante.Complemento[0].ImpuestosLocales.TrasladosLocales[0].Importe =
					trueieh + 0.01;
				setAlert("Procesando...", "light");
				const resp2 = await timbrarFactura(body);
				if (resp2.success) {
					setAlert("Factura prcesada con éxito", "success");
					openInNewTab("https://www.realplaza.co/v1/salida/" + resp2.messages[1]);
					//navigate(`/cuentas`);
				} else {
					setAlert("falla decimal", "danger");
				}
			} else {
				resp.data.data.code === "CFDI40158"
					? setAlert(
							"El régimen Fiscal no coincide con el registrado ante el SAT",
							"danger"
					  )
					: resp.data.data.code === "CFDI33132"
					? setAlert("El RFC del receptor no existe en la lista del SAT", "danger")
					: setAlert("falla no identificada", "danger");
			}
		}
		setSpin(false);
	};

	///////////////////////////////
	//construir conceptos

	const construirConceptos = (conceptos) => {
		let returnData = [];
		conceptos.map((row, i) => {
			if (row.status == 0) {
				let conceptox = {
					ClaveProdServ: "",
					NoIdentificacion: "a",
					Cantidad: "1",
					ClaveUnidad: "ACT",
					Descripcion: "Venta",
					ValorUnitario: "",
					Importe: "",
					ObjetoImp: "02",
					Impuestos: {
						Traslados: [],
					},
				};
				let iva = {
					Base: "",
					Impuesto: "002",
					TipoFactor: "Tasa",
					TasaOCuota: "0.160000",
					Importe: "",
				};
				let ieh = {
					Base: "",
					Impuesto: "003",
					TipoFactor: "Tasa",
					TasaOCuota: "0.030000",
					Importe: "",
				};
				let clave = Conceptos.filter((r) => row.concepto === r.value);
				if (row.concepto !== "propina" && row.concepto !== "abono") {
					conceptox.ClaveProdServ = "01010101";
					conceptox.NoIdentificacion = clave[0].clave;
					conceptox.ValorUnitario = row.importe;
					conceptox.Importe = parseFloat(row.importe);
					iva.Base = parseFloat(row.importe);
					iva.Importe = parseFloat(row.iva);
					ieh.Base = parseFloat(row.importe);
					ieh.Importe = parseFloat(row.ieh);
					conceptox.Impuestos.Traslados[0] = iva;
					//row.concepto === "habitacion" && (conceptox.Impuestos.Traslados[1] = ieh);
					returnData.push(conceptox);
				}
			}
		});
		return returnData;
	};

	///////////////////////////////
	//useEffects
	useEffect(() => {
		formaPago !== "99" ? setmetodoPago("PUE") : setmetodoPago("PPD");
	}, [formaPago]);

	return (
		<div style={{ borderTop: "solid #dddddd 1px" }}>
			<h1
				style={{
					backgroundColor: "red",
					textAlign: "center",
					color: "white",
				}}
			>
				Facturar Público en General1
			</h1>
			<div>
				<p className='medium bold'>Comentarios</p>
				<input type='text' onChange={onChangeComentario} value={comentario} />
			</div>
			{spin && (
				<>
					<h2>Procesando timbrado...</h2>
					<Spinner />
				</>
			)}
			{status === "cerrada" && (
				<div className='grid-2 mt-1 noprint'>
					<select name='metodo' onChange={defForma}>
						<option value='PUE'>--Seleccione una Forma de Pago--</option>
						{metodosdp.map((row, i) => (
							<option key={i} value={row.claveSat}>
								{row.nombre}
							</option>
						))}
					</select>
					{formaPago ? (
						<button onClick={timbrar} className='btn btn-dark my'>
							Timbrar 33
						</button>
					) : null}
				</div>
			)}
			<header className='grid-3 p'>
				<div className='gspan-2 grid-2'>
					<h1 className='gspan-2'>Real Plaza Aguascalientes S.A. De C.V.</h1>
					<div>
						<p className='small my'>Adolfo López Mateos 320 Ote.</p>
						<p className='small my'>C.P. 20000 Zona Centro.</p>
						<p className='small my'>Tel. (449) 968-27-27</p>
						<p className='small my'>Aguascalientes Ags. México.</p>
					</div>
					<div>
						<p className='small my'>www.realplazaags.com</p>
						<p className='small my'>RFC RPA210618HE5</p>
						<p className='small my'>Público en General</p>
					</div>
				</div>
				<div style={{ width: "65%" }}>
					{" "}
					<img src={logo} alt='Logo nav' />
				</div>
				<div className='gspan-3 all-center-row w-100'>
					<p>Expedido en Aguascalientes Ags.</p>
					<p className=''>601 Régimen General de Ley Personas Morales</p>
					<p className=''>Estado de cuenta</p>
				</div>
			</header>
			<section className='datos grid-2 grad p'>
				<article className='receptor'>
					<h3>Cuenta: </h3>
					<p>
						Nombre: <strong>{apellido + " " + nombre} </strong>
					</p>
					<p>
						Compañia: <strong>{rsocial}</strong>
					</p>
					<p>
						Direccion: <strong>{direccion}</strong>
					</p>
					<p>
						Código postal: <strong>{cp}</strong>
					</p>
					<p>
						Estado país:{" "}
						<strong>
							{ciudad + " "} {estado + " "} México
						</strong>
					</p>
				</article>
				<article className='fechas'>
					<h3 className='floatright'>Folio:</h3>
					<p>
						RFC: <strong>{rfc}</strong>
					</p>
					<p>
						Entrada: <strong>{llegada}</strong>
					</p>
					<p>
						Salida: <strong>{salida}</strong>
					</p>
					<p>
						forma de pago: <strong>{formaPago + fpTxt}</strong>
					</p>
					<p>
						Método de Pago: <strong>{metodoPago}</strong>
					</p>
				</article>
			</section>
			<Facturaconceptosth />
			<section
				className='p my-1 w-100'
				style={{ display: "flex", justifyContent: "flex-end" }}
			>
				<div
					style={{
						width: "50%",
						background: "rgba(235,235,235,0.7)",
						padding: "15px",
					}}
					className='grid-4'
				>
					<div className='gspan-4 grid-3'>
						<div>
							<p className='small'>Hab.</p>
							<p className='small'>{habitacionarr}</p>
						</div>
						<div>
							<p className='small'>Recibe.</p>
							<p className='small'>{aperturo}</p>
						</div>
						<div>
							<p className='small'>Salida.</p>
							<p className='small'>{cerro}</p>
						</div>
					</div>
					<div>
						<p className='medium my'>Hospedaje</p>
						<p className='medium my'>Cafetería</p>
						<p className='medium my'>Bar</p>
						<p className='medium my'>Lavandería</p>
					</div>
					<div>
						<h3 className='medium my'>{moneyForm(hospedaje)}</h3>
						<h3 className='medium my'>{moneyForm(cafeteria)}</h3>
						<h3 className='medium my'>{moneyForm(bar)}</h3>
						<h3 className='medium my'>{moneyForm(lavanderia)}</h3>
					</div>
					<div>
						<p className='medium my'>I.V.A. 16%</p>
						<p className='medium my'>Imp. Est.3%</p>
						<p className='medium my'>C. Negocios</p>
						<p className='medium my'>Propinas</p>
						<p className='medium my'>
							<strong>Total</strong>
						</p>
					</div>
					<div className='text-right'>
						<h3 className='medium my'>{moneyForm(iva)}</h3>
						<h3 className='medium my'>{moneyForm(ieh)}</h3>
						<h3 className='medium my'>{moneyForm(cnegocios)}</h3>
						<h3 className='medium my'>{moneyForm(propinas)}</h3>
						<h3 className='medium my'>{moneyForm(total)}</h3>
					</div>
				</div>
			</section>
			<div className='w-100'>
				<p className='small'>Uso de CFDI G03 Gastos en General</p>
			</div>
		</div>
	);
};
//40694
export default Facturarglobal;
