import React from "react";

const Reservaprint = (props) => {
	const {
		apellido,
		ciudad,
		estado,
		folio,
		grupo,
		habitaciones,
		info,
		llegada,
		mail,
		nombre,
		personas,
		ref,
		salida,
		rfc,
		rsocial,
		tel,
		reservo,
	} = props.reserva;
	const tarrres = props.tarif;

	return (
		<div
			className='reserbody'
			style={{ width: "115mm", padding: "0 4mm", height: "150mm" }}
		>
			<section className='w-100 text-right' style={{ height: "22mm" }}>
				<h2 className='' style={{ margin: "0", fontSize: "7mm" }}>
					Folio:{folio}
				</h2>
				<p className='medium'>
					{habitaciones} Habitaciones {personas} Personas
				</p>
			</section>
			<section style={{ marginBottom: "2mm" }}>
				<p className='small' style={{ color: "rgba(0,0,0,0.7)" }}>
					Favor de proporcionar sus datos fiscales al registrase, así como checar su
					factura al momento de pedir su cuenta.
				</p>
			</section>
			<table className='w-100'>
				<tbody>
					<tr style={{ backgroundColor: "transparent !important" }}>
						<td colSpan='2'>
							<p className='small' style={{ fontWeight: "bold" }}>
								Nombre Huesped:
							</p>
							<p className='small my-03'>{apellido + " " + nombre}</p>
						</td>
						<td>
							<p className='small' style={{ fontWeight: "bold" }}>
								llegada:
							</p>
							<p className='small my-03'>{llegada}</p>
						</td>
						<td>
							<p className='small ' style={{ fontWeight: "bold" }}>
								Salida:
							</p>
							<p className='small my-03'>{salida}</p>
						</td>
					</tr>
					<tr>
						<td colSpan='2'>
							<p className='small ' style={{ fontWeight: "bold" }}>
								Empresa:
							</p>
							<p className='small my-03'>{rsocial}</p>
						</td>
						<td>
							<p className='small ' style={{ fontWeight: "bold" }}>
								RFC:
							</p>
							<p className='small my-03'>{rfc}</p>
						</td>
						<td>
							<p className='small ' style={{ fontWeight: "bold" }}>
								Grupo:
							</p>
							<p className='small my-03'>{grupo}</p>
						</td>
					</tr>
					<tr>
						<td colSpan='4'>
							<p className='small mzero' style={{ fontWeight: "bold" }}>
								Habitaciones y Tarifas
							</p>
							<p className='small my-03'>{props.tarif}</p>
						</td>
					</tr>
					<tr>
						<td colSpan='2'>
							<p className='small mzero' style={{ fontWeight: "bold" }}>
								Info:
							</p>
							<p className='small my-03'>{info}</p>
						</td>
						<td colSpan='2'>
							<p className='small mzero' style={{ fontWeight: "bold" }}>
								Referencia:
							</p>
							<p className='small my-03'>{ref}</p>
						</td>
					</tr>
					<tr>
						<td
							colSpan='4'
							style={{ borderTop: "solid 1px #888", paddingTop: "2mm" }}
						>
							<ul style={{ listStyleType: "circle", marginLeft: "4mm" }}>
								<li className='mini'>
									Estamos de acuerdo en ocupar la habitación desde las 11:00 hrs. y
									desocuparla máximo a las 13:00hrs el día de mi salida
								</li>
								<li className='mini'>No se aceptan mascotas.</li>
								<li className='mini'>
									He convenido con el Hotel que el precio de hospedaje que recibiré será
									de ________________________ sin incluir alimentos ni otros consumos.
								</li>
								<li className='mini'>
									Por seguridad, no se permite el acceso de visitas a la habitación.
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default Reservaprint;
