import React, { useContext } from "react";
import PropTypes from "prop-types";
import CargosContext from "../../contexto/cargo/cargoContext.js";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import AlertContext from "../../contexto/alerta/alertContext";

const Cargostdlight = ({
	cargo: {
		id,
		cuenta_id,
		fecha,
		habitacion,
		inporte,
		abono,
		concepto,
		referencia,
		importe,
		iva,
		ieh,
		metodo_pago,
		comentario,
		cajero,
		status,
	},
}) => {
	const cargoContext = useContext(CargosContext);
	const alertContext = useContext(AlertContext);
	const cuentaContext = useContext(CuentaContext);
	const { traerCuenta } = cuentaContext;
	const { borrarCargo } = cargoContext;
	const { setAlert } = alertContext;
	const fechaS = fecha.split(" ");
	const llegadaF = fechaS[0].split("-");

	const meses = [
		"dud",
		"Enero",
		"Feb",
		"Mar",
		"Abril",
		"Mayo",
		"Jun",
		"Jul",
		"Ago",
		"Sep",
		"Oct",
		"Nov",
		"Dic",
	];
	const fllegada =
		llegadaF[2] +
		"/" +
		meses[llegadaF[1].replace(/^0+/, "")] +
		"/" +
		llegadaF[0].substring(2, 4);
	const hora = fechaS[1].substring(0, 5);
	////////////////////////////////
	//formato de numero
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};
	///////////////////////////////////
	//borrar cargos
	const delOnClick = async (e) => {
		prompt(
			"Esta por eliminar el cargo, esta accion no se puede deshacer. ingrse su clave para confirmar"
		);
		const resp = await borrarCargo(e.target.id);
		resp.data.success
			? setAlert("cargo eliminado", "success")
			: setAlert("no se pudo eliminar el cargo", "danger");
		traerCuenta(cuenta_id);
	};
	const total = parseFloat(importe) + parseFloat(iva) + parseFloat(ieh);

	return (
		<tr className={status === 1 ? "cancelada" : ""}>
			<td>{habitacion}</td>
			<td>{fllegada}</td>
			<td>{hora}</td>
			<td>{concepto}</td>
			<td>{metodo_pago}</td>
			<td>{importe !== "0.0000" ? moneyForm(total) : null}</td>
			<td>{abono !== "0.0000" ? moneyForm(abono) : null}</td>
			<td>{referencia}</td>
			<td>{comentario}</td>
			<td>{cajero}</td>
		</tr>
	);
};

Cargostdlight.propTypes = {
	cargo: PropTypes.object.isRequired,
};
export default Cargostdlight;
