import React from "react";

const Transtable = ({ load: { titulo, filas, total } }) => {
	return (
		<>
			<h3>{titulo}</h3>
			<table className='w-100 tightable'>
				<thead className='w-100 stickiehead'>
					<tr className=' w-100'>
						<th>Hab.</th>
						<th>Importe</th>
						<th>Ref.</th>
						<th>Consec.</th>
						<th>Folio</th>
						<th>Cuenta</th>
						<th>Fecha</th>
						<th>Hora</th>
						<th>Cajero.</th>
						<th>Comentario.</th>
					</tr>
				</thead>
				<tbody>
					{filas.map((row, i) => {
						console.log(row.status);

						return (
							<tr key={i} className={row.status === 1 ? "cancelada" : ""}>
								<td>
									<strong>{row.hab}</strong>
								</td>
								<td>
									<strong>{row.importe}</strong>
								</td>
								<td>{row.ref}</td>
								<td>{row.consec}</td>
								<td>{row.folio}</td>
								<td>{row.cuenta_id}</td>
								<td>{row.fecha}</td>
								<td>{row.hora}</td>
								<td>{row.cajero}</td>
								<td>{row.comentario}</td>
							</tr>
						);
					})}
				</tbody>
				<tfoot>
					<tr>
						<td colSpan='2' className='text-right'>
							<strong className='medium'>Total:{total}</strong>
						</td>
					</tr>
				</tfoot>
			</table>
		</>
	);
};

export default Transtable;
