import { LOADING, EMPRESAS, FOLIO, GUARDAR, FACTURAS, FACTURA } from "../types";

const FacturacionReducer = (state, action) => {
	switch (action.type) {
		case EMPRESAS:
			return { ...state, empresas: action.payload, loading: false };
		case LOADING:
			return { ...state, loading: action.payload };
		case FOLIO:
			return { ...state, folio: action.payload };
		case GUARDAR:
			return { ...state, empresa: action.payload };
		case FACTURAS:
			return { ...state, facturas: action.payload };
		case FACTURA:
			return {...state, factura: action.payload};
		default:
			return { ...state };
	}
};

export default FacturacionReducer;
