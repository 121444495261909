import React from "react";

const CargosRAtd = ({
	cargo: {
		habitacion,
		concepto,
		importe,
		iva,
		ieh,
		referencia,
		comentario,
		cajero,
	},
}) => {
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		let negativo = "";
		if (numero < 0) negativo = "-";
		if (!isNaN(numero)) {
			const res = negativo + "$" + Math.abs(numero.toFixed(2));
			return res;
		} else {
			return "Sin Cargos";
		}
	};

	return (
		<tr>
			<td>{habitacion}</td>
			<td>{concepto}</td>
			<td>{moneyForm(importe)}</td>
			<td>{moneyForm(iva)}</td>
			<td>{moneyForm(ieh)}</td>
			<td>
				<strong>{moneyForm(importe + iva + ieh)}</strong>
			</td>
			<td>{comentario}</td>
			<td>{referencia}</td>
		</tr>
	);
};

export default CargosRAtd;
