import React, { useReducer } from "react";
import axios from "axios";
import ReservaContext from "./reservaContext.js";
import ReservaReducer from "./reservaReducer.js";
import {
	RESERVACIONES,
	RESERVACION,
	MODAL,
	CLIENTE,
	TARIFAS,
	SALIDAS,
	RESBACK,
} from "../types";

const ReservaState = (props) => {
	///////////////////
	//State Inicial
	const initialState = {
		reservaciones: [],
		salidas: [],
		reservacion: {},
		resback: {},
		loading: false,
		modal: false,
		cliente: {},
		tarifas: [],
	};

	////////////////////////////
	//traer access token
	const getaccessToken = async () => {
		return window.localStorage.getItem("access_token");
	};

	////////////////////////
	//se declara el dispatch
	const [state, dispatch] = useReducer(ReservaReducer, initialState);

	///////////////////////
	// Crear reserva
	const nuevaReserva = async (body) => {
		try {
			const resp = await axios.post(`/api/reservaciones`, body, {
				headers: { "Content-Type": "application/json" },
			});
			return resp.data;
		} catch (error) {
			return error.response;
		}
	};
	//////////////////////////////////
	// definir reservas
	const setReservaciones = (arr) => {
		dispatch({ type: RESERVACIONES, payload: arr });
	};
	//////////////////////////////////
	// definir reservas
	const setResback = (arr) => {
		dispatch({ type: RESBACK, payload: arr });
	};

	///////////////////////////////
	//borrar reserva
	const borrarReserva = async (id, body) => {
		try {
			const resp = await axios.delete(`/api/reservaciones/${id}`, {
				headers: { "Content-Type": "application/json" },
				data: { usr: body.usr, canord: body.canord },
			});
			return resp.data;
		} catch (error) {
			return error.response.data;
		}
	};
	//////////////////////
	//crear cliente
	const nuevoCliente = async (body) => {
		try {
			const resp = await axios.post(`/api/clientes`, body, {
				headers: { "Content-Type": "application/json" },
			});
			return resp.data;
		} catch (error) {
			return error.response.data;
		}
	};
	//////////////////////
	//crear empresa
	const nuevaEmpresa = async (body) => {
		try {
			const resp = await axios.post(`/api/empresas`, body, {
				headers: { "Content-Type": "application/json" },
			});
			return resp.data;
		} catch (error) {
			return error.response.data;
		}
	};
	/////////////////////
	// traerReservasRec
	const traerReservasRec = async () => {
		try {
			const resp = await axios.get(`/api/reservaciones`);
			dispatch({ type: RESERVACIONES, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error.response.data;
		}
	};

	/////////////////////
	// traerReservasfolio
	const traerReservasApellido = async (apellido) => {
		try {
			//unSetReservas();
			const resp = await axios.get(
				`/api/reservaciones/busqueda/nombre/${apellido}`
			);
			dispatch({ type: RESERVACIONES, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error.response.data;
		}
	};

	/////////////////////
	// traerReservasfolio
	const traerReservasFolio = async (folio) => {
		try {
			const resp = await axios.get(`/api/reservaciones/busqueda/folio/${folio}`);
			dispatch({ type: RESERVACIONES, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error.response.data;
		}
	};

	/////////////////////
	// traerReservasfolio
	const traerReservasCancel = async () => {
		try {
			const resp = await axios.get(`/api/reservaciones/busqueda/canceladas/1`);
			dispatch({ type: RESERVACIONES, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error.response.data;
		}
	};

	/////////////////////
	// traerReservasRangp de fechas
	const traerReservasFechas = async (body) => {
		try {
			//unSetReservas();
			const resp = await axios.post(
				`/api/reservaciones/busqueda/fechas/${body.bsqf1}/${body.bsqf2}`
			);
			dispatch({ type: RESERVACIONES, payload: resp.data.data.reservas });
			return resp.data;
		} catch (error) {
			return error.response.data;
		}
	};

	/////////////////////////////
	// traer reservas pór salidas
	const traerSalidas = async (fecha) => {
		try {
			const resp = await axios.get(`/api/reservaciones/busqueda/salidas/${fecha}`);
			dispatch({ type: SALIDAS, payload: resp.data.data.salidas });
			return resp.data;
		} catch (error) {
			//dispatch({ type: SALIDAS, payload: [] });
			return error.response;
		}
	};
	//////////////////////
	// traer reserva single
	const traerReservaSingle = (rid) => {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/reservaciones/${rid}`)
				.then((resp) => {
					dispatch({ type: RESERVACION, payload: resp.data.data });
					resolve(resp.data);
				})
				.catch((error) => {
					reject(error.response);
				});
		});
	};

	//////////////////////
	// traer cliente single
	const traerClienteSingle = async (rid) => {
		const resp = await axios
			.get(`/api/clientes/${rid}`)
			.then((resp) => {
				dispatch({ type: CLIENTE, payload: resp.data.data });
			})
			.catch((error) => {});
	};

	//////////////////////
	// traer cliente por RFC
	const traerClientePorRfc = async (rfc) => {
		const resp = await axios
			.get(`/api/clientes/rfc/${rfc}`)
			.then((resp) => {
				dispatch({ type: CLIENTE, payload: resp.data.data });
			})
			.catch((error) => {
				console.log(error.response);
			});
	};
	//////////////////////
	// traer tarifas
	const traerTarifas = async () => {
		try {
			const resp = await axios.get(`/api/tarifas`);
			if (resp.data.success) {
				dispatch({ type: TARIFAS, payload: resp.data.data });
				return resp.data;
			}
		} catch (error) {
			return error.response;
		}
	};

	//////////////////////
	//Actualizar Reserva
	const patchReserva = async (body) => {
		try {
			const resp = await axios.patch(`api/reservaciones/${body.folio}`, body, {
				headers: { "Content-Type": "application/json" },
			});
			if (resp.data.success) {
				console.log(resp.data.data);
				dispatch({ type: RESERVACION, payload: resp.data.data.Reservacion });
				return resp.data;
			}
		} catch (error) {
			return error.response;
		}
	};
	//////////////////////
	//Actualizar Reserva
	const patchCliente = async (body) => {
		try {
			const resp = await axios.patch(`api/clientes/${body.id}`, body, {
				headers: { "Content-Type": "application/json" },
			});
			if (resp.data.success) {
				dispatch({ type: CLIENTE, payload: resp.data.data.Cliente });
				return resp.data;
			}
		} catch (err) {
			return err.response;
		}
	};
	/////////////////////
	//check reserva
	const reservaChkId = (id) => {
		return new Promise((rslv, rjct) => {
			const resp = axios.get(`api/reservaciones/check/${id}`);
			resp
				.then((r) => {
					if (r.data.success) {
						rslv(r.data.data);
					}
				})
				.catch((er) => {
					rjct(er.response);
				});
		});
	};

	// set modal
	const setModalOpen = () => {
		dispatch({ type: MODAL, payload: true });
		dispatch({ type: RESERVACION, payload: {} });
	};
	// unset modal
	const unsetModalOpen = () => {
		dispatch({ type: MODAL, payload: false });
	};
	// unsetReservas
	const limpiarReservas = () => {
		dispatch({ type: RESERVACIONES, payload: [] });
	};
	// unsetSalidas
	const limpiarSalidas = () => {
		dispatch({ type: SALIDAS, payload: [] });
	};
	return (
		<ReservaContext.Provider
			value={{
				reservaciones: state.reservaciones,
				salidas: state.salidas,
				reservacion: state.reservacion,
				modal: state.modal,
				cliente: state.cliente,
				tarifas: state.tarifas,
				resback: state.resback,
				nuevaReserva,
				nuevoCliente,
				nuevaEmpresa,
				traerReservasRec,
				traerReservasApellido,
				traerReservasFolio,
				traerReservasFechas,
				traerReservaSingle,
				setModalOpen,
				unsetModalOpen,
				traerClienteSingle,
				traerClientePorRfc,
				traerTarifas,
				limpiarReservas,
				limpiarSalidas,
				patchReserva,
				patchCliente,
				borrarReserva,
				traerSalidas,
				reservaChkId,
				setReservaciones,
				traerReservasCancel,
				setResback,
			}}
		>
			{props.children}
		</ReservaContext.Provider>
	);
};

export default ReservaState;
