import React, { useContext, useEffect, useState } from "react";
import RegistroContext from "../../contexto/registro/registroContext.js";
import AlertContext from "../../contexto/alerta/alertContext";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import tarifas from "../../assets/tarifas";
import Prompt from "../../assets/Prompt.js";

const Habplus = (props) => {
	const registroContext = useContext(RegistroContext);
	const {
		crearRegistro,
		habitaciones: habis,
		traerVaciasL,
		borrarRegistroByHab,
		unsetHabitaciones,
	} = registroContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const cuentaContext = useContext(CuentaContext);
	const { cuenta, traerCuenta } = cuentaContext;
	const {
		cuenta: id,
		reserva_id,
		cliente_id,
		nombre,
		info,
		status,
		habitaciones,
		empresa_id,
		salida,
	} = cuenta;

	const habSolo = habis.map((row) => {
		return row.habitacion;
	});
	///////////////////////////////////////
	const [usr, setUsr] = useState("");
	const [prompt, setPrompt] = useState(false);
	const prompteador = (ev) => {
		ev.preventDefault();
		setPrompt(true);
	};
	///////////////////////////////////////
	//controladires de haitaciones y tarifas
	const [hab, setHab] = useState("");
	const [tar, setTar] = useState("");
	const onChangeSethab = (e) => {
		setHab(e.target.value);
	};
	const onChangeSetTar = (e) => {
		setTar(e.target.value);
	};

	///////////////////////////////////////////////////////////////////////
	// regHabs registra habitaciones o desenlaza habitaciones de la cuenta

	///inicializamos titulos
	let habiArr = [];
	let habitacionesF = "";
	let TarifasF = "";
	let habtit;
	let tartit;
	let habsAct;

	///////////////////////////////////////////
	//si hay cuenta poblamos los titulos acorde
	if (typeof habitaciones !== "undefined" && Object.keys(cuenta).length > 0) {
		habitaciones.map((row) => {
			if (status !== "abierta") {
				habitacionesF === ""
					? (habitacionesF += row.habitacion)
					: (habitacionesF += ", " + row.habitacion);

				TarifasF === ""
					? (TarifasF += row.tarifa)
					: (TarifasF += ", " + row.tarifa);
			} else {
				if (row.status === 1) {
					habitacionesF === ""
						? (habitacionesF += row.habitacion)
						: (habitacionesF += ", " + row.habitacion);

					TarifasF === ""
						? (TarifasF += row.tarifa)
						: (TarifasF += ", " + row.tarifa);
				}
			}
		});
		if (habitaciones.length > 1) {
			habtit = "Habitaciones ";
			tartit = "Tarifas ";
		} else {
			habtit = "Habitación ";
			tartit = "Tarifa ";
		}
		///////////////////////////////////////
		// aislamso habitaciones actuales
		habsAct = habitaciones.filter((row) => {
			if (row.status === 1) {
				return true;
			}
		});
		habiArr = habsAct.map((r) => {
			return r.habitacion;
		});
	}
	const regHabs = async (usr) => {
		await traerVaciasL();
		console.log(habiArr);
		if (habiArr.includes(parseInt(hab))) {
			if (habsAct.length <= 1) {
				setAlert("la cuenta debe tener al menos una habitacion", "danger");
				return;
			}
			if (usr === null) {
				return;
			} else {
				let delbod = {
					hab: hab,
					nombre: nombre,
					cuenta: id,
					info: info,
					razon: "salida por separacion de cuenta",
					tarifa: tar,
					usr: usr,
				};
				const resp = await borrarRegistroByHab(delbod);
				console.log(resp);
				unsetHabitaciones();
				setAlert("habitacion eliminada de la cuenta", "danger");
				traerCuenta(id);
			}
		} else {
			if (habSolo.includes(parseInt(hab))) {
				console.log("entra");
				let body = {
					hplus: true,
					rId: reserva_id,
					cId: cliente_id,
					tId: id,
					eId: empresa_id,
					rSalida: salida,
					usr: usr,
					registros: [
						{
							titular: nombre,
							tarifa: tar,
							habitacion: hab,
							ciudad: "",
							estado: "",
						},
					],
				};
				console.log(body);
				const resp = await crearRegistro(body);
				console.log(resp);
				if (resp.success) {
					traerCuenta(id);
					setAlert("habitación añadida", "success");
				} else {
					console.log(resp);
					setAlert(resp.messages[0], "danger");
				}
			} else {
				traerCuenta(id);
				setAlert("habitación no disponible", "danger");
			}
		}
	};
	useEffect(() => {}, [cuenta]);
	return (
		<>
			<form action='' onSubmit={prompteador} className='grid-3 gspan-3'>
				<input
					name='setHab'
					type='number'
					value={hab}
					placeholder='Habitacion'
					onChange={onChangeSethab}
					list='vaciasl'
					className=''
					required
				/>
				<datalist id='vaciasl'>
					{habSolo.map((res, i) => {
						return (
							<option key={i} value={res}>
								{res}
							</option>
						);
					})}
				</datalist>
				<input
					name='setTar'
					type='text'
					list='tarifas'
					value={tar}
					placeholder='tarifa'
					onChange={onChangeSetTar}
					className=''
					required
				/>
				<datalist id='tarifas'>
					{tarifas.map((res, i) => {
						return (
							<option key={i} value={res.Nombre}>
								{res.Nombre}
							</option>
						);
					})}
				</datalist>
				<button type='submit' className='btn btn-dark'>
					{" "}
					Habitacion +
				</button>
			</form>
			<Prompt
				onClose={setPrompt}
				show={prompt}
				setKey={setUsr}
				onValidKey={regHabs}
			/>
		</>
	);
};

export default Habplus;
