import React from "react";
import {
	REGISTROS,
	REGISTRO,
	MODAL,
	HABITACIONES,
	RENTASA,
	CANCELACIONES,
} from "../types.js";
const registroReducer = (state, action) => {
	switch (action.type) {
		case REGISTROS:
			return {
				...state,
				registros: action.payload,
				loading: false,
			};
		case REGISTRO:
			return {
				...state,
				registro: action.payload,
				loading: false,
			};
		case MODAL:
			return {
				...state,
				modal: action.payload,
			};
		case CANCELACIONES:
			return {
				...state,
				cancelaciones: action.payload,
			};
		case HABITACIONES:
			return { ...state, habitaciones: action.payload };
		case RENTASA:
			return { ...state, rentasa: action.payload };
		default:
			return state;
	}
};

export default registroReducer;
