import React from "react";
import logoc from "../../assets/log-circulo.png";

const Cheque = React.forwardRef((props, ref) => {
  let cheque = props.cheque;
  let fecha, hora;

  const moneyForm = (raw) => {
    const numero = parseFloat(raw);
    let negativo = "";
    if (numero < 0) negativo = "-";
    if (!isNaN(numero)) {
      const res = negativo + "$" + Math.abs(numero.toFixed(2));
      return res;
    } else {
      return "Sin Cargos";
    }
  };
  const mPago = () => {
    let efe = 0;
    let tcred = 0;
    let tdeb = 0;

    if (typeof cheque.rcargos === "object") {
      cheque.rcargos.forEach((row) => {
        if (row.clave === 502) efe += row.importe;
        if (row.clave === 503) tcred += row.importe;
        if (row.clave === 504) tdeb += row.importe;
      });
      if (efe > tcred && efe > tdeb) return "Efectivo";
      else if (tcred > efe && tcred > tdeb) return "T. Crédito";
      else if (tdeb > efe && tdeb > tcred) return "T. Débito";
    }
  };
  const convertirTimestamp = (timestamp) => {
    timestamp = timestamp.split(" ");
    const hora = timestamp[1].substring(0, 5);
    timestamp = timestamp[0].split("-");
    const meses = [
      "dud",
      "Enero",
      "Feb",
      "Mar",
      "Abril",
      "Mayo",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const fecha =
      timestamp[2] +
      "/" +
      meses[timestamp[1].replace(/^0+/, "")] +
      "/" +
      timestamp[0].substring(2, 4);
    return [hora, fecha];
  };
  if (Object.values(cheque).length > 0)
    [hora, fecha] = convertirTimestamp(cheque.apertura);

  const cargos = () => {
    if (cheque.rcargos !== undefined && typeof cheque.rcargos !== "string") {
      return cheque.rcargos.map((row) => {
        if (row.status === 0) return null;
        return (
          <tr>
            <td className="small">{row.descripcion}</td>
            <td className="small">{row.cant}</td>
            <td className="small">${parseFloat(row.precio).toFixed(2)}</td>
            <td className="small">${(row.cant * row.precio).toFixed(2)}</td>
          </tr>
        );
      });
    } else return <tr></tr>;
  };

  const cierre = () => {
    if (cheque.status === "cerrada") {
      return (
        <div
          className="all-center-row"
          style={{ paddingBottom: "1rem", paddingInline: "1rem" }}
        >
          <div className="medium xbold">Método de Pago:</div>
          <div className="medium">{mPago()}</div>
          <div className="medium xbold">Cajero:</div>
          <div className="medium">{cheque.aperturo}</div>
        </div>
      );
    }
  };
  const Ticket = () => {
    return (
      <div className="all-center" style={{ paddingInlineEnd: "1rem" }}>
        <div className="all-center-row">
          <div style={{ width: "25%" }}>
            <img style={{ paddingLeft: "10px" }} src={logoc} alt="logo" />
          </div>
          <div style={{ width: "75%" }}>
            <h1>El Mezzanine</h1>
            <h5>Hotel Real Plaza</h5>
            <p
              style={{
                marginLeft: "0 auto",
                marginRight: "0 auto",
              }}
              className="medium"
            >
              Av. Venustiano Carranza #890
            </p>

            <p
              style={{
                marginLeft: "0 auto",
                marginRight: "0 auto",
              }}
              className="medium"
            >
              Col. Moderna, C.P. 78250,
            </p>
            <p
              style={{
                marginLeft: "0 auto",
                marginRight: "0 auto",
              }}
              className="medium"
            >
              San Luis Potosí, S.L.P.
            </p>
          </div>
        </div>

        <div className="all-center-row" style={{ paddingInlineEnd: "1rem" }}>
          <br />
          <div className="medium">
            {fecha} {hora}
          </div>
        </div>
        <div className="all-center-row" style={{ paddingInline: "2rem" }}>
          <div className="medium">
            {cheque.mesa
              ? "Mesa: " + cheque.mesa
              : cheque.hab
              ? "Habitación: " + cheque.hab
              : ""}
          </div>
          <div className="medium">Cheque: {cheque.id}</div>
        </div>
        <p>---------------------------------------------------------</p>
        <table>
          <thead>
            <tr className="medium">
              <th style={{ width: "50%" }}>Descripción</th>
              <th>Unid</th>
              <th>Precio</th>
              <th>Importe</th>
            </tr>
          </thead>
          <tbody>{cargos()}</tbody>
          <tfoot>
            <td className="small">{props.desc > 0 ? "Descuento: " : ""}</td>
            <td className="small">{props.desc > 0 ? props.desc + "%" : ""}</td>
            <td className="xbold">Total: </td>
            <td>{moneyForm(props.total)}</td>
          </tfoot>
        </table>
        <p>---------------------------------------------------------</p>
        {cierre()}
      </div>
    );
  };

  return (
    <div
      className="grid-2 container-print"
      style={{ marginTop: "2em" }}
      ref={ref}
    >
      <Ticket />
    </div>
  );
});

export default Cheque;
