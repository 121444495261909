import React, { useContext, useState } from "react";
import CuentaContext from "../../contexto/cuenta/cuentaContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";

const BuscCargosByHab = () => {
	//////////////////////////////
	// Contextos
	const cuentaContext = useContext(CuentaContext);
	const { traerCuentaByHab, limpiarEsclavas } = cuentaContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	//////////////////////////////
	// States
	const [habitacion, setHabitacion] = useState("");

	/////////////////////////////
	// onChanges
	const onChangeHab = (e) => setHabitacion(e.target.value);

	/////////////////////////////
	//métodos
	const onSubHab = async (e) => {
		e.preventDefault();
		limpiarEsclavas();
		const resp = await traerCuentaByHab(habitacion);
		console.log(resp);
		resp.success
			? setAlert(`Se encontró la siguiente cuenta`, "success")
			: setAlert(`No se encontraron cuentas con esos parámetros`, "danger");
	};

	return (
		<section>
			<form onSubmit={onSubHab} className='grid-2'>
				<input
					type='text'
					name='habitacion'
					value={habitacion}
					onChange={onChangeHab}
					className='my'
				/>
				<input
					type='submit'
					className='btn btn-dark btn-block my'
					value='Buscar por Habitacion'
				/>
			</form>
		</section>
	);
};

export default BuscCargosByHab;
