import React, { useState } from "react";
import Sabana from "../cargos/Sabana.js";
import Transaccionesth from "../cargos/Transaccionesth.js";
import Salidashuesp from "../cargos/Salidashuesp.js";
import Busqfacxmes from "../facturas/Busqfacxmes.js";
import Repofactmesth from "../facturas/Repofactmesth.js";
import Cuentasth from "../cuentas/Cuentasth.js";
import CargosRAth from "../registros/CargosRAth.js";

const Aditoria = () => {
	const [tab, setTab] = useState("sabana");
	///
	const clickity = (e) => {
		e.preventDefault();
		setTab(e.target.value);
	};
	const ahora = new Date();
	const dia = ahora.toLocaleDateString("es-Mx", {
		day: "numeric",
		month: "long",
		year: "numeric",
	});
	const hora = ahora.toLocaleTimeString();
	return (
		<>
			<div className='grid-2 my-2 noprint'>
				<h1>Reportes de auditoria</h1>
				<div className='grid-3'>
					<button className='btn btn-dark' value='sabana' onClick={clickity}>
						Sábana
					</button>
					<button className='btn btn-dark' value='trans' onClick={clickity}>
						Transacciones
					</button>
					<button className='btn btn-dark' value='salidas' onClick={clickity}>
						Salidas
					</button>
					<button className='btn btn-dark' value='facturas' onClick={clickity}>
						Facturas
					</button>
					<button className='btn btn-dark' value='saldos' onClick={clickity}>
						Saldos
					</button>
					<button className='btn btn-dark' value='rentasa' onClick={clickity}>
						Cargos Auto
					</button>
				</div>
			</div>
			{tab === "sabana" ? (
				<Sabana />
			) : tab === "trans" ? (
				<Transaccionesth />
			) : tab === "salidas" ? (
				<Salidashuesp />
			) : tab === "facturas" ? (
				<>
					<Busqfacxmes inter={false} />
					<Repofactmesth />
				</>
			) : tab === "rentasa" ? (
				<>
					<CargosRAth />
				</>
			) : tab === "saldos" ? (
				<>
					<div className='grid-2'>
						<h2>Saldos</h2>
						<p className='text-right'>
							Habitaciones activas a: <strong>{dia + " " + hora}</strong>
						</p>
					</div>
					<Cuentasth />
				</>
			) : null}
		</>
	);
};

export default Aditoria;
