import React, { useState, useContext } from "react";
import Rfcpickerc from "../facturacion/Rfcpickerc.js";
import FacturacionContext from "../../contexto/facturacion/facturacionContext";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import Prompt from "../../assets/Prompt.js";

const Setempresa = (props) => {
	////////////////////////////////////////
	const facturacionContext = useContext(FacturacionContext);
	const { empresa } = facturacionContext;
	const cuentaContext = useContext(CuentaContext);
	const { traerCuenta, cuentaPatchByFolio } = cuentaContext;
	///////////////////////////////////////
	const [usr, setUsr] = useState("");
	const [prompt, setPrompt] = useState(false);
	const prompteador = (ev) => {
		ev.preventDefault();
		setPrompt(true);
	};
	let id = props.id;
	/////////////////////////
	const setEmpresa = (usr) => {
		let bod = { folio: id, empresa_id: empresa.id };
		typeof empresa !== "undefined" && cuentaPatchByFolio(bod);
		traerCuenta(id);
	};

	return (
		<div className='grid-2 gspan-2'>
			<Rfcpickerc />
			<button className='btn btn-dark' onClick={prompteador}>
				Asignar
			</button>
			<Prompt
				onClose={setPrompt}
				show={prompt}
				setKey={setUsr}
				onValidKey={setEmpresa}
			/>
		</div>
	);
};

export default Setempresa;
