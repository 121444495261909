import React, { useContext, useEffect, useState } from "react";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
const Busqfacxmes = (props) => {
	const facturacionContext = useContext(FacturacionContext);
	const { traerFacturasByFechas, facturas, limpiarFacturas } =
		facturacionContext;
	const { setAlert } = useContext(AlertContext);
	let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
	let fecha = new Date(Date.now() - tzoffset).toISOString().slice(0, 7);
	let fecha1 = new Date(Date.now() - tzoffset)
		.toISOString("yesterday")
		.slice(0, 10);
	let fecha2 = new Date(Date.now() - tzoffset).toISOString().slice(0, 10);
	const [mes, setMes] = useState("");
	const [day, setDay] = useState(fecha);
	const [fload, setFload] = useState(false);
	const [feedback, setFeedback] = useState("");
	function daysInMonth(month, year) {
		return new Date(year, month, 0).getDate();
	}
	/////////////////////////////
	//mes change
	const onDateChange = (e) => {
		limpiarFacturas();
		setMes(e.target.value);
		let tmes = e.target.value;
		let mesArr = tmes.split("-");
		console.log(mesArr);
		const l1 = e.target.value + "-01T00:00:01";
		const l2 =
			e.target.value + "-" + daysInMonth(mesArr[1], mesArr[0]) + "T23:59:59";
		traerFacturasByFechas(l1, l2);
		setFeedback(e.target.value);
	};
	console.log(fecha1);

	////////////////////////////
	/// onDayChange
	/////////////////////////////
	//mes change
	const onDayChange = (e) => {
		limpiarFacturas();
		setDay(e.target.value);
		let tmes = e.target.value;
		const l1 = e.target.value + "T00:00:01";
		const l2 = e.target.value + "T23:59:59";
		traerFacturasByFechas(l1, l2);
		setFeedback(e.target.value);
	};

	const traerASNC = async (uel1, uel2) => {
		let resp = await traerFacturasByFechas(uel1, uel2);
		console.log(resp);

		if (resp.success) {
			setFeedback(fecha2);
		} else {
			setAlert("No hay Facturas registradas este dia aun", "danger");
		}
	};
	///////////////////////////
	///useEfect
	useEffect(() => {
		const uel1 = fecha2 + "T00:00:01";
		const uel2 = fecha2 + "T23:59:59";
		if (facturas.length === 0 && !fload) {
			setFload(true);
			traerASNC(uel1, uel2);
		}
	});

	return (
		<>
			<form className='no-print'>
				<div>
					<label htmlFor='mes'>Buscar por mes</label>
					<input
						className='datemes'
						name='mes'
						type='month'
						value={mes}
						onChange={onDateChange}
					/>
				</div>
				{props.inter ? null : (
					<>
						<label htmlFor='mes'>Buscar por Día</label>
						<input
							className='datemes'
							name='mes'
							type='date'
							value={day}
							style={{ display: "inline-block" }}
							onChange={onDayChange}
						/>
					</>
				)}
			</form>
			<p className='small' style={{ marginRight: "20px", color: "grey" }}>
				Facturas mostradas del: {feedback}
			</p>
		</>
	);
};

export default Busqfacxmes;
