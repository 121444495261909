import React from "react";
//import Inireporeserva from "../reservaciones/Inireporeserva";
import Ocupacion from "../reservaciones/Ocupacion";
import Inisalidas from "../reservaciones/Inisalidas";

const Inicio = () => {
	return (
		<section className='grid-3'>
			<Ocupacion />
			<section className='gspan-2'>
				<Inisalidas />
			</section>
		</section>
	);
};

export default Inicio;
