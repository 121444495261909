import React, { useContext, useEffect, useState } from "react";
import ReservaContext from "../../contexto/reservacion/reservaContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";

const Busqueda = () => {
	//////////////////////////
	// contextos
	const reservaContext = useContext(ReservaContext);
	const {
		traerReservasApellido,
		traerReservasRec,
		traerReservasFolio,
		traerReservasFechas,
		reservaciones,
		setReservaciones,
		limpiarReservas,
		traerReservasCancel,
		setResback,
		resback,
	} = reservaContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const reservasLength = reservaciones.length;

	////////////////////////
	// states
	const [busqApellido, setBusqApellido] = useState("");
	const [busqFolio, setBusqFolio] = useState("");
	const [busqEmpresa, setBusqEmpresa] = useState("");
	const [busqGrupo, setBusqGrupo] = useState("");
	const [busqLlegada, setBusqLlegada] = useState("");
	const [busqSalida, setBusqSalida] = useState("");
	const [bck, setBck] = useState(false);

	///////////////////////
	// onchanges
	const onChangeBusqApellido = async (e) => {
		setBusqApellido(e.target.value);
		if (e.target.value !== "") {
			const restemp = reservaciones;
			setBck(true);
			let filtrado = await restemp.filter((row) => {
				let paterno = row.apellido.split(" ");
				return paterno[0].toLowerCase().includes(e.target.value.toLowerCase());
			});
			if (filtrado.length !== 0) {
				setReservaciones(filtrado);
			} else {
				setAlert("No se encontraron coincidencias", "danger");
				if (resback) {
					setReservaciones(resback);
				} else {
					traerReservasRec();
				}
			}
			setBck(false);
		} else {
			setReservaciones(resback);
		}
	};
	const onChangeBusqEmpresa = async (e) => {
		setBusqEmpresa(e.target.value);
		if (e.target.value !== "") {
			const restemp = reservaciones;
			setBck(true);
			let filtrado = await restemp.filter((row) => {
				let empresa = row.rsocial;
				console.log(empresa);
				if (empresa !== null) {
					return empresa.toLowerCase().includes(e.target.value.toLowerCase());
				}
			});
			console.log(filtrado);
			if (filtrado.length !== 0) {
				setReservaciones(filtrado);
			} else {
				setAlert("No se encontraron coincidencias", "danger");
				if (resback) {
					setReservaciones(resback);
				} else {
					traerReservasRec();
				}
			}
			setBck(false);
		} else {
			setReservaciones(resback);
		}
	};
	const onChangeBusqGrupo = async (e) => {
		setBusqGrupo(e.target.value);
		if (e.target.value !== "") {
			const restemp = reservaciones;
			setBck(true);
			let filtrado = await restemp.filter((row) => {
				let grupo = row.grupo;
				console.log(grupo);
				if (grupo !== null) {
					return grupo.toLowerCase().includes(e.target.value.toLowerCase());
				}
			});
			console.log(filtrado);
			if (filtrado.length !== 0) {
				setReservaciones(filtrado);
			} else {
				setAlert("No se encontraron coincidencias", "danger");
				if (resback) {
					setReservaciones(resback);
				} else {
					traerReservasRec();
				}
			}
			setBck(false);
		} else {
			setReservaciones(resback);
		}
	};
	const onChangeBusqFolio = async (e) => {
		setBusqFolio(e.target.value);
		if (e.target.value !== "") {
			const restemp = reservaciones;
			setBck(true);
			let filtrado = await restemp.filter((row) => {
				let folio = row.folio.toString();
				console.log(folio);

				return folio.includes(e.target.value);
			});
			if (filtrado.length !== 0) {
				setReservaciones(filtrado);
			} else {
				setAlert("No se encontraron coincidencias", "danger");
				if (resback) {
					setReservaciones(resback);
				} else {
					traerReservasRec();
				}
			}
			setBck(false);
		} else {
			setReservaciones(resback);
		}
	};
	const onChangeBusqLlegada = async (e) => {
		setBusqLlegada(e.target.value);
		if (e.target.value !== "") {
			const restemp = reservaciones;
			setBck(true);
			let filtrado = await restemp.filter((row) => {
				let Llegada = row.llegada;
				if (Llegada !== null) {
					return Llegada === e.target.value;
				}
			});
			console.log(filtrado);
			if (filtrado.length !== 0) {
				setReservaciones(filtrado);
			} else {
				setAlert("No se encontraron coincidencias", "danger");
				if (resback) {
					setReservaciones(resback);
				} else {
					traerReservasRec();
				}
			}
			setBck(false);
		} else {
			setReservaciones(resback);
		}
	};
	const onChangeBusqSalida = async (e) => {
		setBusqSalida(e.target.value);
		if (e.target.value !== "") {
			const restemp = reservaciones;
			setBck(true);
			let filtrado = await restemp.filter((row) => {
				let Salida = row.salida;
				if (Salida !== null) {
					return Salida === e.target.value;
				}
			});
			console.log(filtrado);
			if (filtrado.length !== 0) {
				setReservaciones(filtrado);
			} else {
				setAlert("No se encontraron coincidencias", "danger");
				if (resback) {
					setReservaciones(resback);
				} else {
					traerReservasRec();
				}
			}
			setBck(false);
		} else {
			setReservaciones(resback);
		}
	};
	///////////////////////
	// método
	const busControl = async (e) => {
		let ctrl = e.target.value;
		console.log(ctrl);

		switch (ctrl) {
			case "pen":
				const pendientes = traerReservasRec();
				pendientes.then((r) => {
					setResback(r.data);
				});
				break;
			case "rec":
				setBck(true);
				limpiarReservas();
				let li = new Date();
				let ls = new Date();
				li.setMonth(li.getMonth() - 4);
				let body = {};
				body.bsqf1 = li.toISOString().substring(0, 10);
				body.bsqf2 = ls.toISOString().substring(0, 10);
				const resp = traerReservasFechas(body);
				resp.then((r) => {
					r.success && setBck(false);
					r.data !== null && setResback(r.data.reservas);
				});

				break;
			case "tot":
				setBck(true);
				limpiarReservas();
				let bodyt = {};
				bodyt.bsqf1 = "2000-01-01";
				bodyt.bsqf2 = "2200-12-31";
				const respt = traerReservasFechas(bodyt);
				respt.then((r) => {
					console.log(r);
					r.success && setBck(false);
					r.data !== null && setResback(r.data.reservas);
				});
				break;
			case "can":
				setBck(true);
				limpiarReservas();
				const respcan = await traerReservasCancel();
				console.log(respcan);
				respcan.data !== null && setResback(respcan.data);
				setBck(false);
				break;
		}
	};

	///////////////////////////
	const refresh = () => {
		traerReservasRec();
	};
	useEffect(() => {
		if (reservasLength === 0 && !bck) {
			const iniback = traerReservasRec();
			iniback.then((r) => {
				setResback(r.data);
			});
		}
		console.log("carga");

		//eslint-disable-next-line
	}, [reservaciones, resback]);
	return (
		<>
			<section id='busqueda' className='grid-6 noprint'>
				<article className='busqueda busqApellido gspan-2'>
					<label htmlFor='busqApellido'>Buscar por Apellido</label>
					<input
						type='text'
						name='busqApellido'
						value={busqApellido}
						onChange={onChangeBusqApellido}
					/>
					<label htmlFor='busqFolio'>Buscar por Folio</label>
					<input
						type='text'
						name='busqFolio'
						value={busqFolio}
						onChange={onChangeBusqFolio}
					/>
				</article>
				<article className='busqueda busqFolio gspan-2'>
					<label htmlFor='busqFolio'>Buscar por Empresa</label>
					<input
						type='text'
						name='busqFolio'
						value={busqEmpresa}
						onChange={onChangeBusqEmpresa}
					/>
					<label htmlFor='busqApellido'>Buscar por Grupo</label>
					<input
						type='text'
						name='busqApellido'
						value={busqGrupo}
						onChange={onChangeBusqGrupo}
					/>
				</article>
				<article className=''>
					<label>Buscar por Llegada</label>
					<input
						name='range1'
						type='date'
						value={busqLlegada}
						onChange={onChangeBusqLlegada}
						className=''
						required
					/>
					<label>Buscar por salida</label>
					<input
						name='range2'
						type='date'
						value={busqSalida}
						onChange={onChangeBusqSalida}
						className=''
						required
					/>
				</article>
				<article>
					<label>Tipo de busqueda</label>
					<select name='' id='' onChange={busControl}>
						<option>Seleccione</option>
						<option value='pen'>Activas</option>
						<option value='rec'>Recientes</option>
						<option value='can'>Canceladas</option>
						<option value='tot'>Histórico</option>
					</select>
					<label className='grey'>Reiniciar Búsuqeda</label>
					<button className='btn btn-dark w-100' onClick={refresh}>
						Reinicio
					</button>
				</article>
			</section>
		</>
	);
};

export default Busqueda;
