import React, { useContext, useEffect, useState } from "react";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import Empresatd from "./Empresatd.js";
import Spinner from "../elementos/Spinner.js";
const Empresath = () => {
	const facturacionContext = useContext(FacturacionContext);
	const { traerEmpresas, empresas } = facturacionContext;
	const [subset, setSubset] = useState([]);
	const [rfclook, setRfclook] = useState("");
	const [rsolook, setRsolook] = useState("");
	const onChRfclook = (e) => {
		setRsolook("");
		let val = e.target.value.toUpperCase();
		setRfclook(val);
		let newSet = empresas.filter((r) => r.rfc.substring(0, val.length) == val);
		setSubset(newSet);
	};
	const onChRsolook = (e) => {
		setRfclook("");
		let val = e.target.value.toUpperCase();
		setRsolook(val);
		let newSet = empresas.filter(
			(r) => r.rsocial.substring(0, val.length) == val
		);
		setSubset(newSet);
	};
	useEffect(() => {
		if (empresas.length === 0) {
			traerEmpresas();
		} else {
			setSubset(empresas);
		}
	}, [empresas]);
	return (
		<>
			<div
				className='grid-2 my-2'
				style={{ borderTop: "solid 1px grey", paddingTop: "10px" }}
			>
				<div>
					<label htmlFor='rfc'>Buscar por RFC</label>
					<input name='rfc' type='text' value={rfclook} onChange={onChRfclook} />
				</div>
				<div>
					<label htmlFor='Razon'>Buscar por Razón Social</label>
					<input name='Razon' type='text' value={rsolook} onChange={onChRsolook} />
				</div>
			</div>
			<table className='w-100'>
				<thead>
					<tr>
						<th>RFC</th>
						<th>Rason social</th>
						<th>C.P.</th>
						<th>Mail</th>
						<th>Borrar</th>
					</tr>
				</thead>
				<tbody>
					{empresas.length === 0 ? (
						<tr>
							<td colSpan={4}>
								<Spinner />
							</td>
						</tr>
					) : (
						subset.map((row, i) => {
							return <Empresatd key={i} empresa={row} />;
						})
					)}
				</tbody>
			</table>
		</>
	);
};

export default Empresath;
