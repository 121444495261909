import React from "react";
import { useNavigate } from "react-router-dom";

const RepoCantd = ({
	cancelacion: {
		id,
		fid,
		consec,
		tipo,
		referencia,
		fechao,
		fechac,
		cancelo,
		flag,
	},
}) => {
	const navigate = useNavigate();
	const fechaformat = (fecha) => {
		if (fecha !== "" && fecha !== "no hay dato") {
			const fechaS = fecha.split(" ");
			const llegadaF = fechaS[0].split("-");
			const meses = [
				"dud",
				"Enero",
				"Feb",
				"Mar",
				"Abril",
				"Mayo",
				"Jun",
				"Jul",
				"Ago",
				"Sep",
				"Oct",
				"Nov",
				"Dic",
			];
			return (
				llegadaF[2] +
				"/" +
				meses[llegadaF[1].replace(/^0+/, "")] +
				"/" +
				llegadaF[0].substring(2, 4)
			);
		} else {
			return "Sin Fecha";
		}
	};
	let cor = flag === 0 ? "/cargos/" : "/rcargos/";
	const navgo = (e) => {
		e.preventDefault();
		navigate(cor + fid);
	};
	return (
		<tr onClick={navgo} className='clickable2'>
			<td>
				<strong>{fid}</strong>
			</td>
			<td>
				<strong>{consec}</strong>
			</td>
			<td>
				<strong>{tipo}</strong>
			</td>
			<td>{referencia}</td>
			<td>{fechaformat(fechao)}</td>
			<td>{fechaformat(fechac)}</td>
			<td>{cancelo}</td>
		</tr>
	);
};

export default RepoCantd;
