import React, { useContext } from "react";
import PropTypes from "prop-types";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import AlertContext from "../../contexto/alerta/alertContext";

const Rcargostd = ({
  cargo: {
    id,
    cant,
    clave,
    descripcion,
    precio,
    importe,
    tiempo,
    cajero,
    cheque_id,
    status,
  },
  idc,
}) => {
  ////////////////////////////////////////
  //CONTEXTO
  const { borrarCargo, traerCheque, cheque, setLoading } =
    useContext(CafeteriaContext);
  const { setAlert } = useContext(AlertContext);

  ////////////////////////////////////////
  // METODOS
  const convertirTimestamp = (timestamp) => {
    timestamp = timestamp.split(" ");
    const hora = timestamp[1];
    timestamp = timestamp[0].split("-");
    const meses = [
      "dud",
      "Enero",
      "Feb",
      "Mar",
      "Abril",
      "Mayo",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const fecha =
      timestamp[2] +
      "/" +
      meses[timestamp[1].replace(/^0+/, "")] +
      "/" +
      timestamp[0].substring(2, 4);
    return [hora, fecha];
  };

  const moneyForm = (raw) => {
    const numero = parseFloat(raw);
    let negativo = "";
    if (numero < 0) negativo = "-";
    if (!isNaN(numero)) {
      const res = negativo + "$" + Math.abs(numero.toFixed(2));
      return res;
    } else {
      return "Sin Cargos";
    }
  };

  const eliminaCargo = async (user) => {
    setLoading(true);
    const resp = await borrarCargo(id, "-");
    setAlert(resp.data.data.result, "success");
    await traerCheque(cheque_id);
  };
  const [hora, fecha] = convertirTimestamp(tiempo);

  return (
    <tr className={status === 1 ? "clickable" : "text-danger text-line"}>
      <td className="noprint">{idc + 1}</td>
      <td>{cant}</td>
      <td className="">{clave}</td>
      <td>{descripcion}</td>
      <td>{moneyForm(precio)}</td>
      <td>{moneyForm(importe)}</td>
      <td>{fecha}</td>
      <td>{hora}</td>
      <td className="">{cajero}</td>
      <td className="noprint">
        <button
          className={status === 1 ? "noprint" : "noprint hid"}
          onClick={eliminaCargo}
          disabled={cheque.status !== "abierta" ? true : false}
        >
          <img src="/Eliminar.png" alt="icon"></img>
        </button>
      </td>
    </tr>
  );
};

Rcargostd.propTypes = {
  cargo: PropTypes.object.isRequired,
};
export default Rcargostd;
