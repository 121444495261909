import React, { useContext, useEffect } from "react";
import RegistroContext from "../../contexto/registro/registroContext.js";
import Registrostd from "./Registrostd.js";
import Spinner from "../elementos/Spinner.js";

const Registrosth = () => {
	const registroContext = useContext(RegistroContext);
	const { traerRegistros, registros } = registroContext;

	const registroslength = Object.keys(registros).length;

	useEffect(() => {
		if (registroslength === 0) {
			traerRegistros();
		}
	}, [registros, registroslength, traerRegistros]);

	if (registros.length === 0) {
		return <Spinner />;
	} else {
		return (
			<div className=''>
				<table className='my-1 w-100'>
					<thead>
						<tr>
							<th>Habitacion</th>
							<th>Nombre</th>
							<th>Empresa</th>
							<th>Entrada</th>
							<th>Salida</th>
							<th>Tarifa</th>
							<th>Grupo</th>
							<th style={{ width: "5%" }}>Cambio</th>
							<th>Reg</th>
						</tr>
					</thead>
					<tbody>
						{registros.map((res) => (
							<Registrostd key={res.registroid} registro={res} />
						))}
					</tbody>
				</table>
			</div>
		);
	}
};

export default Registrosth;
