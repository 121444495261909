export const catalogo = [
	{ clave: "001", descripcion: "DESAY. CONTINENTAL", precio: "71", room: "81" },
	{
		clave: "001BB",
		descripcion: "DESAY. CONTINENTAL",
		precio: "71",
		room: "81",
	},
	{ clave: "002", descripcion: "HUEVOS DIVORCIADOS", precio: "88", room: "98" },
	{ clave: "003", descripcion: "DESAY. REAL PLAZA", precio: "116", room: "126" },
	{ clave: "004", descripcion: "DESAY. NORTEÑO", precio: "160", room: "170" },
	{ clave: "005", descripcion: "DESAY. MEXICANO", precio: "148", room: "158" },
	{
		clave: "006",
		descripcion: "PLATO DE FRUTAS CON YOGHURT",
		precio: "69",
		room: "79",
	},
	{ clave: "072", descripcion: "PAN DULCE 1 PZA", precio: "15", room: "25" },
	{
		clave: "007",
		descripcion: "ENSALADA DE CITRICOS",
		precio: "80",
		room: "90",
	},
	{
		clave: "008",
		descripcion: "FILETE AL MOJO DE AJO",
		precio: "152",
		room: "162",
	},
	{ clave: "009", descripcion: "FILETE ALMENDRADO", precio: "165", room: "175" },
	{ clave: "010", descripcion: "CEREAL/AVENA", precio: "58", room: "68" },
	{ clave: "011", descripcion: "HOT CAKES NATURALES", precio: "69", room: "79" },
	{
		clave: "012",
		descripcion: "HOT CAKES PREPARADOS",
		precio: "78",
		room: "88",
	},
	{
		clave: "013",
		descripcion: "PAN DULCE ORD. 3 PZAS",
		precio: "40",
		room: "40",
	},
	{ clave: "014", descripcion: "PAN TOSTADO", precio: "35", room: "45" },
	{ clave: "015", descripcion: "BISQUETS", precio: "29", room: "39" },
	{ clave: "016", descripcion: "ORD.JAMON/TOCINO", precio: "43", room: "53" },
	{ clave: "017", descripcion: "HUEVOS NATURALES", precio: "69", room: "79" },
	{ clave: "018", descripcion: "HUEVOS C/JAMON", precio: "81", room: "91" },
	{ clave: "019", descripcion: "HUEVOS RANCHEROS", precio: "75", room: "85" },
	{ clave: "020", descripcion: "OMELETTE NATURAL", precio: "93", room: "103" },
	{
		clave: "021",
		descripcion: "OMELETTE JAMON O QUESO",
		precio: "83",
		room: "93",
	},
	{
		clave: "222",
		descripcion: "OMELETTE AL GUSTO",
		precio: "93",
		room: "103",
	},
	{ clave: "022", descripcion: "CHILAQUILES", precio: "72", room: "82" },
	{
		clave: "023",
		descripcion: "CHILAQUILES CON POLLO",
		precio: "95",
		room: "105",
	},
	{ clave: "024", descripcion: "MOLLETES ESPECIALES", precio: "78", room: "88" },
	{
		clave: "025",
		descripcion: "FILETE A LA VERACRUZANA",
		precio: "152",
		room: "162",
	},
	{ clave: "026", descripcion: "CHOCOLATE", precio: "38", room: "48" },
	{ clave: "027", descripcion: "MALTEADA", precio: "52", room: "62" },
	{ clave: "028", descripcion: "CAFE", precio: "32", room: "42" },
	{ clave: "073", descripcion: "CAPUCHINO", precio: "54", room: "64" },
	{ clave: "029", descripcion: "CAPUCHINO SABORES", precio: "67", room: "77" },
	{ clave: "030", descripcion: "SANDWICH DE POLLO", precio: "84", room: "94" },
	{ clave: "031", descripcion: "COPA YOGURTH", precio: "49", room: "59" },
	{
		clave: "032",
		descripcion: "COCTEL DE CAMARONES",
		precio: "112",
		room: "122",
	},
	{ clave: "033", descripcion: "CHORIQUESO", precio: "90", room: "100" },
	{ clave: "034", descripcion: "SOPA DEL DIA", precio: "70", room: "80" },
	{ clave: "035", descripcion: "CREMA", precio: "78", room: "88" },
	{ clave: "036", descripcion: "ENSALADA MIXTA", precio: "74", room: "84" },
	{ clave: "037", descripcion: "ENSALADA DEL CHEFF", precio: "87", room: "97" },
	{
		clave: "203",
		descripcion: "ENSALADA CESAR POLLO",
		precio: "85",
		room: "95",
	},
	{
		clave: "204",
		descripcion: "POLLO FRITO CON VERDURAS A LA MANT",
		precio: "123",
		room: "133",
	},
	{
		clave: "205",
		descripcion: "POLLO ESTILO MEZANINE",
		precio: "135",
		room: "145",
	},
	{
		clave: "206",
		descripcion: "POLLO CANASTA CON PAPAS",
		precio: "122",
		room: "132",
	},
	{
		clave: "207",
		descripcion: "PECHUGA PARMESANA",
		precio: "135",
		room: "145",
	},
	{ clave: "038", descripcion: "SPAGUETTI ALFREDO", precio: "82", room: "92" },
	{
		clave: "208",
		descripcion: "FETUCCINE ARRABIATA",
		precio: "84",
		room: "94",
	},
	{
		clave: "209",
		descripcion: "FETUCCINE CAMARONES",
		precio: "95",
		room: "105",
	},
	{ clave: "210", descripcion: "SPAGUETTI BOLOGNESA", precio: "89", room: "99" },
	{
		clave: "039",
		descripcion: "LIMONADA O NARANJADA",
		precio: "40",
		room: "50",
	},
	{
		clave: "040",
		descripcion: "POLLO FRITO/CANASTA",
		precio: "122",
		room: "132",
	},
	{
		clave: "041",
		descripcion: "COPA YOGURTH C/FRUTA",
		precio: "57",
		room: "67",
	},
	{ clave: "042", descripcion: "MILANESA C/PAPAS", precio: "149", room: "159" },
	{
		clave: "043",
		descripcion: "CHILAQUILES CON HUEVO",
		precio: "82",
		room: "92",
	},
	{
		clave: "044",
		descripcion: "FILETE A LA TAMPIQUEÑA",
		precio: "182",
		room: "192",
	},
	{ clave: "045", descripcion: "CHILAQ. C/POLLO", precio: "95", room: "105" },
	{
		clave: "046",
		descripcion: "CHILAQ.C/HUEVO",
		precio: "82",
		room: "92",
	},
	{ clave: "047", descripcion: "ENCHILADAS", precio: "88", room: "98" },
	{ clave: "048", descripcion: "TACOS DE POLLO", precio: "86", room: "96" },
	{ clave: "049", descripcion: "TACOS POTOSINOS", precio: "99", room: "109" },
	{ clave: "050", descripcion: "TACOS ROAST BEEF", precio: "129", room: "139" },
	{ clave: "051", descripcion: "TORTAS", precio: "48", room: "58" },
	{ clave: "052", descripcion: "FLAUTAS POTOSINAS", precio: "95", room: "105" },
	{
		clave: "053",
		descripcion: "PUNTAS A LA MEXICANA",
		precio: "147",
		room: "157",
	},
	{ clave: "054", descripcion: "CLUB REAL PLAZA", precio: "93", room: "103" },
	{ clave: "055", descripcion: "SANDWICH", precio: "84", room: "94" },
	{
		clave: "056",
		descripcion: "HAMBURGUESA C/QUESO",
		precio: "98",
		room: "108",
	},
	{ clave: "057", descripcion: "PEPITOS DE RES", precio: "122", room: "132" },
	{ clave: "058", descripcion: "PASTEL DE LA CASA", precio: "60", room: "70" },
	{ clave: "059", descripcion: "JARRA DE CAFE", precio: "105", room: "115" },
	{ clave: "060", descripcion: "HELADO", precio: "58", room: "68" },
	{ clave: "061", descripcion: "TRES MARIA", precio: "75", room: "85" },
	{ clave: "262", descripcion: "BANANA SPLIT", precio: "75", room: "84" },
	{ clave: "220", descripcion: "SOPA AZTECA", precio: "74", room: "84" },
	{ clave: "221", descripcion: "SOPA CEBOLLA", precio: "74", room: "84" },
	{ clave: "062", descripcion: "SOPA VERDURAS", precio: "74", room: "84" },
	{
		clave: "063",
		descripcion: "LIMONADA / NARANJADA",
		precio: "40",
		room: "50",
	},
	{ clave: "064", descripcion: "TE", precio: "32", room: "42" },
	{ clave: "065", descripcion: "REFRESCO", precio: "35", room: "45" },
	{ clave: "066", descripcion: "BUFFET DOMINICAL", precio: "189", room: "" },
	{ clave: "132", descripcion: "1/2 BUFFET DOMINICAL", precio: "95", room: "" },
	{ clave: "067", descripcion: "PAPAS A LA FRANCESA", precio: "69", room: "79" },
	{ clave: "068", descripcion: "BUFFET", precio: "175", room: "" },
	{ clave: "069", descripcion: "JUGO VERDE", precio: "50", room: "60" },
	{ clave: "169", descripcion: "MENU SEMANAL", precio: "195", room: "205" },
	{ clave: "071", descripcion: "SINCRONIZADAS", precio: "93", room: "103" },
	{ clave: "074", descripcion: "JUGO DE FRUTAS", precio: "38", room: "48" },
	{ clave: "075", descripcion: "LECHE", precio: "32", room: "42" },
	{ clave: "076", descripcion: "AGUA EMBOTELLADA", precio: "17", room: "27" },
	{ clave: "077", descripcion: "HUEVOS MOTULEÑOS", precio: "85", room: "95" },
	{
		clave: "078",
		descripcion: "ENCHILADAS SUIZAS / HUAST",
		precio: "88",
		room: "98",
	},
	{
		clave: "211",
		descripcion: "WAFFLES",
		precio: "69",
		room: "79",
	},
	{
		clave: "079",
		descripcion: "JARRA AGUA DE FRUTAS",
		precio: "87",
		room: "97",
	},
	{
		clave: "201",
		descripcion: "JARRA AGUA DE FRUTAS MINERAL",
		precio: "94",
		room: "104",
	},
	{
		clave: "080",
		descripcion: "1/2 JARRA DE AGUA FRUTAS",
		precio: "46",
		room: "56",
	},
	{ clave: "081", descripcion: "ARRACHERA ASADA", precio: "182", room: "192" },
	{ clave: "132", descripcion: "ARRACHERA 220g", precio: "220", room: "230" },
	{ clave: "133", descripcion: "T-BONE", precio: "210", room: "220" },
	{ clave: "134", descripcion: "RIBEYE", precio: "260", room: "270" },
	{
		clave: "082",
		descripcion: "TOSTADO FRANCES - CANELA",
		precio: "47",
		room: "57",
	},
	{ clave: "083", descripcion: "GUACAMOLE", precio: "64", room: "74" },
	{
		clave: "084",
		descripcion: "ENSALADA DE POLLO",
		precio: "80",
		room: "90",
	},
	{ clave: "085", descripcion: "PIEZA DE FRUTA", precio: "15", room: "25" },
	{
		clave: "086",
		descripcion: "ENSALADA DE ATUN / POLLO",
		precio: "86",
		room: "96",
	},
	{
		clave: "087",
		descripcion: "1/2 JARRA DE AGUA MINERAL",
		precio: "54",
		room: "64",
	},
	{
		clave: "088",
		descripcion: "ENTREMES QUESOS / CARNES",
		precio: "95",
		room: "105",
	},
	{ clave: "089", descripcion: "ENTREMES MEXICANO", precio: "85", room: "95" },
	{
		clave: "090",
		descripcion: "QUESO FUNDIDO CON CHAMP",
		precio: "90",
		room: "100",
	},
	{ clave: "091", descripcion: "QUESO FUNDIDO", precio: "65", room: "75" },
	{
		clave: "092",
		descripcion: "CAMPECHANA CON CAJETA",
		precio: "35",
		room: "45",
	},
	{ clave: "093", descripcion: "PLATO DE FRUTAS", precio: "63", room: "73" },
	{
		clave: "094",
		descripcion: "SABANA A LA PARRILLA",
		precio: "149",
		room: "159",
	},
	{
		clave: "095",
		descripcion: "CECINA C/ ENCHILADAS",
		precio: "169",
		room: "179",
	},
	{ clave: "096", descripcion: "TAMALES HUASTECOS", precio: "78", room: "88" },
	{ clave: "097", descripcion: "ORDEN DE GORDITAS", precio: "58", room: "68" },
	{ clave: "098", descripcion: "MACHACA CON HUEVO", precio: "95", room: "105" },
	{ clave: "099", descripcion: "CONSUMO", precio: "", room: "" },
	{ clave: "100", descripcion: "SERVICIO", precio: "", room: "" },
	{
		clave: "101",
		descripcion: "OMELETTE C/ CLARAS NAT",
		precio: "95",
		room: "105",
	},
	{
		clave: "102",
		descripcion: "ORD QUESADILLAS MAIZ",
		precio: "64",
		room: "74",
	},
	{
		clave: "103",
		descripcion: "ORD QUESADILLAS HARINA",
		precio: "69",
		room: "79",
	},
	{ clave: "104", descripcion: "FILETE MEZZANINE", precio: "152", room: "162" },
	{
		clave: "105",
		descripcion: "OMELETTE C/ CLARAS PREP.",
		precio: "99",
		room: "109",
	},
	{ clave: "106", descripcion: "TE", precio: "32", room: "42" },
	{ clave: "107", descripcion: "1/2 BUFFET", precio: "90", room: "" },
	{ clave: "108", descripcion: "1/2 JARRA CAFE", precio: "54", room: "64" },
	{ clave: "109", descripcion: "1/2 JARRA JUGO", precio: "70", room: "80" },
	{ clave: "110", descripcion: "CLARAS NATURALES", precio: "80", room: "90" },
	{
		clave: "111",
		descripcion: "CLARAS AL GUSTO PREP",
		precio: "95",
		room: "105",
	},
	{
		clave: "112",
		descripcion: "COPA DE VINO TINTO / BCO",
		precio: "69",
		room: "79",
	},
	{
		clave: "113",
		descripcion: "CALDO TLALPEÑO",
		precio: "74",
		room: "84",
	},
	{ clave: "114", descripcion: "FLAN DE LA CASA", precio: "60", room: "70" },
	{ clave: "125", descripcion: "JUGO VERDE", precio: "50", room: "60" },
	{
		clave: "126",
		descripcion: "ALITAS DE POLLO 6PZAS",
		precio: "135",
		room: "145",
	},
	{ clave: "127", descripcion: "ORDEN DE HOT DOG", precio: "69", room: "79" },
	{
		clave: "128",
		descripcion: "ENCHILADAS HIDROCALIDAS",
		precio: "88",
		room: "98",
	},
	{ clave: "129", descripcion: "DESCORCHE", precio: "180", room: "180" },
	{ clave: "115", descripcion: "DURAZNOS EN ALMIBAR", precio: "49", room: "59" },
	{
		clave: "116",
		descripcion: "CERVEZA CLARA CO/XXL/L",
		precio: "42",
		room: "52",
	},
	{
		clave: "117",
		descripcion: "CERVEZA NEGRA IND/VICTORIA",
		precio: "48",
		room: "58",
	},
	{ clave: "118", descripcion: "MICHELADA CLARA", precio: "54", room: "64" },
	{ clave: "119", descripcion: "MICHELADA OBSCURA", precio: "59", room: "69" },
	{
		clave: "120",
		descripcion: "GRINGA CERVEZA CLARA",
		precio: "64",
		room: "74",
	},
	{
		clave: "121",
		descripcion: "GRINGA CERVEZA OSCURA",
		precio: "70",
		room: "80",
	},
	{ clave: "122", descripcion: "COPA CLERICOT", precio: "110", room: "120" },
	{ clave: "123", descripcion: "CAFE EXPRESS", precio: "38", room: "48" },
	{ clave: "124", descripcion: "CAFE EXPRESS DOBLE", precio: "52", room: "62" },
	{ clave: "131", descripcion: "AVENA CON LECHE", precio: "53", room: "63" },
	{
		clave: "213",
		descripcion: "CAFE EXPRESS CORTADO",
		precio: "58",
		room: "68",
	},
	{
		clave: "202",
		descripcion: "REAL PLAZA C/FRUTA",
		precio: "145",
		room: "155",
	},
	{ clave: "214", descripcion: "JARRA DE CLERICOT", precio: "235", room: "245" },
	{
		clave: "215",
		descripcion: "1/2 JARRA DE CLERICOT",
		precio: "125",
		room: "135",
	},
	{
		clave: "216",
		descripcion: "JARRA JUGO DE NARANJA",
		precio: "130",
		room: "140",
	},
	{
		clave: "217",
		descripcion: "JARRA JUGO DE ZANAHORIA",
		precio: "140",
		room: "150",
	},
	{
		clave: "218",
		descripcion: "CAJA DE ENCH.CAJA GDE",
		precio: "385",
		room: "",
	},
	{ clave: "219", descripcion: "PZA. HUEVO CRUDO", precio: "20", room: "" },
	{ clave: "230", descripcion: "PZA HUEVO ESTRA", precio: "25", room: "" },
	{
		clave: "231",
		descripcion: "FLANERA YOGURTH/ COTTAGE",
		precio: "57",
		room: "67",
	},
	{
		clave: "232",
		descripcion: "FLANERA YOGURTH/ FRUTA",
		precio: "57",
		room: "67",
	},
	{ clave: "233", descripcion: "PZ GORDITAS", precio: "20", room: "" },
	{ clave: "234", descripcion: "SOPE SENCILLO", precio: "25", room: "" },
	{ clave: "237", descripcion: "SOPE SENCILLO C/GUISO", precio: "35", room: "" },
	{
		clave: "236",
		descripcion: "QUESADILLA SENC. O GUISO",
		precio: "35",
		room: "",
	},
	{ clave: "237", descripcion: "PZA. TAMAL BUFFET", precio: "18", room: "" },
	{ clave: "238", descripcion: "MENUDO", precio: "80", room: "" },
	{ clave: "501", descripcion: "PAGO", precio: "", room: "" },
	{ clave: "502", descripcion: "ABONO EFECTIVO", precio: "", room: "" },
	{ clave: "503", descripcion: "ABONO TARJETA CRED", precio: "", room: "" },
	{ clave: "504", descripcion: "ABONO TARJETA DEB", precio: "", room: "" },
	{ clave: "505", descripcion: "PROPINA", precio: "", room: "" },
	{
		clave: "193",
		descripcion: "PLATO DE FRUTAS C/ YOGHURT",
		precio: "69",
		room: "79",
	},
	{ clave: "193", descripcion: "CHAMPIÑONES AJILLO", precio: "85", room: "95" },
	{ clave: "505", descripcion: "PROPINA", precio: "", room: "" },
];
