const metodosdp = [
	{ value: "Efectivo", nombre: "1 Efectivo", claveSat: "01" },
	{
		value: "Cheque",
		nombre: "2 Cheque",
		claveSat: "02",
	},
	{ value: "Tarjeta de Debito", nombre: "3 Tarjeta de Debito", claveSat: "28" },
	{
		value: "Tarjeta de Credito",
		nombre: "4 Tarjeta de Credito",
		claveSat: "04",
	},
	{ value: "Transferencia", nombre: "5 Transferencia", claveSat: "03" },
	{
		value: "cuentas por cobrar",
		nombre: "6 cuentas por cobrar",
		claveSat: "99",
	},
	{
		value: "Reembolso",
		nombre: "7 Reembolso",
		claveSat: "noTimbrar",
	},
];
export default metodosdp;
