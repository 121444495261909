import React, { useContext, useState, useEffect } from "react";
import RegistroContext from "../../contexto/registro/registroContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
import Prompt from "../../assets/Prompt.js";

const Rack = () => {
	const registroContext = useContext(RegistroContext);
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const { habitaciones, traerHabitaciones, patchHabitacion } = registroContext;
	const [habset, setHabset] = useState([]);
	const [piso, setPiso] = useState(1);
	const [status, setStatus] = useState("");
	const [usr, setUsr] = useState("");
	const [prompt, setPrompt] = useState(false);

	///////////////////////////////////
	/// filtrado
	const filtrarHabs = (pisox) => {
		if (pisox === 1) return null;
		const filteredSet = habitaciones.filter(
			(r) => parseInt(r.piso) === parseInt(pisox)
		);
		return filteredSet;
	};
	////////////////////////////////////
	//o click
	const arrmg = (e) => {
		const clasesD = e.currentTarget.classList;
		!clasesD.contains("ocupada") && e.currentTarget.classList.toggle("selected");
	};
	////////////////////////////////////
	//control de slider
	const onChangePiso = (e) => {
		setPiso(e.target.value);
		let habsetr = filtrarHabs(e.target.value);
		setHabset(habsetr);
	};
	//////////////////////////////////
	// boton
	const statusChange = (e) => {
		setStatus(e.target.name);
		setPrompt(true);
		//dialogo.showModal();

		/*
		const usr = prompt(
			"Esta por cambiar el estatus de las habitaciones ingrese su clave"
		);
		if (usr === null) {
			setAlert("Operación cancelada", "danger");
			return;
		}
		const seleccionadas = document.querySelectorAll("article.selected > p");
		seleccionadas.forEach((ele) => {
			let obj = {
				habitacion: ele.id,
				status: e.target.name,
			};
			try {
				patchHabitacion(obj);
			} catch (err) {
				setAlert("Error: " + err.response, "danger");
			}
			ele.parentElement.classList.toggle("selected");
			ele.parentElement.classList.remove(
				"vaciaS",
				"vaciaL",
				"asignadaL",
				"asignadaS"
			);
			ele.parentElement.classList.add(e.target.name);
		});
		setAlert("Habitaciones Actualizadas", "success");
		*/
	};

	/////////////////////////////////
	// cambio de estatus
	const changeStatusPM = () => {
		const seleccionadas = document.querySelectorAll("article.selected > p");
		seleccionadas.forEach((ele) => {
			let obj = {
				habitacion: ele.id,
				status: status,
			};
			try {
				patchHabitacion(obj);
			} catch (err) {
				setAlert("Error: " + err.response, "danger");
			}
			ele.parentElement.classList.toggle("selected");
			ele.parentElement.classList.remove(
				"vaciaS",
				"vaciaL",
				"asignadaL",
				"asignadaS"
			);
			ele.parentElement.classList.add(status);
		});
		setAlert("Habitaciones Actualizadas1", "success");
		setUsr("");
	};
	////////////////////////////////////////////////////////
	//use effect
	useEffect(() => {
		if (Object.keys(habitaciones).length === 0) traerHabitaciones();
		const intervalo = setTimeout(() => {
			traerHabitaciones();
			return () => clearTimeout(intervalo);
		}, 2000);
	}, [habitaciones, piso, habset, traerHabitaciones]);
	return (
		<>
			<Prompt
				onValidKey={changeStatusPM}
				onClose={setPrompt}
				show={prompt}
				setKey={setUsr}
			/>
			<section className='grid-6'>
				<h1 className='gspan-2'>Rack de habitaciones</h1>
				<button
					onClick={statusChange}
					name='vaciaL'
					className='btn btn-dark'
					style={{ background: "#90ee90", border: "solid darkgrey 1px" }}
				>
					Disponibles
				</button>
				<button
					onClick={statusChange}
					name='vaciaS'
					className='btn btn-dark'
					style={{ background: "lightcoral", border: "solid darkgrey 1px" }}
				>
					Sucias
				</button>
				<button
					onClick={statusChange}
					name='asignadaL'
					className='btn btn-dark'
					style={{ background: "lightblue", border: "solid darkgrey 1px" }}
				>
					Asignadas
				</button>
				<button
					onClick={statusChange}
					name='asignadaS'
					className='btn btn-dark'
					style={{ background: "#00008b", border: "solid darkgrey 1px" }}
				>
					No disp
				</button>
			</section>
			<section>
				<input
					type='range'
					min='1'
					max='10'
					value={piso}
					onChange={onChangePiso}
					className='w-100'
				/>
			</section>
			<section className={`rack ${habset.length > 0 ? "grid-6" : "grid-12"}`}>
				{habset.length > 0
					? habset.map((row, i) => {
							return (
								<article
									key={i}
									onClick={arrmg}
									className={`${row.status} rackhab2 text-center`}
								>
									<p id={row.habitacion}>{row.habitacion}</p>
								</article>
							);
					  })
					: habitaciones.map((row, i) => {
							return (
								<article
									key={i}
									onClick={arrmg}
									className={`${row.status} rackhab text-center`}
								>
									<p id={row.habitacion}>{row.habitacion}</p>
								</article>
							);
					  })}
			</section>
		</>
	);
};

export default Rack;
