import React, { useState, useContext, useEffect } from "react";
import FacturacionContext from "../../contexto/facturacion/facturacionContext";
import regi from "../../assets/regimenes";
import AlertContext from "../../contexto/alerta/alertContext.js";

const Rsocial = (props) => {
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const tog = () => props.togg();
	///////////////////////////////
	//CONTEXT
	const {
		empresa,
		empresas,
		guardarDatos,
		traerEmpresas,
		actualizaDatos,
		seleccionaEmpresa,
		limpiarEmpresa,
		limpiarEmpresas,
	} = useContext(FacturacionContext);

	///////////////////////////////
	//STATE

	const [id, setId] = useState("");
	const [srfc, setSrfc] = useState("");
	const [srsocial, setSrsocial] = useState("");
	const [sdireccion, setSdireccion] = useState("");
	const [scp, setScp] = useState("");
	const [sregimen, setSregimen] = useState("");
	const [stel, setStel] = useState("");
	const [sciudad, setSciudad] = useState("");
	const [sestado, setSestado] = useState("");
	const [smail, setSmail] = useState("");

	//////////////////////////////////
	// onchanges
	const onChangeSrfc = (e) => setSrfc(e.target.value);
	const onChangeSrsocial = (e) => setSrsocial(e.target.value);
	const onChangeSdireccion = (e) => setSdireccion(e.target.value);
	const onChangeScp = (e) => setScp(e.target.value);
	const onChangeSregimen = (e) => setSregimen(e.target.value);
	const onChangeStel = (e) => setStel(e.target.value);
	const onChangeSciudad = (e) => setSciudad(e.target.value);
	const onChangeSestado = (e) => setSestado(e.target.value);
	const onChangeSmail = (e) => setSmail(e.target.value);

	//////////////////////////////////////////
	// poblar formulario
	const fillForm = () => {
		setId(empresa.id);
		setSrfc(empresa.rfc);
		setSrsocial(empresa.rsocial);
		setSdireccion(empresa.direccion);
		setScp(empresa.cp);
		setSregimen(empresa.regimen);
		setSciudad(empresa.ciudad);
		setSestado(empresa.estado);
		setStel(empresa.tel);
		setSmail(empresa.mail);
		setSrfc(empresa.rfc);
	};
	//////////////////////////////////////////
	// poblar formulario
	const emptyForm = () => {
		setId("");
		setSrfc("");
		setSrsocial("");
		setSdireccion("");
		setScp("");
		setSregimen("");
		setSciudad("");
		setSestado("");
		setStel("");
		setSmail("");
		setSrfc("");
	};

	////////////////////////////////////
	// GUARDA DATOS FISCALES
	const nuevosDatos = async (e) => {
		e.preventDefault();
		/*let usr = prompt("Ingrese su clave");
		if (usr === null) {
			return;
		}*/
		const datosFiscales = {
			direccion: sdireccion,
			cp: scp,
			ciudad: sciudad,
			estado: sestado,
			regimen: sregimen,
			mail: smail,
			rsocial: srsocial,
			rfc: srfc,
			tel: stel,
		};
		console.log(datosFiscales);
		const resp = await guardarDatos(datosFiscales);
		console.log(resp);
		if (resp.success) {
			tog();
			setAlert(resp.messages, "success");
			setId(empresa.id);
			setSrfc(empresa.rfc);
			setSrsocial(empresa.rsocial);
			setSdireccion(empresa.direccion);
			setScp(empresa.cp);
			setSregimen(empresa.regimen);
			setSciudad(empresa.ciudad);
			setSestado(empresa.estado);
			setStel(empresa.tel);
			setSmail(empresa.mail);
			setSrfc(empresa.rfc);
			limpiarEmpresa();
			limpiarEmpresas();
		} else {
			setAlert(resp.data.messages, "danger");
		}
	};

	/////////////////////////////////////
	//Actualiza Datos Fiscales
	const cambioDatos = async (e) => {
		e.preventDefault();
		let usr = prompt("Ingrese su clave");
		if (usr === null) {
			return;
		}
		const datosFiscales = {
			id: id,
			direccion: sdireccion,
			cp: scp,
			ciudad: sciudad,
			estado: sestado,
			regimen: sregimen,
			mail: smail,
			rsocial: srsocial,
			rfc: srfc,
			tel: stel,
		};
		const resp = await actualizaDatos(datosFiscales);
		if (resp.success) {
			setAlert("Registro actualizado con éxito", "success");
			const resp2 = await traerEmpresas();
			if (resp2.success) {
				setId(resp.data.Empresa.id);
				setSrfc(resp.data.Empresa.rfc);
				setSrsocial(resp.data.Empresa.rsocial);
				setSdireccion(resp.data.Empresa.direccion);
				setScp(resp.data.Empresa.cp);
				setSregimen(resp.data.Empresa.regimen);
				setSciudad(resp.data.Empresa.ciudad);
				setSestado(resp.data.Empresa.estado);
				setStel(resp.data.Empresa.tel);
				setSmail(resp.data.Empresa.mail);
				setSrfc(resp.data.Empresa.rfc);
			}
		} else {
			setAlert(resp.data.messages[0], "danger");
		}
	};

	useEffect(() => {
		if (Object.keys(empresas).length === 0) traerEmpresas();
		if (Object.keys(empresas).length !== 0) fillForm();
		if (Object.keys(empresa).length === 0) emptyForm();
	}, [empresas, empresa]);

	return (
		<>
			<form className=''>
				<div className=''>
					<div className='grid-2'>
						<div className='w-100'>
							<p className='medium bold'>RFC</p>
							<input onChange={onChangeSrfc} value={srfc} type='text' required />
						</div>
						<div>
							<p className='medium bold'>RAZON SOCIAL</p>
							<input
								onChange={onChangeSrsocial}
								value={srsocial}
								className='w-100'
								type='text'
								required
							/>
						</div>
					</div>
					<div className='grid-2'>
						<div>
							<p className='medium bold'>DIRECCION</p>
							<input
								onChange={onChangeSdireccion}
								value={sdireccion}
								className=''
								type='text'
							/>
						</div>
						<div className='grid-3'>
							<div>
								<p className='medium bold'>C. POSTAL</p>
								<input onChange={onChangeScp} value={scp} type='number' required />
							</div>
							<div>
								<p className='medium bold'>REGIMEN</p>
								<input
									onChange={onChangeSregimen}
									value={sregimen}
									list='regimen'
									type='text'
								/>
								<datalist id='regimen'>
									{regi.map((res) => {
										return (
											<option key={res.regimen} value={res.regimen}>
												{res.regimen}-{res.descripcion}
											</option>
										);
									})}
								</datalist>
							</div>
							<div>
								<p className='medium bold'>TELEFONO</p>
								<input onChange={onChangeStel} value={stel} type='number' />
							</div>
						</div>
					</div>
				</div>

				<div className='grid-4'>
					<div>
						<p className='medium bold'>CIUDAD</p>
						<input onChange={onChangeSciudad} value={sciudad} type='text' />
					</div>
					<div>
						<p className='medium bold'>ESTADO</p>
						<input onChange={onChangeSestado} value={sestado} type='text' />
					</div>
					<div>
						<p className='medium bold'>EMAIL</p>
						<input
							onChange={onChangeSmail}
							value={smail}
							placeholder='usuario@dominio.com'
							type='text'
						/>
					</div>
					<div className='grid-2'>
						<div style={{ position: "relative" }}>
							<button
								id='act'
								className='btn btn-dark noprint abottom w-100'
								onClick={cambioDatos}
							>
								Actualizar
							</button>
						</div>

						<div style={{ position: "relative" }}>
							<button
								id='gua'
								className='btn btn-dark noprint abottom w-100'
								onClick={nuevosDatos}
							>
								Guardar
							</button>
						</div>
					</div>
				</div>
			</form>
		</>
	);
};

export default Rsocial;
