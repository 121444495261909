import React, { useState } from "react";
import RepoCanth from "../registros/RepoCanth.js";
import BusqCan from "../registros/BusqCan.js";
const Cancelaciones = () => {
	return (
		<div>
			<div className='grid-2'>
				<div>
					<h1>Cancelaciones</h1>
				</div>
				<div className='text-right'>
					<BusqCan inter={false} />
				</div>
			</div>
			<RepoCanth />
		</div>
	);
};

export default Cancelaciones;
