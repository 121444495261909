import React from "react";
import ReactDom from "react-dom";
const Modal = ({ open, children, facModCerrar }) => {
	if (!open) return null;
	return ReactDom.createPortal(
		<div className='modalOver'>
			<div className='modal'>
				<button className='btn btn-dark floatRight' onClick={facModCerrar}>
					Cerrar
				</button>
				{children}
			</div>
		</div>,
		document.getElementById("portal")
	);
};

export default Modal;
