import React, { useContext, useState } from "react";
import ReservaContext from "../../contexto/reservacion/reservaContext.js";
import Reservastd from "./Reservastd.js";
import Spinner from "../elementos/Spinner.js";
import Prompt from "../../assets/Prompt.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
const Reservasth = () => {
	const reservaContext = useContext(ReservaContext);
	const alertContext = useContext(AlertContext);
	const { reservaciones, borrarReserva, limpiarReservas, resback } =
		reservaContext;
	const { setAlert } = alertContext;
	const reservasLength = reservaciones.length;
	const [rid, setRid] = useState(0);
	const [usr, setUsr] = useState("");

	////////////////////////////////////
	const [prompt2, setPrompt] = useState(false);
	///////////////////////////
	//
	const delOnClick = async (usr) => {
		let body = {};
		let canord = prompt(
			"Ingrese el nombre de la persona que solicitó la cancelación"
		);
		body.usr = usr;
		body.canord = canord;
		const resp = borrarReserva(rid, body);
		console.log(resp);

		resp
			.then((r) => {
				setAlert("Reserva Eliminada con Exito", "success");
				setUsr("");
				setRid("");
				limpiarReservas();
			})
			.catch((e) => {
				setAlert("Error al eliminar la reservación", "danger");
			});
	};

	if (reservasLength === 0) {
		return <Spinner />;
	} else {
		return (
			<div className=''>
				<table className='my-1 w-100 tfix'>
					<thead>
						<tr className='w-100'>
							<th style={{ width: "7%" }}>Folio</th>
							<th style={{ width: "18%" }}>Nombre</th>
							<th>llegada</th>
							<th>Salida</th>
							<th style={{ width: "15%" }}>Empresa</th>
							<th>Grupo</th>
							<th style={{ width: "20%" }}>Info</th>
							<th style={{ width: "5%" }}>status</th>
						</tr>
					</thead>
					<tbody>
						{console.log(reservaciones)}
						{typeof reservaciones !== "undefined"
							? reservaciones.map((res) => (
									<Reservastd
										key={res.folio}
										reserva={res}
										setRid={setRid}
										setPrompt={setPrompt}
									/>
							  ))
							: typeof resback !== "undefined"
							? resback.map((res) => (
									<Reservastd
										key={res.folio}
										reserva={res}
										setRid={setRid}
										setPrompt={setPrompt}
									/>
							  ))
							: null}
					</tbody>
				</table>
				<Prompt
					onClose={setPrompt}
					show={prompt2}
					setKey={setUsr}
					onValidKey={delOnClick}
				/>
			</div>
		);
	}
};

export default Reservasth;
