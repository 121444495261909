import React, { useContext } from "react";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import Conceptos from "../../assets/conceptos";

const Facturaconceptostd = ({
	cargo: {
		abono,
		cajero,
		comentario,
		concepto,
		cuenta_id,
		fecha,
		habitacion,
		id,
		ieh,
		importe,
		iva,
		metodo_pago,
		referencia,
	},
}) => {
	let clave = Conceptos.map((obj) => {
		if (obj.value === concepto) {
			return obj.claveSat;
		}
	});
	let unidad = Conceptos.map((obj) => {
		if (obj.value === concepto) {
			return obj.unidad;
		}
	});
	////////////////////////////////////////
	// CONTEXT
	////////////////////////////////////////
	const cuentaContext = useContext(CuentaContext);
	const { traerCuenta } = cuentaContext;

	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};
	const horaTs = new Date(fecha);
	const fechaf = fecha.split(" ");
	const salidaF = fechaf[0].split("-");
	const hora = horaTs.toLocaleTimeString().replace(/(.*)\D\d+/, "$1");
	const fsalida = salidaF[2] + "/" + salidaF[1];
	const fullimporte = parseFloat(importe) + parseFloat(iva) + parseFloat(ieh);
	const oCtraerCuenta = async (e) => {
		e.preventDefault();
		const resp = await traerCuenta(id);
		if (resp.success) {
		}
	};

	return (
		<tr onClick={oCtraerCuenta} className='clickable'>
			<td className='op'>{fsalida}</td>
			<td className='op'>{hora}</td>
			<td className='op'>{clave}</td>
			<td className='op'>{unidad}</td>
			<td className='op'>{referencia}</td>
			<td className='op'>{concepto}</td>
			<td className='op'>{moneyForm(fullimporte)}</td>
			<td className='op'>{moneyForm(abono)}</td>
		</tr>
	);
};
export default Facturaconceptostd;
