export const conceptos = [
	{
		value: "habitacion",
		nombre: "1 Habitación",
		claveSat: "90111800",
		unidad: "E48",
		clave: "01",
	},
	{
		value: "Consumo",
		nombre: "2 Cafetería",
		claveSat: "90101501",
		unidad: "E48",
		clave: "02",
	},
	{
		value: "bar",
		nombre: "3 Bar",
		claveSat: "90101501",
		unidad: "E48",
		clave: "03",
	},
	{
		value: "lavanderia",
		nombre: "4 Lavandería",
		claveSat: "91111502",
		unidad: "E48",
		clave: "04",
	},
	{
		value: "CentroNegocios",
		nombre: "5 centro de negocios",
		claveSat: "90111601",
		unidad: "E48",
		clave: "05",
	},
	{
		value: "propina",
		nombre: "6 Propina",
		claveSat: "90111601",
		unidad: "E48",
		clave: "90111601",
	},
	{ value: "abono", nombre: "7 Abono" },
];
export default conceptos;
