const regimen = [
	{ regimen: "601", descripcion: "General de Ley Personas Morales" },
	{ regimen: "603", descripcion: "Personas Morales con Fines no Lucrativos" },
	{
		regimen: "605",
		descripcion: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
	},
	{ regimen: "606", descripcion: "Arrendamiento" },
	{
		regimen: "607",
		descripcion: "Régimen de Enajenación o Adquisición de Bienes",
	},
	{ regimen: "608", descripcion: "Demás Ingresos" },
	{
		regimen: "610",
		descripcion:
			"Residentes en el Extranjero sin Establecimiento Permanente en México",
	},
	{
		regimen: "611",
		descripcion: "Ingresos por Dividendos (socios y accionistas)",
	},
	{
		regimen: "612",
		descripcion:
			"Personas Físicas con Actividades Empresariales y ProfesionalesPersonas Físicas con Actividades Empresariales y Profesionales",
	},
	{ regimen: "614", descripcion: "Ingresos por intereses" },
	{
		regimen: "615",
		descripcion: "Régimen de los ingresos por obtención de premios",
	},
	{ regimen: "616", descripcion: "Sin obligaciones fiscales" },
	{
		regimen: "620",
		descripcion:
			"Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
	},
	{ regimen: "621", descripcion: "Incorporación Fiscal" },
	{
		regimen: "622",
		descripcion: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
	},
	{ regimen: "623", descripcion: "Opcional para Grupos de Sociedades" },
	{ regimen: "624", descripcion: "Coordinados" },
	{
		regimen: "625",
		descripcion:
			"Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
	},
	{ regimen: "626", descripcion: "Régimen Simplificado de Confianza" },
];

export default regimen;
