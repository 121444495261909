import React, { useContext, useState } from "react";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import logo from "../../assets/logo.png";
import regi from "../../assets/regimenes";
import base from "../../assets/pagobase.json";

const Complemento = () => {
	////////////////////////////
	//contextos
	const facturacionContext = useContext(FacturacionContext);
	const { factura, traerFacturaByFolio, timbrarComplemento } =
		facturacionContext;
	let totalfactura = 0;
	totalfactura = factura.total ?? factura.total;
	/////////////////////////////////////////
	//state
	const [serie, setSerie] = useState("A");
	const [folio, setFolio] = useState(0);
	const [saldo, setSaldo] = useState(totalfactura);
	const [monto, setMonto] = useState(0);
	const [fopago, setFopago] = useState(0);
	const [fepago, setFepago] = useState("");
	const [parcialidad, setParcialidad] = useState(1);
	const [susti, setSusti] = useState(false);
	const [folioasustituir, setFolioasustituir] = useState("");
	const [nregimen, setNregimen] = useState(601);
	let regimen = factura.regimen !== "" ? factura.regimen : nregimen;

	//////////////////////////////////////////
	// datos
	let tz = new Date(Date.now()).getTimezoneOffset() * 60000;
	const hoy = new Date(Date.now() - tz).toISOString().slice(0, 19);
	const fechadPago = fepago + "T00:00:00";

	/////////////////////////////////////////
	//onchanges
	const serieOnChange = (e) => setSerie(e.target.value);
	const folioOnChange = (e) => setFolio(e.target.value);
	const onChangeParcial = (e) => setParcialidad(e.target.value);
	const onChangeFecha = (e) => setFepago(e.target.value);
	const onChangeSaldo = (e) => setSaldo(e.target.value);
	const onChangeMonto = (e) => setMonto(e.target.value);
	const onChangefopago = (e) => setFopago(e.target.value);
	const sonChangeFolioasustituir = (e) => setFolioasustituir(e.target.value);
	const onChangeNregimen = (e) => setNregimen(e.target.value);
	const onChangeSusti = (e) => {
		if (e.target.checked) {
			setSusti(true);
		} else {
			setSusti(false);
		}
	};

	////////////////////////////////
	// formulario de busqueda
	const buscarFactura = async (e) => {
		e.preventDefault();
		const resp = await traerFacturaByFolio(serie, folio);
		console.log(resp);
	};

	/////////////////////////////////
	//Funcion de timbrado
	const sellarPago = async (e) => {
		e.preventDefault();
		let usr = await prompt(
			"Está por timbrar el complemento, ingrese su contraseña"
		);
		if (usr === null) {
			return;
		}
		////poblar base
		base.Comprobante.Fecha = hoy;
		base.Comprobante.Receptor.Rfc = factura.rfc;
		base.Comprobante.Receptor.Nombre = factura.rsocial;
		base.Comprobante.Receptor.DomicilioFiscalReceptor = factura.cp;
		base.Comprobante.Receptor.RegimenFiscalReceptor = regimen;
		base.Comprobante.Complemento[0].Pagos20.Totales.MontoTotalPagos = monto;
		base.Comprobante.Complemento[0].Pagos20.Pago[0].FechaPago = fechadPago;
		base.Comprobante.Complemento[0].Pagos20.Pago[0].FormaDePagoP = fopago;
		base.Comprobante.Complemento[0].Pagos20.Pago[0].Monto = monto;
		base.Comprobante.Complemento[0].Pagos20.Pago[0].DoctoRelacionado[0].IdDocumento =
			factura.folio_fiscal;
		base.Comprobante.Complemento[0].Pagos20.Pago[0].DoctoRelacionado[0].ImpSaldoAnt =
			saldo;
		base.Comprobante.Complemento[0].Pagos20.Pago[0].DoctoRelacionado[0].ImpPagado =
			monto;
		base.Comprobante.Complemento[0].Pagos20.Pago[0].DoctoRelacionado[0].ImpSaldoInsoluto =
			saldo - monto;
		let pdf = {
			nombre: factura.rsocial,
			direccion: factura.direccion,
			rsocial: factura.rsocial,
			cp: factura.cp,
			ciudad: factura.ciudad,
			estadoPais: factura.estado ?? factura.estado + ", México.",
			fechaFactura: hoy,
			fechaPago: fepago,
			formaPago: fopago,
			rfc: factura.rfc,
			parcialidad: parcialidad,
			facturaap: factura.serie + factura.folio,
			pago: monto,
			saldo: saldo,
			cfdire: factura.folio_fiscal,
			saldoAnt: parseFloat(saldo),
			pago: parseFloat(monto),
			saldo: saldo - monto,
		};
		let body = {
			cuenta_id: factura.c_id,
			usr: "sad",
			body: base,
			pdf: pdf,
		};
		console.log(body);

		const resp = await timbrarComplemento(body);
		console.log(resp);
	};
	///////////////////////////////
	//money
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};

	return (
		<>
			<div id='buscaFolio' style={{ marginBottom: "15px" }}>
				<form className='grid-6' onSubmit={buscarFactura}>
					<h1 className='gspan-3'>Complemento de pago</h1>
					<article>
						<label htmlFor='serie'>Serie</label>
						<select onChange={serieOnChange}>
							<option value='A'>A</option>
							<option value='B'>B</option>
							<option value='C'>C</option>
							<option value='D'>D</option>
						</select>
					</article>
					<article>
						<label htmlFor='folio'>Folio</label>
						<input type='number' value={folio} min='0' onChange={folioOnChange} />
					</article>
					<button className='btn btn-dark'>Buscar</button>
				</form>
			</div>
			{Object.entries(factura).length !== 0 && (
				<>
					<div className='grid-2 w-100'>
						<div>
							<h3>Pago a Factura Folio: {factura.serie + factura.folio}</h3>
							<h3>Folio Fiscal: {factura.folio_fiscal}</h3>
						</div>
						<h3>Monto de la factura: ${factura.total}</h3>
					</div>
					<form
						onSubmit={sellarPago}
						id='complementodata'
						className='flexlinea'
						style={{
							padding: "0.5rem",
							border: "1px #ccc solid",
							borderRadius: "5px",
						}}
					>
						<div>
							<label htmlFor='parcialidad'>Parcialidad</label>
							<select
								name='parcialidad'
								value={parcialidad}
								onChange={onChangeParcial}
							>
								<option value='1'>1</option>
								<option value='2'>2</option>
								<option value='3'>3</option>
								<option value='4'>4</option>
								<option value='5'>5</option>
							</select>
						</div>
						<div>
							<label htmlFor='fpago'>Fecha de Pago</label>
							<input type='date' value={fepago} onChange={onChangeFecha} required />
						</div>
						<div>
							<label htmlFor='monto'>Saldo</label>
							<input
								type='number'
								value={saldo}
								min='1'
								onChange={onChangeSaldo}
								required
							/>
						</div>
						<div>
							<label htmlFor='monto'>Monto</label>
							<input
								type='number'
								value={monto}
								min='1'
								onChange={onChangeMonto}
								required
							/>
						</div>
						<div>
							<label htmlFor='fopago'>Forma da Pago</label>
							<select name='fopago' value={fopago} onChange={onChangefopago}>
								<option value='01'>01 Efectivo</option>
								<option value='02'>02 Cheque</option>
								<option value='03'>03 Transferencia</option>
							</select>
						</div>

						<div>
							<button
								type='submit'
								className='btn btn-dark'
								style={{ marginBottom: "3px" }}
							>
								Generar
							</button>
						</div>
						{factura.regimen === "" && (
							<div>
								<label htmlFor='fopago'>regimen Fiscal</label>
								<select value={nregimen} onChange={onChangeNregimen}>
									{regi.map((res) => {
										return (
											<option key={res.regimen} value={res.regimen}>
												{res.regimen}-{res.descripcion}
											</option>
										);
									})}
								</select>
							</div>
						)}
						<div className='breakflex'></div>
						<label htmlFor='susti'>
							<input
								type='checkbox'
								name='susti'
								value={susti}
								onChange={onChangeSusti}
							/>{" "}
							Sustituir CFDI
						</label>
						{susti && (
							<input
								type='text'
								placeholder='Ingrese el folio fiscal del CFDI a sustituir'
								value={folioasustituir}
								onChange={sonChangeFolioasustituir}
							/>
						)}
					</form>
					<section className='flexilinea'></section>
					<section id='vista' className='my-1'>
						<div>
							<header className='grid-3 p'>
								<div className='gspan-2 grid-2'>
									<h1 className='gspan-2'>Real Plaza Aguascalientes S.A. De C.V.</h1>
									<div>
										<p className='small my'>Adolfo López Mateos 320 Ote.</p>
										<p className='small my'>C.P. 20000 Zona Centro.</p>
										<p className='small my'>Tel. (449) 968-27-27</p>
										<p className='small my'>Aguascalientes Ags. México.</p>
									</div>
									<div>
										<p className='small my'>www.realplazaags.com</p>
										<p className='small my'>RFC RPA210618HE5</p>
									</div>
								</div>
								<div style={{ width: "65%" }}>
									{" "}
									<img src={logo} alt='Logo nav' />
								</div>
								<div className='gspan-3 all-center-row w-100'>
									<p>Expedido en Aguascalientes Ags.</p>
									<p className=''>601 Régimen General de Ley Personas Morales</p>
									<p className=''>Estado de cuenta</p>
								</div>
							</header>
							<section className='datos grid-2 grad p'>
								<article className='receptor'>
									<p>
										Razón Social: <strong>{factura.rsocial}</strong>
									</p>
									<p>
										Direccion: <strong>{factura.direccion}</strong>
									</p>
									<p>
										Código postal: <strong>{factura.cp}</strong>
									</p>
									<p>
										Estado país:{" "}
										<strong>{factura.ciudad + " " + factura.estado + " MÉXICO"}</strong>
									</p>
								</article>
								<article className='fechas'>
									<p>
										RFC: <strong>{factura.rfc}</strong>
									</p>
									<p>
										Régimen: <strong>{nregimen}</strong>
									</p>
									<p>
										Fecha de pago: <strong>{fepago}</strong>
									</p>
									<p>
										Método de Pago:{" "}
										<strong>{factura.forma_pago !== "CXC" ? "PUE" : "PPD"}</strong>
									</p>
								</article>
							</section>
							<section className=''>
								<table className='w-100'>
									<thead>
										<tr>
											<th>No. Pacialidad</th>
											<th>Factura</th>
											<th>CFDI Relacionado</th>
											<th>Saldo Anterior</th>
											<th>PAGO</th>
											<th>SALDO</th>
										</tr>
									</thead>
									<tbody className='grad'>
										<tr>
											<td>{parcialidad}</td>
											<td>{factura.serie + factura.folio}</td>
											<td>{factura.folio_fiscal}</td>
											<td>{moneyForm(saldo)}</td>
											<td>{moneyForm(monto)}</td>
											<td>{moneyForm(saldo - monto)}</td>
										</tr>
									</tbody>
								</table>
							</section>

							<div className='w-100'>
								<p className='small'>Comentario: {factura.comentario}</p>
								<p className='small'>Uso de CFDI: CP01 Pagos</p>
							</div>
						</div>
					</section>
				</>
			)}
		</>
	);
};

export default Complemento;
