import React, { useContext } from "react";
import tarifas from "../../assets/tarifas.js";
const Rentastd = ({
	renta: {
		habitacion,
		tarifa,
		cuenta,
		nombre,
		rsocial,
		pago,
		saldo,
		reg,
		psgo,
		ref,
		cargo,
		llegada,
		salida,
		info,
	},
}) => {
	let importe = "";
	tarifas.map((row) => {
		if (tarifa === row.Nombre) {
			importe = row.Importe;
		}
	});

	////////////////////////////////
	//formato de numero
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return 0;
		}
	};
	return (
		<tr>
			<td>{habitacion}</td>
			<td>{tarifa}</td>
			<td>{moneyForm(importe)}</td>
			<td>{moneyForm(cargo)}</td>
			<td>{nombre}</td>
			<td>{rsocial}</td>
			<td>{llegada}</td>
			<td>{salida}</td>
			<td>{pago}</td>
			<td>{moneyForm(saldo)}</td>
			<td>{reg}</td>
			<td>{ref}</td>
			<td>{info}</td>
		</tr>
	);
};

export default Rentastd;
