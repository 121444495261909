import React, { useContext, useEffect, useState } from "react";
import CargoContext from "../../contexto/cargo/cargoContext";
import Cargostdlight from "./Cargostdlight";
import Spinner from "../elementos/Spinner";
import AlertContext from "../../contexto/alerta/alertContext";

const Cargosthlight = ({ fecha }) => {
	///////////////////////////////////////////////
	// CONTEXT
	const cargoContext = useContext(CargoContext);
	const { traerCargos, cargos, limpiar, cargo } = cargoContext;
	let totalCargos = 0;
	let totalAbonos = 0;
	let dommie = [
		{
			id: 1,
			cuenta_id: 3,
			importe: "0",
			iva: "0",
			ieh: "0",
			abono: "0.0000",
			metodo_pago: "",
			referencia: "",
			concepto: "Sin cargos",
			habitacion: 0,
			comentario: "",
			fecha: "2022-01-01 00:00:00",
			cajero: "Art",
		},
	];
	const meses = [
		"dud",
		"Enero",
		"Feb",
		"Mar",
		"Abril",
		"Mayo",
		"Jun",
		"Jul",
		"Ago",
		"Sep",
		"Oct",
		"Nov",
		"Dic",
	];
	//fsalida =	salidaF[0] + "/" + meses[salidaF[1].replace(/^0+/, "")] + "/" + salidaF[2];

	///////////////////////////////
	//formato de numero
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};

	useEffect(() => {
		if (Object.keys(cargos).length === 0) {
		}
	}, [cargos]);
	return (
		<>
			<h3 className='my-1'>Listado de Transacciones {fecha}</h3>
			<table className='w-100 pCarta'>
				<thead>
					<tr>
						<th>Hab.</th>
						<th>Fecha</th>
						<th>Hora</th>
						<th>Concepto</th>
						<th>Metodo de Pago</th>
						<th>Importe</th>
						<th>Abono</th>
						<th>Referencia</th>
						<th>Comentario</th>
						<th>Cajero</th>
					</tr>
				</thead>
				<tbody>
					{cargos.length > 0
						? cargos.map((res) => <Cargostdlight key={res.id} cargo={res} />)
						: dommie.map((res) => <Cargostdlight key={res.id} cargo={res} />)}
				</tbody>
				<tfoot>
					<tr>
						<td colSpan='6' />
						<th scope='row'>Cargos</th>
						<td>{moneyForm(totalCargos)}</td>
						<th scope='row'>Abonos</th>
						<td>{moneyForm(totalAbonos)}</td>
					</tr>
				</tfoot>
			</table>
		</>
	);
};

export default Cargosthlight;
