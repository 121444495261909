import { CUENTAS, CUENTA, TURNO, SALIDAS, FOLIOS, ACTIVAS } from "../types.js";
const cuentaReducer = (state, action) => {
	switch (action.type) {
		case CUENTAS:
			return {
				...state,
				cuentas: action.payload,
				loading: false,
			};
		case SALIDAS:
			return {
				...state,
				salidas: action.payload,
				loading: false,
			};
		case ACTIVAS:
			return {
				...state,
				activas: action.payload,
				loading: false,
			};
		case FOLIOS:
			return {
				...state,
				folios: action.payload,
				loading: false,
			};
		case CUENTA:
			return {
				...state,
				cuenta: action.payload,
				loading: false,
			};
		case TURNO:
			return {
				...state,
				turno: action.payload,
				loading: false,
			};
		default:
			return state;
	}
};
export default cuentaReducer;
