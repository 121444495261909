import React, { useState } from "react";
import Descfacth from "../facturas/Descfacth.js";
import Repofactmesth from "../facturas/Repofactmesth.js";
import Busqfacxmes from "../facturas/Busqfacxmes.js";
const Descargafacturas = () => {
	const [tab, setTab] = useState("descarga");
	const desc = (e) => {
		e.preventDefault();
		setTab("descarga");
	};
	const rep = (e) => {
		e.preventDefault();
		setTab("reporte");
	};

	return (
		<div>
			<div className='grid-2'>
				<div>
					<h1>Facturas</h1>
					<div className='tabz w-100'>
						<button className='btn  btn-dark' onClick={desc}>
							Descargas
						</button>
						<button className='btn  btn-dark' onClick={rep}>
							Reporte Mensual
						</button>
					</div>
				</div>
				<div className='text-right'>
					<Busqfacxmes inter={false} />
				</div>
			</div>
			{tab === "descarga" ? (
				<Descfacth />
			) : tab === "reporte" ? (
				<Repofactmesth />
			) : (
				<h3>Seleccione una pestaña</h3>
			)}
		</div>
	);
};

export default Descargafacturas;
