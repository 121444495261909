export const RESERVACIONES = "RESERVACIONES";
export const RESERVACION = "RESERVACION";
export const LOADING = "LOADING";
export const MODAL = "MODAL";
export const CLIENTE = "CLIENTE";
export const EMPRESA = "EMPRESA";
export const TARIFAS = "TARIFAS";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const CUENTAS = "CUENTAS";
export const CUENTA = "CUENTA";
export const REGISTROS = "REGISTROS";
export const REGISTRO = "REGISTRO";
export const CARGOS = "CARGOS";
export const CARGO = "CARGO";
export const CARGOSID = "CARGOSID";
export const TEST = "TEST";
export const LIMPIAR = "LIMPIAR";
export const CHEQUE = "CHEQUE";
export const CHEQUES = "CHEQUES";
export const EMPRESAS = "EMPRESAS";
export const FOLIO = "FOLIO";
export const TURNO = "TURNO";
export const GUARDAR = "GUARDAR";
export const FACTURA = "FACTURA";
export const FACTURAS = "FACTURAS";
export const ABONOS = "ABONOS";
export const HABITACIONES = "HABITACIONES";
export const HABITACION = "HABITACION";
export const SALIDAS = "SALIDAS";
export const RENTAS = "RENTAS";
export const RENTASA = "RENTASA";
export const SET_LOADING = "SET_LOADING";
export const UNSET_LOADING = "UNSET_LOADING";
export const USUARIOS = "USUARIOS";
export const USUARIO = "USUARIO";
export const CLEARU = "CLEARU";
export const LOG_FEEDBACK = "LOG_FEEDBACK";
export const LIMPIAR_LOG_FEEDBACK = "LIMPIAR_LOG_FEEDBACK";
export const CANCELACIONES = "CANCELACIONES";
export const FOLIOS = "FOLIOS";
export const ACTIVAS = "ACTIVAS";
export const RESBACK = "RESBACK";
