import React, { useContext, useState, useEffect } from "react";
import ReservaContext from "../../contexto/reservacion/reservaContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import Rfcpicker from "../facturacion/Rfcpicker.js";
import Rsocial2 from "../facturacion/rsocial2.js";
import Prompt from "../../assets/Prompt.js";
import tarifass from "../../assets/tarifas";
import estados from "../../assets/estados";

const NuevaReserva = (props) => {
	const hoyrw = new Date();
	const hoyr = new Date(hoyrw.getTime() - hoyrw.getTimezoneOffset() * 60000);
	const hoy = hoyr.toISOString().split("T")[0];

	const mananarw = new Date();
	const mananar = new Date(mananarw.setDate(mananarw.getDate() + 1));
	const manana = mananar.toISOString().split("T")[0];

	////////////////////
	//contexto
	const reservaContext = useContext(ReservaContext);
	const {
		nuevaReserva,
		nuevoCliente,
		traerTarifas,
		tarifas,
		traerReservasRec,
		limpiarReservas,
	} = reservaContext;
	const facturacionContext = useContext(FacturacionContext);
	const { empresa } = facturacionContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	///////////////////
	//state
	const [nombre, setNombre] = useState("");
	const [apellido, setApellido] = useState("");
	const [grupo, setGrupo] = useState("");
	const [llegada, setLlegada] = useState(hoy);
	const [salida, setSalida] = useState(manana);
	const [personas, setPersonas] = useState(1);
	const [habitaciones, setHabitaciones] = useState(1);
	const [info, setInfo] = useState("");
	const [ref, setRef] = useState("");
	const [factura, setFactura] = useState(false); // tooggle clientes
	const [nuevaEmpresa, setNuevaEmpresa] = useState(false); // tooggle clientes
	const [cliente_id, setCliente_id] = useState(null);
	const [empresaCheck, setEmpresaCheck] = useState(null);
	const [usr, setUsr] = useState("");
	const [mail, setMail] = useState(null);
	const [tel, setTel] = useState(null);
	const [prompt, setPrompt] = useState(false);
	const [tarifa, setTarifa] = useState([]);
	const [ciudad, setCiudad] = useState("");
	const [estado, setEstado] = useState("");
	const [mpago, setMpago] = useState("Efectivo");

	///////////////////
	//onChange inputs
	const onChangeNombre = (e) => setNombre(e.target.value);
	const onChangeApellido = (e) => setApellido(e.target.value);
	const onChangeGrupo = (e) => setGrupo(e.target.value);
	const onChangeLlegada = (e) => setLlegada(e.target.value);
	const onChangeSalida = (e) => setSalida(e.target.value);
	const onChangePersonas = (e) => setPersonas(e.target.value);
	const onChangeHabitaciones = (e) => setHabitaciones(e.target.value);
	const onChangeInfo = (e) => setInfo(e.target.value);
	const onChangeRef = (e) => setRef(e.target.value);
	const onChangeFactura = (e) =>
		e.target.checked ? setFactura(true) : setFactura(false); // toogle clientes
	const onChangeNuevaEmpresa = (e) =>
		e.target.checked ? setNuevaEmpresa(true) : setNuevaEmpresa(false); // toogle clientes
	const onChangeMail = (e) => setMail(e.target.value);
	const onChangeTel = (e) => setTel(e.target.value);
	const onChangeCiudad = (e) => setCiudad(e.target.value);
	const onChangeEstado = (e) => setEstado(e.target.value);
	const onChangeMpago = (e) => setMpago(e.target.value);
	const onChangeTarifa = (e) => {
		if (e.target.value === "0") {
			return;
		}
		let row = {
			habnum: habitaciones,
			tarifa: e.target.value,
		};
		setTarifa((tarifa) => [...tarifa, row]);
	};

	///////////////////////
	// metodo para crear el body del cliente
	const getClienteData = () => {
		const clienteObj = {};
		clienteObj.nombre = nombre;
		clienteObj.apellido = apellido;
		grupo.length === 0 ? (clienteObj.grupo = null) : (clienteObj.grupo = grupo);
		//clienteObj.direccion = direccion;
		clienteObj.ciudad = ciudad;
		clienteObj.estado = estado;
		clienteObj.mail = mail;
		tel !== null && (clienteObj.tel = tel);
		return clienteObj;
	};

	///////////////////////
	// metodo para crear el body de la reserva
	const getReservaData = () => {
		let tarr = "";
		let tarif = "";
		let totalhabs = 0;
		if (tarifa.length === 0) {
			setAlert("No ingresó ninguna tarífa", "danger");
			return;
		}
		tarifa.map((r, i) => {
			for (let index = 0; index < r.habnum; index++) {
				tarr = tarr + r.tarifa + " ";
			}
			tarif = tarif + r.habnum + "x " + r.tarifa + " ";
			totalhabs = +totalhabs + +r.habnum;
		});
		const reservaObj = {};
		reservaObj.llegada = llegada;
		reservaObj.salida = salida;
		reservaObj.personas = personas;
		reservaObj.habitaciones = totalhabs;
		reservaObj.tarifas = tarr;
		reservaObj.grupo = grupo;
		reservaObj.mpago = mpago;
		info.length > 0
			? (reservaObj.info = totalhabs + " habitaciones: " + tarif + "\n" + info)
			: (reservaObj.info = totalhabs + " habitaciones: " + tarif);
		ref.length > 0 && (reservaObj.ref = ref);
		reservaObj.cliente_id = cliente_id;
		reservaObj.empresa_id = empresa.id;
		//reservaObj.ciudad = ciudad;
		//reservaObj.estado = estado;
		reservaObj.reservo = null;
		return reservaObj;
	};

	////////////////////
	// killtar
	const killtar = (indice) => {
		setTarifa(tarifa.filter((row, i) => i !== indice));
	};

	///////////////////
	// métodos
	const submitReserva = async (usr) => {
		let clienteObj = getClienteData();
		let reservaObj = getReservaData();
		reservaObj.reservo = usr;
		// dispara nuevo cliente
		const cResp = nuevoCliente(clienteObj);
		cResp
			.then((r) => {
				console.log(r);
				setCliente_id(r.data.id);
				reservaObj.cliente_id = r.data.id;
				//si requiere factura factura será true
				if (empresaCheck) {
					//disparamos la creacion de nueva empresa y si jala capturamos en el id
					reservaObj.empresa_id = empresa.id;
					let rResp = nuevaReserva(reservaObj);
					rResp
						.then((r) => {
							setAlert(
								`Reservación creada con exito para ${
									nombre + " " + apellido + " con folio: " + r.data.id
								}`,
								"success"
							);
						})
						.catch((r) => {
							setAlert(`Falló la reserva :vida`, "danger");
						});
				} else {
					let rxResp = nuevaReserva(reservaObj);
					rxResp
						.then((r) => {
							console.log(r);

							setAlert(
								`Reservación creada con exito para ${
									nombre + " " + apellido + " con folio: " + r.data.id
								}`,
								"success"
							);
							//props.restogg(false);
						})
						.catch((e) => {
							console.log(e.response);
							setAlert(`Falló la reserva :` + e.statusCode, "danger");
						});
				}
				limpiarReservas();
				traerReservasRec();
				setUsr("");
			})
			.catch((r) => {
				setAlert("r.messages", "danger");
			});
	};
	const trigPrompt = (e) => {
		e.preventDefault();
		setPrompt(true);
	};
	//////////////////
	//useEffect
	useEffect(() => {
		if (tarifas.length === 0) {
			traerTarifas();
		}
		if (Object.keys(empresa).length !== 0) {
			setEmpresaCheck(true);
		} else {
			setEmpresaCheck(false);
		}
		//eslint-disable-next-line
	}, [empresaCheck, empresa]);

	////////////////////////////////////////////////////////////////////////////
	//return salida return salida return salida
	return (
		<>
			<Prompt
				onClose={setPrompt}
				show={prompt}
				setKey={setUsr}
				onValidKey={submitReserva}
			/>
			<div id='nuevaReserva'>
				<section className='grid-3 my'>
					<article>
						<input
							type='checkbox'
							value='1'
							name='factura'
							onChange={onChangeFactura}
						/>
						<label htmlFor='factura' className='m-1'>
							Asignar Datos Fiscales
							{empresaCheck && <i className='fas fa-check-circle paloma'></i>}
						</label>
					</article>
					<article>
						<input
							type='checkbox'
							value='1'
							name='factura2'
							onChange={onChangeNuevaEmpresa}
						/>
						<label htmlFor='factura2' className='m-1'>
							Capturar/Editar Datos Fiscales
						</label>
					</article>
					<article>
						<strong
							className={Object.keys(empresa).length > 0 ? "text-dark" : "text-danger"}
						>
							{Object.keys(empresa).length > 0
								? empresa.rsocial
								: "PUBLICO EN GENERAL"}
						</strong>
					</article>
				</section>
				{factura && <Rfcpicker />}
				{nuevaEmpresa && <Rsocial2 />}
				<form action='' onSubmit={trigPrompt} className='card'>
					<section className='grid-4'>
						{/*Columna Uno*/}
						<article className='columna'>
							{/*Campo apellido*/}
							<label htmlFor='apellido'>Apellido</label>
							<input
								name='apellido'
								type='text'
								value={apellido}
								onChange={onChangeApellido}
								className=''
								required
							/>
							{/*Campo nombre*/}
							<label htmlFor='nombre'>Nombre</label>
							<input
								name='nombre'
								type='text'
								value={nombre}
								onChange={onChangeNombre}
								className=''
								required
							/>
							{/*Campo grupo*/}
							<label htmlFor='grupo'>Grupo</label>
							<input
								name='grupo'
								type='text'
								value={grupo}
								onChange={onChangeGrupo}
								className=''
							/>
						</article>
						<article className='columna'>
							{/*Campo mail*/}
							<label htmlFor='mail'>Ciudad</label>
							<input
								name='ciudad'
								type='text'
								value={ciudad}
								onChange={onChangeCiudad}
								className=''
							/>

							<label htmlFor='estado'>Estado</label>
							<input
								type='text'
								name='estado'
								list='estado'
								value={estado}
								onChange={onChangeEstado}
								placeholder='Estado'
							/>
							{/*Campo mail*/}
							<label htmlFor='mail'>Email</label>
							<input
								name='mail'
								type='text'
								value={mail}
								onChange={onChangeMail}
								className=''
							/>
							<datalist id='estado'>
								{estados.map((row, i) => {
									return (
										<option key={i} value={row.estado}>
											{row.estado}
										</option>
									);
								})}
							</datalist>
						</article>
						<section className='grid-3 gspan-2'>
							<article className='columna'>
								{/*Campo llegada*/}
								<label htmlFor='llegada'>Llegada</label>
								<input
									name='llegada'
									type='date'
									value={llegada}
									onChange={onChangeLlegada}
									min={hoy}
									max='2122-01-01'
									className=''
									required
								/>
								{/*Campo salida*/}
								<label htmlFor='salida'>Salida</label>
								<input
									name='salida'
									type='date'
									value={salida}
									onChange={onChangeSalida}
									className=''
									min={hoy}
									max={"2123-01-01"}
									required
								/>
							</article>
							<article className='columna'>
								{/*Campo personas*/}
								<label htmlFor='personas'>No de personas</label>
								<input
									type='number'
									name='personas'
									value={personas}
									onChange={onChangePersonas}
									min='1'
									max='300'
								/>
								{/*Campo tel*/}
								<label htmlFor='tel'>Teléfono</label>
								<input
									name='tel'
									type='number'
									value={tel}
									onChange={onChangeTel}
									className=''
								/>
							</article>
							<article className='columna'>
								{/*Campo habitaciones*/}
								<label htmlFor='habitaciones'>Habitaciones</label>
								<input
									type='number'
									name='habitaciones'
									value={habitaciones}
									onChange={onChangeHabitaciones}
									className=''
									min='1'
									max='50'
								/>
								<label htmlFor='habitaciones'>Tarifa</label>
								<select name='tarifa' onChange={onChangeTarifa}>
									<option value='0'>Seleccione</option>
									{tarifass.map((res, i) => {
										return (
											<option key={i} value={res.Nombre}>
												{res.Nombre}
											</option>
										);
									})}
								</select>
								<label htmlFor='mpago'>Metodo de pago</label>
								<select name='mpago' onChange={onChangeMpago}>
									<option value='Efectivo'>Efectivo</option>
									<option value='Tarjeta de Credito'>T Credito</option>
									<option value='Tarjeta de Debito'>T Debito</option>
									<option value='Transferencia'>Transferencia</option>
									<option value='Cheque'>Cheque</option>
									<option value='Cuentas por cobrar'>CXC</option>
									<option value='XDE'>XDE</option>
								</select>
							</article>
						</section>
						<section className='grid-2 '></section>
					</section>
					<section
						style={{
							display: "flex",
							flexDirection: "row",
							fontSize: "1.2rem",
							padding: "10px 0",
						}}
					>
						{tarifa.map((row, i) => {
							return (
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										border: "1px solid rgba(0,0,0,0.2)",
										borderRadius: "15px",
										paddingLeft: "5px",
										marginRight: "10px",
									}}
								>
									<strong style={{ margin: "0 5px" }}>{row.habnum}</strong>
									<p>{row.tarifa}</p>
									<i
										className='fas fa-times-circle tacha floatRight'
										onClick={(r) => killtar(i)}
										style={{}}
									></i>
								</div>
							);
						})}
					</section>
					<section className='grid-2'>
						<div>
							<label htmlFor='info'>Comentarios</label>
							<textarea
								name='info'
								rows='3'
								value={info}
								onChange={onChangeInfo}
							></textarea>
						</div>
						<div>
							<label htmlFor='ref'>Referencia</label>
							<textarea
								name='ref'
								rows='3'
								value={ref}
								onChange={onChangeRef}
							></textarea>
						</div>
					</section>
					<section className='grid-2'>
						<input
							type='submit'
							className='btn btn-dark btn-block my gspan-2'
							value='Crear Reservacion'
						/>
					</section>
				</form>
			</div>
		</>
	);
};

export default NuevaReserva;
