import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";

const Cafeteriatd = ({
    cuenta: {
        factura,
        id,
        apertura,
        cierre,
        roomservice,
        habitacion,
        status,
        importe,
        pago,
        propina,
        mesa,
        impresa,
        rcargos
    }
}) => {
    ///////////////////////////////////////
    // CONTEXT
    const cafeteriaContext = useContext(CafeteriaContext);
    const {traerCheque } =cafeteriaContext;

    const navigate = useNavigate();

    ///////////////////////////////////////
    // STATE
    let tiempo;

    ///////////////////////////////////////
    // onClick
    const oCtraerCheque = async (e) => {
        e.preventDefault();
        await traerCheque(id);
        //if(resp.success)
            navigate(`/rcargos/${id}`);
    };
    ////////////////////////////////////////
    // METODOS
    const convertirTimestamp = (timestamp) => {
        if(timestamp){
            timestamp = timestamp.split(" ");
            const hora=timestamp[1].slice(0,5);
            timestamp = timestamp[0].split("-");
            const meses = [
                "dud", "Enero", "Feb",
                "Mar", "Abril", "Mayo",
                "Jun", "Jul", "Ago",
                "Sep", "Oct", "Nov", "Dic",
            ];
            const fecha= timestamp[2] + "/" + 
                meses[timestamp[1].replace(/^0+/,"")] + "/" +timestamp[0].substring(2,4);
            return [hora, fecha];
        }
        else
            return [null,null];
    };

    const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};
    
    const mayorPago = () => {
        let fpago = "";
        let pagos=[];
        let efe=0, cred=0, deb=0;
        if(status === "abierta" || status === "transferida")
            return ""
        if(rcargos!=="Cheque sin cargos"){
           
            rcargos.map((res)=>{
                if(res.clave === 502)
                    efe = res.importe
                if(res.clave === 503)
                    cred = res.importe
                if(res.clave === 504)
                    deb = res.importe   
            })   
            if(efe>cred&&efe>deb)
                fpago = "Efectivo";
            else if (cred > efe && cred > deb)
                fpago = "T. Crédito";
            else
                fpago = "T. Débito";
            return fpago;  
        }
        else
            return rcargos
    }

      if(status==="cerrada"||status==="facturada")
        tiempo=cierre;
      else
        tiempo=apertura;
      const [horaa, fechaa] = convertirTimestamp(tiempo);
      
      
      
    return( 
        
        <tr onClick={oCtraerCheque} className='clickable'>
    {/*        <td>{factura}</td> */}
            <td>{id}</td>
            <td>{fechaa}</td>
            <td>{horaa}</td>
            <td>{mesa??"-"}</td>
            <td>{roomservice===1?"SI":"NO"}</td>
            <td>{habitacion!==0?habitacion:"-"}</td>
            <td>{moneyForm(importe)}</td>
            <td>{moneyForm(importe)}</td>
            <td>{moneyForm(propina)}</td>
            <td>{mayorPago()}</td>
        </tr>
    
        
    );
};

Cafeteriatd.propTypes = {
    cuenta: PropTypes.object.isRequired,
};

export default Cafeteriatd;