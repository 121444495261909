import React, { useReducer } from "react";
import axios from "axios";
import CuentaReducer from "./cuentaReducer";
import CuentaContext from "./cuentaContext";
import {
	CUENTAS,
	CUENTA,
	TEST,
	MODAL,
	TURNO,
	LOADING,
	SALIDAS,
	FOLIOS,
	ACTIVAS,
} from "../types";

const CuentaState = (props) => {
	///////////////////
	//State Inicial
	const initialState = {
		cuentas: [],
		activas: [],
		folios: [],
		salidas: [],
		cuenta: {},
		modal: false,
		loading: false,
		turno: {},
	};

	///////////////////////////
	//traer access token
	const getaccessToken = async () => {
		return window.localStorage.getItem("access_token");
	};

	////////////////////////
	//se declara el dispatch
	const [state, dispatch] = useReducer(CuentaReducer, initialState);

	////////////////////////
	//generar nueva cuenta
	/////////////////////
	// traerReservasRangp de fechas
	const crearCuenta = async (body) => {
		try {
			const resp = await axios.post(`/api/cuentas`, body, {
				headers: { "Content-Type": "application/json" },
			});
			dispatch({ type: CUENTA, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error.response.data;
		}
	};

	////////////////////////
	//Traer Cuentas Activas
	////////////////////////
	const traerCuentas = async () => {
		dispatch({ type: CUENTAS, payload: [] });
		try {
			const resp = await axios.get(`/api/cuentas`);
			dispatch({ type: CUENTAS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error.response;
		}
	};

	////////////////////////
	//Traer Cuentas Activas
	////////////////////////
	const traerCuentasAct = async () => {
		dispatch({ type: ACTIVAS, payload: [] });
		try {
			const resp = await axios.get(`/api/cuentas/activas/1`);
			dispatch({ type: ACTIVAS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error.response;
		}
	};

	////////////////////////
	//Traer Cuentas Activas
	////////////////////////
	const traerCuentasCer = async () => {
		dispatch({ type: CUENTAS, payload: [] });
		try {
			const resp = await axios.get(`/api/cuentas/cerradas/1`);
			if (resp.data.success) {
				dispatch({ type: CUENTAS, payload: resp.data.data });
				return resp.data;
			}
		} catch (error) {
			return error.response;
		}
	};

	////////////////////////
	//Traer Cuentas Activas
	////////////////////////
	const traerCuentasFact = async (fecha) => {
		dispatch({ type: CUENTAS, payload: [] });
		let busqueda = "";
		if (typeof fecha !== "undefined") {
			busqueda = fecha;
		} else {
			let fechaRw = new Date();
			let ano = fechaRw.getFullYear();
			let mes = fechaRw.getMonth() + 1;
			busqueda = ano + "-" + mes;
		}
		try {
			const resp = await axios.get(`/api/cuentas/facturadas/${busqueda}`);
			console.log(resp);
			if (resp.data.success) {
				dispatch({ type: CUENTAS, payload: resp.data.data });
				return resp.data;
			}
		} catch (error) {
			return error.message;
		}
	};

	////////////////////////
	//Traer Cuentas Activas
	////////////////////////
	const traerCuentasCan = async (fecha) => {
		dispatch({ type: CUENTAS, payload: [] });
		let busqueda = "";
		if (typeof fecha !== "undefined") {
			busqueda = fecha;
		} else {
			let fechaRw = new Date();
			let ano = fechaRw.getFullYear();
			let mes = fechaRw.getMonth() + 1;
			busqueda = ano + "-" + mes;
		}
		try {
			const resp = await axios.get(`/api/cuentas/canceladas/${busqueda}`);
			if (resp.data.success) {
				dispatch({ type: CUENTAS, payload: resp.data.data });
				return resp.data;
			}
		} catch (error) {
			console.log(error.response);
			return error.message;
		}
	};

	////////////////////////
	//Traer Cuenta Single
	////////////////////////
	const traerCuenta = async (id) => {
		try {
			const resp = await axios.get(`/api/cuentas/${id}`);
			dispatch({ type: CUENTA, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error.response;
		}
	};

	////////////////////////
	// traer cuenta por habitacion
	const traerCuentaByHab = async (hab) => {
		try {
			const resp = await axios.get(`/api/cuentas/habitacion/${hab}`);
			console.log(resp);

			await dispatch({ type: CUENTA, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			console.log(error.response);
			return error.response;
		}
	};

	////////////////////////
	// traer cuenta por habitacion
	const traerCuentaByHabs = async (hab) => {
		try {
			const resp = await axios.get(`/api/cuentas/habitaciones/${hab}`);
			await dispatch({ type: CUENTAS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			console.log(error.response);
			return error.response;
		}
	};

	////////////////////////
	// traer cuentas cerradas por dia
	const traerCuentaByDia = async (day) => {
		try {
			const resp = await axios.get(`/api/cuentas/dia/${day}`);
			await dispatch({ type: CUENTAS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error.response;
		}
	};

	////////////////////////
	// traer cuenta por habitacion
	const traerCuentaByNom = async (nom) => {
		try {
			const respArr = new Array();
			const resp = await axios.get(`/api/cuentas/nombre/${nom}`);
			respArr.push(resp.data.data);
			dispatch({ type: CUENTAS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error;
		}
	};

	////////////////////////
	// traer cuenta por empresa
	const traerCuentaByEmpresa = async (empresa) => {
		try {
			const respArr = new Array();
			const resp = await axios.get(`/api/cuentas/empresa/${empresa}`);
			respArr.push(resp.data.data);
			dispatch({ type: CUENTAS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error;
		}
	};

	////////////////////////
	// traer cuenta por apertura
	const traerCuentaByLlegada = async (llegada) => {
		try {
			const respArr = new Array();
			const resp = await axios.get(`/api/cuentas/apertura/${llegada}`);
			respArr.push(resp.data.data);
			dispatch({ type: CUENTAS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			console.log(error.response);
			return error;
		}
	};

	////////////////////////
	// traer cuenta por habitacion
	const traerCuentaByGrupo = async (grupo) => {
		try {
			const respArr = new Array();
			const resp = await axios.get(`/api/cuentas/grupo/${grupo}`);
			respArr.push(resp.data.data);
			dispatch({ type: CUENTAS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error;
		}
	};

	////////////////////////
	// traer cuenta por habitacion
	const traerCuentaByFolio = async (folio) => {
		try {
			const respArr = new Array();
			const resp = await axios.get(`/api/cuentas/folio/${folio}`);
			respArr.push(resp.data.data);
			dispatch({ type: CUENTAS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error;
		}
	};

	////////////////////////
	// traer cuenta por habitacion
	const cuentaPatchByFolio = async (body) => {
		try {
			const resp = await axios.patch(`/api/cuentas/folio/${body.folio}`, body, {
				headers: { "Content-Type": "application/json" },
			});
			console.log(resp.data);
			dispatch({ type: CUENTA, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			console.log(error.response);

			return error.response;
		}
	};

	//////////////////////
	//cerrarCuenta
	const cerrarCuentaById = async (cid, cerro) => {
		const body = {
			cerro: cerro,
		};
		try {
			const resp = await axios.patch(`/api/cuentas/cierre/${cid}`, body, {
				headers: { "Content-Type": "application/json" },
			});
			return resp;
		} catch (error) {
			console.log(error.response);
			return error.response.data;
		}
	};

	//////////////////////
	//cerrarCuenta
	const cancelarCuentaById = async (cid, cerro) => {
		const body = {
			cerro: cerro,
		};
		let salida = "";
		try {
			const resp = await axios.patch(`/api/cuentas/cancelacion/${cid}`, body, {
				headers: { "Content-Type": "application/json" },
			});
			salida = resp.data;
		} catch (error) {
			console.log(error.response);
			salida = error.response;
		}
		return salida;
	};

	//////////////////////
	//separa cuenta
	const separarCuenta = async (body) => {
		let salida = "";
		try {
			const resp = await axios.post(`/api/cuentas/separacion/1`, body, {
				headers: { "Content-Type": "application/json" },
			});
			salida = resp.data;
		} catch (error) {
			console.log(error.response);
			salida = error.response;
		}
		return salida;
	};

	// set modal
	const setLoading = () => {
		dispatch({ type: LOADING, payload: true });
	};
	// set modal
	const unsetLoading = () => {
		dispatch({ type: LOADING, payload: false });
	};
	// set modal
	const setModalOpen = () => {
		dispatch({ type: MODAL, payload: true });
		//dispatch({type: CARGOS, payload: []});
	};

	// unset modal
	const unsetModalOpen = () => {
		dispatch({ type: MODAL, payload: false });
	};

	///////////////////////////
	// TEST
	///////////////////////////
	const test = async (id) => {
		await axios
			.get(`/api/cuentas/${id}`)
			.then((resp) => {
				dispatch({ type: TEST, payload: resp.data.data });
				return resp.data;
			})
			.catch((error) => {});
	};
	///////////////////////////
	//unseCuenta
	const usetCuenta = () => {
		dispatch({ type: CUENTA, payload: {} });
	};

	//////////////////////////
	// cerrar turno
	const cerrarTurno = async (usr) => {
		let body = {
			usr: usr,
		};
		try {
			const resp = await axios.patch(`/api/turnos`, body, {
				headers: { "Content-Type": "application/json" },
			});
			setLoading();
			dispatch({ type: TURNO, payload: resp.data.data });
			unsetLoading();
			return resp.data;
		} catch (error) {
			console.log(error);
			return error.response;
		}
	};

	//////////////////////
	// traer salidas por fecha
	const traerSalidas = async (apertura, cierre) => {
		try {
			const resp = await axios.get(`/api/salidas/${apertura}/${cierre}/`);
			console.log(resp);
			dispatch({ type: SALIDAS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			console.log(error.response);

			let dommie = [
				{
					id: 1,
					hab: 0,
					tarifa: "n/a",
					nombre: "n/a",
					cuenta: "0.0000",
					info: "n/a",
					usr: "n/a",
					hora: "0000-01-01 00:00:00",
					razon: "Sin salidas",
				},
			];
			dispatch({ type: SALIDAS, payload: dommie });
			return "No se encontraron Cargos en este turno";
		}
	};

	/////////////////////////////
	//traer esclavas
	const traerEsclavas = async (id) => {
		try {
			const resp = await axios.get(`/api/cuentas/esclavas/${id}`);
			if (resp.data.success) {
				dispatch({ type: FOLIOS, payload: resp.data.data });
				return resp.data.data;
			}
		} catch (err) {}
	};

	/////////////////////////////
	//limpiar sclavas
	const limpiarEsclavas = () => {
		dispatch({ type: FOLIOS, payload: [] });
	};
	/////////////////////////////
	//limpiar cuenta single
	const limpiarCuenta = () => {
		dispatch({ type: CUENTA, payload: {} });
	};
	/////////////////////////////
	//limpiar cuentas
	const limpiarCuentas = () => {
		dispatch({ type: CUENTAS, payload: [] });
	};
	/////////////////////////////
	//nuevo folio
	const nuevoFolio = async (id) => {
		try {
			const resp = await axios.post(`/api/cuentas/folio/${id}`);
			if (resp.data.success) {
				traerEsclavas(id);
			}
		} catch (error) {
			console.log(error.response);
		}
	};

	//////////////////////////
	// cerrar turno
	const traerTurno = async () => {
		try {
			const resp = await axios.patch(`/api/turnos`);
			setLoading();
			dispatch({ type: TURNO, payload: resp.data.data });
			unsetLoading();
			return resp.data;
		} catch (error) {
			console.log(error);
			return error.response;
		}
	};
	return (
		<CuentaContext.Provider
			value={{
				cuentas: state.cuentas,
				activas: state.activas,
				salidas: state.salidas,
				folios: state.folios,
				cuenta: state.cuenta,
				modal: state.modal,
				loading: state.loading,
				turno: state.turno,
				crearCuenta,
				traerCuentasAct,
				traerCuenta,
				traerCuentaByHab,
				traerCuentaByNom,
				setModalOpen,
				unsetModalOpen,
				usetCuenta,
				test,
				cerrarCuentaById,
				traerCuentasCer,
				cerrarTurno,
				setLoading,
				unsetLoading,
				traerTurno,
				traerCuentas,
				traerCuentasFact,
				cancelarCuentaById,
				traerCuentaByDia,
				traerCuentaByHabs,
				separarCuenta,
				traerEsclavas,
				traerSalidas,
				traerCuentasCan,
				traerCuentaByEmpresa,
				traerCuentaByLlegada,
				traerCuentaByGrupo,
				traerCuentaByFolio,
				cuentaPatchByFolio,
				limpiarEsclavas,
				nuevoFolio,
				limpiarCuenta,
				limpiarCuentas,
			}}
		>
			{props.children}
		</CuentaContext.Provider>
	);
};

export default CuentaState;
