import React, { useContext, useEffect, useState } from "react";
import ReservaContext from "../../contexto/reservacion/reservaContext.js";
import RegistroContext from "../../contexto/registro/registroContext.js";
import Dona from "../elementos/Dona.js";

const Inireporeserva = () => {
	const registroContext = useContext(RegistroContext);
	const { habitaciones, traerHabitaciones } = registroContext;
	console.log(registroContext);

	const [htotal, setHtotal] = useState(0);
	const [hvacial, setVacial] = useState(0);
	const [hocupada, setOcupada] = useState(0);
	const [hvacias, setVacias] = useState(0);
	const [hasignadal, setAsignadal] = useState(0);
	const [hasignadas, setAsignadas] = useState(0);
	const [dispo, setDispo] = useState(0);
	const [ocu, setOcu] = useState(0);
	const [sus, setSus] = useState(0);
	const [out, setOut] = useState(0);
	const [dondata, setDondata] = useState({
		disponibles: 0,
		ocupadas: 0,
		sucias: 0,
		ocupadas: 0,
	});
	const numeros = () => {
		setHtotal(habitaciones.length);
		setVacial(habitaciones.filter((row) => row.status === "vaciaL").length);
		setOcupada(habitaciones.filter((row) => row.status === "ocupada").length);
		setVacias(habitaciones.filter((row) => row.status === "vaciaS").length);
		setAsignadal(habitaciones.filter((row) => row.status === "asignadaL").length);
		setAsignadas(habitaciones.filter((row) => row.status === "asignadaS").length);
		var midata = {
			disponibles: hasignadal + hvacial,
			ocupadas: hocupada,
			sucias: hvacias,
			fds: hasignadas,
		};
		setDondata(midata);
	};

	/////////////////////////////
	// calcular pie
	const piepop = () => {
		let disp = ((hvacial + hasignadal) / htotal) * 100;
		let ocu = (hocupada / htotal) * 100;
		let sus = (hvacias / htotal) * 100;
		let out = (hasignadas / htotal) * 100;
		setDispo(3.6 * Math.round(disp));
		setOcu(3.6 * (Math.round(disp) + Math.round(ocu)));
		setSus(3.6 * (Math.round(disp) + Math.round(ocu) + Math.round(sus)));
		setOut(
			3.6 *
				(Math.round(disp) + Math.round(ocu) + Math.round(sus) + Math.round(out))
		);
	};
	useEffect(() => {
		numeros();
		piepop();
		habitaciones.length === 0 && traerHabitaciones();
		const intervalo = setTimeout(() => {
			traerHabitaciones();
		}, 4000);
		return () => clearTimeout(intervalo);
	}, [habitaciones]);

	return (
		<section className=''>
			<h1 className=''>Estado del Hotel</h1>
			<h2 className=''>
				Total de Habitaciones: <strong>{htotal}</strong>
			</h2>
			<Dona data={dondata} />
			<ul
				className='my-1 card'
				style={{ backgroundColor: "rgba(255,255,255,0.5)", borderRadius: "10px" }}
			>
				<li id='dispo' className='rel'>
					<p className='medium' style={{ margin: "15px 5px" }}>
						Disponibles:<strong> {hvacial + hasignadal}</strong>
					</p>
				</li>
				<li id='ocu' className='rel'>
					<p className='medium' style={{ margin: "15px 5px" }}>
						Ocupadas: <strong>{hocupada}</strong>
					</p>
				</li>
				<li id='sus' className='rel'>
					<p className='medium' style={{ margin: "15px 5px" }}>
						Sucias: <strong>{hvacias}</strong>
					</p>
				</li>
				<li id='out' className='rel'>
					<p className='medium' style={{ margin: "15px 5px" }}>
						Fuera de servicio: <strong>{hasignadas}</strong>
					</p>
				</li>
			</ul>
		</section>
	);
};

export default Inireporeserva;
