import React, { useContext, useEffect, useState } from "react";
import ReservaContext from "../../contexto/reservacion/reservaContext.js";
import RegistroContext from "../../contexto/registro/registroContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
import LinesChart from "../elementos/LinesChart.js";

const Inisalidas = () => {
	const reservaContext = useContext(ReservaContext);
	const registroContext = useContext(RegistroContext);
	const { traerSalidasarr } = registroContext;
	const alertContext = useContext(AlertContext);
	const { reservaciones, traerSalidas, traerReservasFechas, salidas } =
		reservaContext;
	const { setAlert } = alertContext;
	const [pendientes, setPendientes] = useState(0);
	const [salidasReg, setsalidasReg] = useState(0);
	const [reservasHoy, setreservasHoy] = useState(0);
	const [pausa, setPausa] = useState(false);
	let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
	let fecha = new Date(Date.now() - tzoffset).toISOString().slice(0, 10);
	const [busqSalidas, setBusqSalidas] = useState([]);
	const [busqReservas, setBusqReservas] = useState([]);
	const [salarr, setSalarr] = useState([]);
	const [fechaState, setFechaState] = useState(fecha);
	const monthNames = [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];
	let time = fechaState + "T00:00:01";
	const d = new Date(time);
	const dia = d.getDate() + " " + monthNames[d.getMonth()];
	//////////////////////////
	//chart
	const salidasArr = async () => {
		const arr = await traerSalidasarr();
		if (arr.success) {
			setSalarr(arr.data);
		}
	};

	////////////////////////
	const setHoy = async () => {
		let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
		let fecha = new Date(Date.now() - tzoffset).toISOString().slice(0, 10);
		let saliresp = await traerSalidas(fecha);
		if (saliresp.success) {
			console.log(saliresp);
			setBusqSalidas(saliresp.data.salidas);
			setPendientes(saliresp.data.salidas.filter((r) => r.status === 1).length);
			setsalidasReg(saliresp.data.salidas.filter((r) => r.status === 0).length);
		}
		let body2 = {
			bsqf1: fecha,
			bsqf2: fecha,
		};
		const reshoy = await traerReservasFechas(body2);
		console.log(reshoy);

		if (reshoy.success) {
			setFechaState(fecha);
			setreservasHoy(reshoy.data.registros);
			setBusqReservas(reshoy.data.reservas);
		}
		setPausa(false);
	};
	//////////////////////////////
	//date change
	const onDateChange = (e) => {
		setPausa(true);
		const salidas = traerSalidas(e.target.value);
		salidas.then((sr) => {
			if (sr.success) {
				setBusqSalidas(sr.data.salidas);
				setPendientes(sr.data.salidas.filter((r) => r.status === 1).length);
				setsalidasReg(sr.data.salidas.filter((r) => r.status === 0).length);
			} else {
				setAlert("no se encontraron registros en ese día", "danger");
				setBusqSalidas([]);
				setPendientes(0);
				setsalidasReg(0);
			}
		});
		let body = {
			bsqf1: e.target.value,
			bsqf2: e.target.value,
		};
		const reservas = traerReservasFechas(body);
		reservas.then((rr) => {
			console.log(rr);
			if (rr.success) {
				setBusqReservas(rr.data.reservas);
			} else {
				setBusqReservas([]);
			}
		});
		setFechaState(e.target.value);
		setPausa(true);
	};

	useEffect(() => {
		if (!pausa) {
			salidas.length === 0 && setHoy();
			const intervalo = setTimeout(() => {
				traerSalidas(fechaState);
				let body2 = {
					bsqf1: fechaState,
					bsqf2: fechaState,
				};
				traerReservasFechas(body2);
				return () => clearTimeout(intervalo);
			}, 5000);
		}
		if (salarr.length === 0) {
			salidasArr();
		}
	}, [pausa, busqSalidas, salarr]);

	return (
		<div className='w-100 grid-2'>
			<div className='gspan-2'>
				<h1 className=''>Estado del Hotel</h1>
				<h3>Salidas y Reservas Próximas</h3>
				<LinesChart dataarr={salarr} label={"Próximas Salidas"} />
			</div>
			{/*Col 2 salidas del dia*/}
			<section className=''>
				<form className='flexlinea'>
					<label htmlFor='mes'>Buscar por Día:</label>
					<input
						className='datemes'
						name='mes'
						type='date'
						value={fechaState}
						onChange={onDateChange}
					/>
				</form>
				<article>
					<h3>
						Salidas pendientes {dia}:
						<span className='large'>{"  " + pendientes}</span>
					</h3>
					<table className='w-100 left'>
						<thead>
							<tr>
								<th>Nombre</th>
								<th>Habs.</th>
							</tr>
						</thead>
						<tbody>
							{salidas.length === 0 ? (
								<tr>
									<td colSpan={2}>
										<h2>Sin salidas para este día</h2>
									</td>
								</tr>
							) : (
								busqSalidas.length > 0 &&
								busqSalidas.map((row, i) => {
									if (row.status === 1) {
										return (
											<tr key={i}>
												<td>{row.apellido + " " + row.nombre}</td>
												<td>{row.habitacion}</td>
											</tr>
										);
									}
								})
							)}
						</tbody>
					</table>
					<h3>
						Salidas registradas {dia}:
						<span className='large'>{"  " + salidasReg}</span>
					</h3>
					<table className='w-100 left'>
						<thead>
							<tr>
								<th>Nombre</th>
								<th>Habs.</th>
							</tr>
						</thead>
						<tbody>
							{salidas.length === 0 ? (
								<tr>
									<td colSpan={2}>
										<h2>Sin salidas para este día</h2>
									</td>
								</tr>
							) : (
								busqSalidas.length > 0 &&
								busqSalidas.map((row, i) => {
									if (row.status === 0) {
										return (
											<tr key={i}>
												<td>{row.apellido + " " + row.nombre}</td>
												<td>{row.habitacion}</td>
											</tr>
										);
									}
								})
							)}
						</tbody>
					</table>
				</article>
			</section>
			<section>
				<button className='btn btn-dark' onClick={setHoy}>
					Hoy
				</button>
				<h3>
					Reservas para {dia}
					<span className='large'>{"  " + reservasHoy}</span>
				</h3>
				<table className='w-100 left'>
					<thead>
						<tr>
							<th>Nombre</th>
							<th>Habs.</th>
						</tr>
					</thead>
					<tbody>
						{busqReservas.length === 0 ? (
							<tr>
								<td colSpan={2}>
									<h2>Sin reservas para este día</h2>
								</td>
							</tr>
						) : (
							busqReservas.map((row, i) => {
								return (
									<tr key={i}>
										<td>{row.apellido + " " + row.nombre}</td>
										<td>{row.habitaciones}</td>
									</tr>
								);
							})
						)}
					</tbody>
				</table>
			</section>
		</div>
	);
};

export default Inisalidas;
