import React, { useContext, useEffect } from "react";
import tarifas from "../../assets/tarifas";
import RegistroContext from "../../contexto/registro/registroContext.js";

const Regarr = ({
	setHabsArr,
	habsArr,
	sHabitaciones,
	setsHabitaciones,
	ciudad,
	estado,
}) => {
	const registroContext = useContext(RegistroContext);
	const { habitaciones } = registroContext;
	const tarsolo = tarifas.map((r) => {
		return r.Nombre;
	});
	const habsolo = habitaciones.map((row) => {
		if (row.status === "vaciaL" || row.status === "asignadaL") {
			return parseInt(row.habitacion);
		}
	});

	///////////////////
	// habs
	const habsPlus = (e) => {
		e.preventDefault();
		if (sHabitaciones < 100) {
			setsHabitaciones(sHabitaciones + 1);
			setHabsArr([
				...habsArr,
				{
					habitacion: 0,
					tarifa: "NOCD1",
					titular: "titular",
					ciudad: ciudad,
					estado: estado,
				},
			]);
		}
	};
	////////////////////
	//
	const killhab = (i) => {
		let arrcpy = habsArr;
		arrcpy.splice(i, 1);
		setHabsArr(arrcpy);
		setsHabitaciones(sHabitaciones - 1);
		console.log(habsArr);
	};
	const habsMinus = (e) => {
		e.preventDefault();
		if (sHabitaciones > 1) {
			setsHabitaciones(sHabitaciones - 1);
			habsArr.splice(-1);
		}
	};
	////////////
	//onchange
	const onChangeM = (index, e) => {
		const values = [...habsArr];
		values[index][e.target.name] = e.target.value;
		setHabsArr(values);
	};

	///////////
	//Tarifa
	const onChangeTar = (index, e) => {
		const values = [...habsArr];
		values[index].tarifa = e.target.value;
		setHabsArr(values);
	};

	///////////
	//Titular
	const onChangeTitular = (index, e) => {
		const values = [...habsArr];
		values[index].titular = e.target.value;
		setHabsArr(values);
	};
	//////////////////
	//useEffect
	useEffect(() => {
		habsArr.length > 1 && setsHabitaciones(habsArr.length);
		//eslint-disable-next-line
	}, [habsArr]);
	return (
		<section>
			<section>
				<article>
					{/*Campo sHabitaciones*/}
					<label htmlFor='sHabitaciones'>Habitaciones</label>
					<div className='grid-3'>
						<h2>{sHabitaciones}</h2>
						<button onClick={habsPlus}>+</button>
						<button onClick={habsMinus}>-</button>
					</div>
				</article>
			</section>
			<section id='habsec' className='grid-2 gspan-6 my-1'>
				{habsArr.map((row, i) => {
					return (
						<div key={i} id={`hab-${i}`} className=''>
							<p className='small bold'>Habitacion / Tarifa / Titular</p>
							<div
								className='flexRigth'
								style={{
									border: "1px solid black",
									padding: "4px",
									gridGap: "0.5rem",
									borderRadius: "6px",
								}}
							>
								<input
									type='number'
									name='habitacion'
									list='hab'
									value={row.noHab}
									placeholder='Numero'
									size={11}
									onChange={(e) => onChangeM(i, e)}
									required
									style={{ width: "60%" }}
								/>
								<datalist id='hab'>
									{habsolo.map((res, i) => {
										return <option key={i} value={res}></option>;
									})}
								</datalist>
								<input
									type='text'
									name='tarifa'
									list='tarifas'
									placeholder={row.tarifa}
									onChange={(e) => onChangeTar(i, e)}
									size={3}
									style={{ width: "60%" }}
								/>
								<datalist id='tarifas'>
									{tarifas.map((res, i) => {
										return (
											<option key={i} value={res.Nombre}>
												{res.Nombre}
											</option>
										);
									})}
								</datalist>
								<input
									className='gspan-2'
									type='text'
									name='titular'
									placeholder={row.titular}
									onChange={(e) => onChangeTitular(i, e)}
								/>
								<i
									className='fas fa-times-circle tacha floatRight'
									onClick={(r) => killhab(i)}
									style={{}}
								></i>
							</div>
						</div>
					);
				})}
			</section>
		</section>
	);
};

export default Regarr;
