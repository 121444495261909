import React, { useReducer } from "react";
import axios from "axios";
import CargoContext from "./cargoContext.js";
import CargoReducer from "./cargoReducer.js";
import { MODAL, CARGOS, CARGO, LIMPIAR, ABONOS, RENTAS } from "../types";
import { test } from "../../componentes/cargos/Test";

const CargoState = (props) => {
	//////////////////
	//State Inicial
	const initialState = {
		cargos: [],
		cargo: {},
		abonos: [],
		rentas: [],
		loading: false,
		modal: false,
	};

	////////////////////////////
	//traer access token
	const getaccessToken = async () => {
		return window.localStorage.getItem("access_token");
	};

	////////////////////////
	//se declara el dispatch
	const [state, dispatch] = useReducer(CargoReducer, initialState);

	///////////////////////
	// Añadir un Cargo
	const nuevoCargo = async (body) => {
		try {
			const resp = await axios.post(`/api/cargos`, body, {
				headers: { "Content-Type": "application/json" },
			});
			return resp.data;
		} catch (error) {
			return error.response;
		}
	};

	/////////////////////
	// editar cargo
	const editarCargo = async (body) => {
		let feed = null;
		await axios
			.get(`/api/cargos`, body)
			.then((resp) => {
				feed = resp.data;
			})
			.catch((error) => {
				feed = error.response;
			});
		return feed;
	};

	/////////////////////
	// set folio
	const cambiarFolio = async (body) => {
		let cid = body.cid;
		let folbo = body.folio;
		try {
			const resp = await axios.patch(`/api/cargos/folio/${cid}`, folbo, {
				headers: { "Content-Type": "application/json" },
			});
			return resp;
		} catch (error) {
			console.log(error.response);
		}
	};

	/////////////////////
	// editar cargo
	const borrarCargo = async (cid, usr) => {
		let ousr = { usuario: usr };
		try {
			const resp = await axios.delete(
				`api/cargos/${cid}`,
				{ data: ousr },
				{
					headers: { "Content-Type": "application/json" },
				}
			);
			console.log(resp);
			return resp;
		} catch (error) {
			console.log(error.response);
			return error.response;
		}
	};

	/////////////////////
	// Traer Cargos por Habitación
	const traerCargosHab = async (hab) => {
		await axios
			.get(`/api/cargos/${hab}`)
			.then((resp) => {
				dispatch({ type: CARGOS, payload: resp.data.data });
				return resp.data;
			})
			.catch((error) => {});
	};

	//////////////////////
	// traer cargo single
	const traerCargoSingle = async (cid) => {
		await axios
			.get(`/api/cargos/${cid}`)
			.then((resp) => {
				dispatch({ type: CARGO, payload: resp.data.data });
			})
			.catch((error) => {});
	};

	//////////////////////
	// traer cargos por cuenta
	const traerCargos = async (cuenta_id) => {
		await axios
			.get(`/api/cargos/cuenta/${cuenta_id}`)
			.then((resp) => {
				dispatch({ type: CARGOS, payload: resp.data.data });
			})
			.catch((error) => {
				dispatch({ type: CARGOS, payload: test[0].cargos });
			});
	};

	//////////////////////
	// traer cargos por cuenta
	const traerCargosByFechas = async (apertura, cierre) => {
		try {
			const resp = await axios.get(`/api/cargos/fechas/${apertura}/${cierre}/`);
			dispatch({ type: CARGOS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			let dommie = [
				{
					id: 1,
					cuenta_id: 3,
					importe: "0",
					iva: "0",
					ieh: "0",
					abono: "0.0000",
					metodo_pago: "",
					referencia: "",
					concepto: "Sin cargos",
					habitacion: 0,
					comentario: "",
					fecha: "0000-01-01 00:00:00",
					cajero: "Art",
				},
			];
			dispatch({ type: CARGOS, payload: dommie });
			return "No se encontraron Cargos en este turno";
		}
	};
	//////////////////////
	// traer cargos por cuenta
	const traerCargosByCuenta = async (busqCuentaid) => {
		try {
			const resp = await axios.get(`/api/cargos/cuenta/${busqCuentaid}`);
			dispatch({ type: CARGOS, payload: resp.data.data });
			return resp.data;
		} catch (error) {
			return error.response;
		}
	};

	//////////////////////
	// traer cargos por cuenta
	const traerTurnoUltimo = async () => {
		try {
			const resp = await axios.get(`/api/turnos`);
			if (resp.data.success) {
				return resp.data;
			}
		} catch (err) {
			return err.response;
		}
	};

	//////////////////////
	// traer cargos por cuenta
	const traerTurnosByDia = async (fecha) => {
		try {
			const resp = await axios.get(`api/abonos/fechas/dia/${fecha}`);
			if (resp.data.success) {
				return resp.data;
			}
		} catch (err) {
			return err.response;
		}
	};

	//////////////////////
	// traer cargos por cuenta
	const traerTurnoByDia = async (turno, dia) => {
		let body = {
			fecha: dia,
		};
		try {
			const resp = await axios.post(`api/turnos/single/${turno}`, body, {
				headers: { "Content-Type": "application/json" },
			});
			if (resp.data.success) {
				return resp.data;
			}
		} catch (err) {
			return err.response;
		}
	};

	///////////////////////////
	//traerabonos
	const traerAbonosByFecha = async (apertura, cierre) => {
		try {
			const resp = await axios.get(`/api/abonos/fechas/${apertura}/${cierre}/`);
			console.log(resp);
			if (resp.data.messages) {
				dispatch({ type: ABONOS, payload: resp.data.data });
				return resp.data.messages;
			}
		} catch (error) {
			dispatch({ type: ABONOS, payload: [] });
			return error.response.data;
		}
	};
	//////////////////////////
	//cerrar turno
	const cerrarTurno = async (usr) => {
		const body = {
			usr: usr,
		};
		try {
			const resp = await axios.post(`/api/turnos`, body, {
				headers: { "Content-Type": "application/json" },
			});
			if (resp.data.success) {
				return resp.data;
			}
		} catch (err) {
			return err.response;
		}
	};

	//////////////////////////////
	// traerRentas
	const traerRentas = async (dia) => {
		try {
			const resp = await axios.get(`api/cargos/rentas/sabana/${dia}`);
			if (resp.data.success) {
				dispatch({ type: RENTAS, payload: resp.data.data });
				console.log(resp.data);
				return resp.data;
			}
		} catch (err) {
			return err.response;
		}
	};
	// set modal
	const setModalOpen = () => {
		dispatch({ type: MODAL, payload: true });
		dispatch({ type: CARGO, payload: {} });
	};

	// unset modal
	const unsetModalOpen = () => {
		dispatch({ type: MODAL, payload: false });
	};

	const limpiar = () => {
		dispatch({ type: LIMPIAR, payload: [] });
	};
	const limpiarCargos = () => {
		dispatch({ type: CARGOS, payload: [] });
	};
	return (
		<CargoContext.Provider
			value={{
				cargos: state.cargos,
				cargo: state.cargo,
				modal: state.modal,
				abonos: state.abonos,
				rentas: state.rentas,
				traerCargosByFechas,
				nuevoCargo,
				editarCargo,
				traerCargosHab,
				traerCargos,
				traerCargoSingle,
				setModalOpen,
				unsetModalOpen,
				limpiar,
				traerTurnoUltimo,
				cerrarTurno,
				traerAbonosByFecha,
				traerTurnosByDia,
				borrarCargo,
				traerTurnoByDia,
				traerRentas,
				cambiarFolio,
				limpiarCargos,
				traerCargosByCuenta,
			}}
		>
			{props.children}
		</CargoContext.Provider>
	);
};

export default CargoState;
