import React, { useContext } from "react";
import { Link } from "react-router-dom";
import logoc from "../../assets/log-circulo.png";
import AutContext from "../../contexto/autentificacion/autContext";
const Header = () => {
	const autContext = useContext(AutContext);
	const { logout } = autContext;
	const logoutOnClick = () => {
		logout();
	};
	const role = window.localStorage.getItem("role");
	return (
		<header id='main'>
			<nav className='container text-right overvi'>
				<div className='branding'>
					<img src={logoc} alt='Logo nav' />

					<h3 className='mr-a '>
						<Link to='/'>Sistema Real Plaza</Link>
					</h3>
					{role === "admin" || role === "housek" ? (
						<Link to='/rack' className='noprint'>
							<p className='small'>Rack</p>
						</Link>
					) : null}
					{role === "admin" && (
						<div className='dropdown'>
							<button className='Link small mainNavBtn noprint'>Altas</button>
							<div className='dropdown-menu'>
								<Link to='/empresas' className='noprint'>
									<p className='small'>Empresas</p>
								</Link>
								<Link to='/reservaciones' className='noprint'>
									<p className='small'>Reservaciones</p>
								</Link>
								<Link to='/registros' className='noprint'>
									<p className='small'> Registros</p>
								</Link>
							</div>
						</div>
					)}

					{(role === "admin" || role === "conta") && (
						<div className='dropdown'>
							<button className='Link small mainNavBtn noprint'>Administración</button>
							<div className='dropdown-menu'>
								<Link to='/cuentas' className='noprint'>
									<p className='small'>Cuentas</p>
								</Link>
								<Link to='/cargos/0' className='noprint'>
									<p className='small'>Cargos</p>
								</Link>
								<Link to='/cortes' className='noprint'>
									<p className='small'>Caja</p>
								</Link>
								<Link to='/cancelaciones' className='noprint'>
									<p className='small'>Cancelaciones</p>
								</Link>
								<Link to='/consultas' className='noprint'>
									<p className='small'>Consultas</p>
								</Link>
							</div>
						</div>
					)}
					{(role === "admin" || role === "conta" || role === "housek") && (
						<Link to='/auditoria' className='noprint'>
							<p className='small'>Auditoria</p>
						</Link>
					)}
					{(role === "admin" || role === "conta") && (
						<div className='dropdown'>
							<button className='Link small mainNavBtn noprint'>Facturación</button>
							<div className='dropdown-menu'>
								<Link to='/Descargas' className='noprint'>
									<p className='small'>Facturas</p>
								</Link>
								<Link to='/cfacturacion' className='noprint'>
									<p className='small'>Facturacion C y D</p>
								</Link>
								<Link to='/Complemento' className='noprint'>
									<p className='small'>Complemento pago</p>
								</Link>
							</div>
						</div>
					)}
					{(role === "admin" || role === "conta" || role === "cafe") && (
						<Link to='/cafeteria' className='noprint'>
							<p className='small'>Cafetería</p>
						</Link>
					)}
					<i
						className='fas fa-sign-in-alt noprint'
						style={{ marginLeft: "10px" }}
						onClick={logoutOnClick}
					></i>
					{role === "admin" && (
						<Link to='/tutoriales' className='noprint'>
							<p className='small'>Tutoriales</p>
						</Link>
					)}
				</div>
			</nav>
		</header>
	);
};

export default Header;
