export const clavesctrl = [
	{
		clave: 1233,
		usuario: "ART",
	},
	{
		clave: 2697,
		usuario: "LCO",
	},
	{
		clave: 2906,
		usuario: "AMT",
	},
	{
		clave: 6118,
		usuario: "PGC",
	},
	{
		clave: 8811,
		usuario: "RPM",
	},
	{
		clave: 9095,
		usuario: "MEMD",
	},
	{
		clave: 1977,
		usuario: "VH",
	},
	{
		clave: 5460,
		usuario: "NPV",
	},
	{
		clave: 1709,
		usuario: "MDH",
	},
	{
		clave: 1825,
		usuario: "LTB",
	},
	{
		clave: 1925,
		usuario: "BOV",
	},
	{
		clave: 2209,
		usuario: "RMD",
	},
	{
		clave: 2417,
		usuario: "MLC",
	},
	{
		clave: 8426,
		usuario: "RLD",
	},
	{
		clave: 7913,
		usuario: "KGC",
	},
	{
		clave: 3816,
		usuario: "MTO",
	},
	{
		clave: 1437,
		usuario: "CLR",
	},
	{
		clave: 2548,
		usuario: "EFC",
	},
	{
		clave: 3659,
		usuario: "SRX",
	},
	{
		clave: 4761,
		usuario: "JIN",
	},
	{
		clave: 5872,
		usuario: "KOM",
	},
	{
		clave: 4711,
		usuario: "SAP",
	},
];
export default clavesctrl;
