import React, { useContext } from "react";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import { useNavigate } from "react-router-dom";

const Reportetd = ({
  cuenta: {
    id,
    fechafactura,
    apertura,
    cierre,
    roomservice,
    habitacion,
    status,
    importe,
    pago,
    rcargos,
    descuento,
    propina,
    mesa,
    turno,
    impresa,
  },
}) => {
  ///////////////////////////////////////
  // CONTEXT
  const cafeteriaContext = useContext(CafeteriaContext);
  const { traerCheque } = cafeteriaContext;

  const navigate = useNavigate();
  ////////////////////////////////
  //METODOS
  const convertirTimestamp = (timestamp) => {
    if (timestamp) {
      timestamp = timestamp.split(" ");
      const hora = timestamp[1].slice(0, 5);
      timestamp = timestamp[0].split("-");
      const meses = [
        "dud",
        "Enero",
        "Feb",
        "Mar",
        "Abril",
        "Mayo",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ];
      const fecha =
        timestamp[2] +
        "/" +
        meses[timestamp[1].replace(/^0+/, "")] +
        "/" +
        timestamp[0].substring(2, 4);
      return [hora, fecha];
    } else return [null, null];
  };

  const moneyForm = (raw) => {
    const numero = parseFloat(raw);
    if (!isNaN(numero)) {
      const res = "$" + numero.toFixed(2);
      return res;
    } else {
      return "Sin Cargos";
    }
  };

  ///////////////////////////////////////
  // onClick
  const oCtraerCheque = async (e) => {
    e.preventDefault();
    await traerCheque(id);
    //if(resp.success)
    navigate(`/rcargos/${id}`);
  };

  const [hora, fecha] = convertirTimestamp(cierre);

  const abonos = (forma) => {
    let efe = 0,
      tarjeta = 0,
      impTotal = 0,
      propina = 0,
      cargoHab = 0,
      cxc = 0;
    let efeDesc = 0;
    if (Object.values(rcargos).length > 0 && rcargos !== "Cheque sin cargos") {
      rcargos.forEach((row, i) => {
        if (row.status === 1)
          switch (row.clave) {
            case 501:
              cargoHab += parseFloat(row.importe);
              break;
            case 502:
              efe += parseFloat(row.importe);
              break;
            case 503:
            case 504:
            case 506:
              tarjeta += parseFloat(row.importe);
              break;
            case 505:
              propina += parseFloat(row.importe);
              break;
            default:
              impTotal += parseFloat(row.importe);
              break;
          }

        if (impresa === 99 || impresa === 6 || impresa === 7 || descuento > 0) {
          efeDesc =
            (parseFloat(impTotal) * (100 - descuento)) / 100 -
            parseFloat(tarjeta);
        } else efeDesc = parseFloat(impTotal) - parseFloat(tarjeta);

        if (parseFloat(efe) > parseFloat(efeDesc) && efe > 0) efe = efeDesc;
      });
      if (efe > 0) efe = efe + propina;
    }
    if (impresa === 99) {
      cxc = cargoHab;
      cargoHab = 0;
    }
    switch (forma) {
      case "imp":
        return impTotal;
      case "efe":
        return efe;
      case "prop":
        return propina;
      case "tar":
        return tarjeta;
      case "hab":
        return cargoHab;
      case "cxc":
        return cxc;
      default:
        break;
    }
  };

  return (
    <tr
      className={
        status === "cancelada" ? "text-line text-italic clickable" : "clickable"
      }
      onClick={oCtraerCheque}
    >
      <td>{id}</td>
      <td>{fecha}</td>
      <td>{hora}</td>
      <td>{roomservice ? habitacion : mesa}</td>
      <td>{moneyForm(abonos("imp") / 1.16)}</td>
      <td>{moneyForm(abonos("prop"))}</td>
      <td>{moneyForm((abonos("imp") / 1.16) * 0.16)}</td>
      <td>{moneyForm(abonos("imp") + abonos("prop"))}</td>
      <td>{moneyForm(abonos("efe"))}</td>
      <td>{moneyForm(abonos("tar"))}</td>
      <td>{moneyForm(abonos("cxc"))}</td>
      <td>
        {impresa === 5
          ? moneyForm(
              (abonos("imp") * (100 - descuento)) / 100 + parseFloat(propina)
            )
          : moneyForm(0)}
      </td>
      <td>
        {impresa === 6
          ? moneyForm((abonos("imp") * (100 - descuento)) / 100)
          : moneyForm(0)}
      </td>
      <td>
        {impresa !== 6 && descuento > 0 && descuento < 50
          ? moneyForm(abonos("imp") * (descuento / 100))
          : moneyForm(0)}
      </td>
    </tr>
  );
};
export default Reportetd;
