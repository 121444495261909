import React, { useContext, useEffect, useState } from "react";
import ReservaContext from "../../contexto/reservacion/reservaContext";
import NuevoRegistro from "../registros/NuevoRegistro.js";
//import Spinner from "../elementos/Spinnermodal";
//import facturacionContext from "../../contexto/facturacion/facturacionContext";
import AlertContext from "../../contexto/alerta/alertContext";
import Prompt from "../../assets/Prompt";
import Reservaprint from "./Reservaprint";
import ReactToPrint from "react-to-print";
import tarifass from "../../assets/tarifas";

const ReservaSingle = () => {
	const reservaContext = useContext(ReservaContext);
	//const { empresa, empresas, actualizaDatos } = useContext(facturacionContext);
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const [registrar, setRegistrar] = useState(false);
	const [reserva, setReserva] = useState({});
	const [prompt, setPrompt] = useState(false);
	const [usr, setUsr] = useState(false);
	const {
		reservacion,
		unsetModalOpen,
		patchReserva,
		patchCliente,
		limpiarReservas,
	} = reservaContext;

	const resref = React.createRef();
	const [tarr, setTarr] = useState([]);
	const onChangeTarifa = (e) => {
		if (e.target.value === "0") {
			return;
		}
		let row = {
			habnum: reserva.habitaciones,
			tarifa: e.target.value,
		};
		setTarr((tarr) => [...tarr, row]);
	};
	if (
		typeof reserva.info !== "undefined" &&
		reserva.info !== "" &&
		reserva.info !== null
	) {
		console.log(reserva.info);

		if (reserva.info.includes("\n")) {
			let splitinfo = reserva.info.split("\n");
			if (splitinfo.length > 1) {
				setReserva({ ...reserva, info: splitinfo[1] });
			}
		} else if (
			reserva.info.includes("habitaciones") &&
			!reserva.info.includes("\n")
		) {
			setReserva({ ...reserva, info: "" });
		}
	}
	let tarif = "";
	let tarifasarr = "";
	if (tarr.length > 0) {
		tarr.map((r) => {
			tarif = tarif + r.habnum + "x " + r.tarifa + " ";
			for (let i = 0; i < r.habnum; i++) {
				tarifasarr = tarifasarr + r.tarifa + " ";
			}
		});
	}

	////////////////////
	// killtar
	const killtar = (indice) => {
		setTarr(tarr.filter((row, i) => i !== indice));
	};

	//const reservaLength = Object.keys(reservacion).length;
	const { id, reg, tarifas } = reservacion;

	const closeModal = () => {
		unsetModalOpen();
	};

	const actualizaReserva = async (usr) => {
		const reser = {
			//modifico : usr,
			folio: reserva.folio,
			llegada: reserva.llegada,
			salida: reserva.salida,
			personas: reserva.personas,
			habitaciones: reserva.habitaciones,
			tarifas: tarifasarr,
			grupo: reserva.grupo,
			info: tarif + "\n" + reserva.info,
			ref: reserva.ref,
			cliente: reserva.cliente,
			empresa: reserva.empresa,
			reservo: reserva.reservo,
			cancelo: reserva.cancelo,
		};
		const resp1 = await patchReserva(reser);

		if (reserva.mail === "") reserva.mail = null;
		const cli = {
			id: reserva.cliente_id,
			nombre: reserva.nombre,
			apellido: reserva.apellido,
			ciudad: reserva.ciudad,
			tel: reserva.tel,
			estado: reserva.estado,
			mail: reserva.mail,
		};
		const resp2 = await patchCliente(cli);
		if (resp1.success || resp2.success) {
			setAlert("Datos actualizados", "success");
			limpiarReservas();
			closeModal();
		} else {
			setAlert("Error al actualizar verifique los datos", "danger");
		}
	};

	const regTogg = () => {
		registrar ? setRegistrar(false) : setRegistrar(true);
	};

	useEffect(() => {
		if (Object.keys(reserva).length === 0) setReserva(reservacion);
		let temptar = [];
		if (
			typeof tarifas !== "undefined" &&
			tarifas !== null &&
			tarifas !== "" &&
			tarr.length === 0
		) {
			let pretarr = tarifas.match(/[^ ]+/g);
			const tarset = [...new Set(pretarr)];
			tarset.forEach((tarifa) => {
				const habnum = pretarr.filter((e) => e === tarifa).length;
				temptar.push({ tarifa, habnum });
			});
			setTarr(temptar);
		}
	}, [reservacion, reserva]);

	if (!registrar) {
		return (
			<>
				<div className='modalOver' />
				<Prompt
					onClose={setPrompt}
					show={prompt}
					setKey={setUsr}
					onValidKey={actualizaReserva}
				/>
				<div id={id} className='grid-4 modal smallgapx'>
					<h2 className='gspan-4'>
						Reserva: {id}{" "}
						<span className='floatRight'>
							<i className='fas fa-times-circle' onClick={closeModal}></i>
						</span>
					</h2>
					<div className='gspan-2 grid-2'>
						<div>
							<p className='small bold'>Nombre</p>
							<input
								type='text'
								value={reserva.nombre}
								onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
								readOnly={true}
								onChange={(e) => setReserva({ ...reserva, nombre: e.target.value })}
							></input>
						</div>
						<div>
							<p className='small bold'>Apellido</p>
							<input
								type='text'
								value={reserva.apellido}
								onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
								readOnly={true}
								onChange={(e) => setReserva({ ...reserva, apellido: e.target.value })}
							></input>
						</div>
						<article>
							<p className='small bold'>Grupo</p>
							<input
								type='text'
								value={reserva.grupo}
								onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
								readOnly={true}
								onChange={(e) => setReserva({ ...reserva, grupo: e.target.value })}
							></input>
						</article>
						<article>
							<p className='small bold'>No.personas</p>
							<input
								type='text'
								value={reserva.personas}
								onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
								readOnly={true}
								onChange={(e) => setReserva({ ...reserva, personas: e.target.value })}
							></input>
						</article>
						<div className='gspan-2'>
							<p className='small bold'>Comentarios</p>
							<input
								type='text'
								value={reserva.info}
								onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
								readOnly={true}
								onChange={(e) => setReserva({ ...reserva, info: e.target.value })}
							></input>
						</div>
					</div>

					<div className='gspan-2 grid-2'>
						<article>
							<p className='small bold'>LLegada</p>
							<input
								type='date'
								value={reserva.llegada}
								onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
								readOnly={true}
								onChange={(e) => setReserva({ ...reserva, llegada: e.target.value })}
							></input>
						</article>
						<article>
							<p className='small bold'>No. habitaciones</p>
							<input
								type='text'
								value={reserva.habitaciones}
								onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
								readOnly={true}
								onChange={(e) =>
									setReserva({ ...reserva, habitaciones: e.target.value })
								}
							></input>
						</article>
						<article>
							<p className='small bold'>Salida</p>
							<input
								type='date'
								value={reserva.salida}
								onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
								readOnly={true}
								onChange={(e) => setReserva({ ...reserva, salida: e.target.value })}
							></input>
						</article>
						<article>
							<p className='small bold'>Tarifas</p>
							<select name='tarifa' onChange={onChangeTarifa}>
								<option value='0'>Seleccione</option>
								{tarifass.map((res, i) => {
									return (
										<option key={i} value={res.Nombre}>
											{res.Nombre}
										</option>
									);
								})}
							</select>
						</article>
						<div className='gspan-2'>
							<p className='small bold'>Referencia</p>
							<input
								type='text'
								value={reserva.ref}
								onDoubleClick={(e) => (e.target.readOnly = !e.target.readOnly)}
								readOnly={true}
								onChange={(e) => setReserva({ ...reserva, ref: e.target.value })}
							></input>
						</div>
					</div>
					<section
						className='gspan-4'
						style={{
							display: "flex",
							flexDirection: "row",
							fontSize: "1.2rem",
							padding: "10px 0",
						}}
					>
						{tarr.map((row, i) => {
							return (
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										border: "1px solid rgba(0,0,0,0.2)",
										borderRadius: "15px",
										paddingLeft: "5px",
										marginRight: "10px",
									}}
									key={i}
								>
									<strong style={{ margin: "0 5px" }}>{row.habnum}</strong>
									<p>{row.tarifa}</p>
									<i
										className='fas fa-times-circle tacha floatRight'
										onClick={(r) => killtar(i)}
										style={{}}
									></i>
								</div>
							);
						})}
					</section>
					<button
						className='btn btn-dark gspan-4'
						onClick={() => {
							setPrompt(true);
						}}
					>
						Actualizar
					</button>
					<div className='gspan-4 text-center'>
						{/*reg === 0 ? (
							<button className='btn btn-dark' onClick={regTogg}>
								Registrar
							</button>
						) : null*/}
						<ReactToPrint
							trigger={() => (
								<button
									className='btn btn-dark cerrarCuentabtn my-1'
									style={{ marginRight: "10px" }}
								>
									Imprimir Reserva
								</button>
							)}
							content={() => resref.current}
						/>
					</div>
				</div>
				<div ref={resref}>
					<Reservaprint reserva={reserva} tarif={tarif} />
				</div>
			</>
		);
	} else {
		return (
			<>
				<div className='modalOver'>
					<div className='modal'>
						<NuevoRegistro />
						<div className='gspan-4 text-center my-05'>
							<button className='btn' onClick={regTogg}>
								regresar
							</button>
						</div>
					</div>
				</div>
			</>
		);
	}
};
export default ReservaSingle;
