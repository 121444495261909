import React, { useContext, useState, useEffect } from "react";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import Reportetdf from "./Reportetdf";
import Spinner from "../elementos/Spinnermodal";
import facturacionContext from "../../contexto/facturacion/facturacionContext";
import alertContext from "../../contexto/alerta/alertContext";

const Reportethf = (props) => {
  ////////////////////////////
  //CONTEXT
  const { cheques, loading, setVistaReporte, setVistaFacturas } =
    useContext(CafeteriaContext);
  const { facturas, traerFacturasByFechas } = useContext(facturacionContext);
  const { setAlert } = useContext(alertContext);
  ////////////////////////////
  //STATE
  const [fechaR, setFechaR] = useState("");
  const [imp, setImp] = useState(0);
  const [iva, setIva] = useState(0);
  const [total, setTotal] = useState(0);
  const [propina, setPropina] = useState(0);
  const [facturadas, setFacturadas] = useState([]);

  ////////////////////////////
  //METODOS
  const cambioVista = () => {
    setVistaFacturas(false);
    setVistaReporte(false);
    setFacturadas([]);
  };

  const moneyForm = (raw) => {
    const numero = parseFloat(raw);
    if (!isNaN(numero)) {
      const res = "$" + numero.toFixed(2);
      return res;
    } else {
      return "Sin Cargos";
    }
  };

  const fechaBusq = () => {
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let date = new Date();
    let fecha = "";

    fecha = props.fecha.split(" ");
    fecha = fecha[0].split("-");
    date.setFullYear(fecha[0]);
    date.setMonth(fecha[1] - 1);
    date.setDate(fecha[2]);

    setFechaR(date.toLocaleDateString("es-ES", options));
  };

  const imprimir = () => {
    let areaImpresion = document.getElementById("tabla").innerHTML;
    let original = document.body.innerHTML;

    document.body.innerHTML = areaImpresion;
    window.print();
    document.body.innerHTML = original;
    //window.location.reload();
  };
  ////////////////////////////
  //FILTRA CUENTAS CERRADAS/FACTURADAS/TRANSFERIDAS
  const reporteInicial = () => {
    let imp = 0,
      iva = 0,
      prop = 0,
      total = 0;
    let facturada = [];
    fechaBusq();

    if (Object.keys(facturas).length > 0)
      facturas.forEach((res) => {
        if (res.serie === "B") facturada = [...facturada, res];
      });

    if (Object.values(facturada).length > 0) {
      facturada.forEach((res) => {
        res.propina = 0;
        let fimporte = 0,
          ftotal = 0;
        cheques.forEach((row) => {
          let cprop = 0;
          if (row.id === res.c_id)
            row.rcargos.forEach((cargo) => {
              if (cargo.clave === 505) {
                cprop += parseFloat(cargo.importe);
              }
            });
          res.propina = cprop;
        });
        prop += res.propina;
        imp += res.importe - res.propina; //parseFloat(res.importe);
        iva += res.iva;
        total += res.total - res.propina;
      });
    }
    setImp(imp);
    setFacturadas(facturada);
    setPropina(prop);
    setIva(iva);
    setTotal(total);
  };

  useEffect(() => {
    console.log(cheques);
    const traeFacturas = async () => {
      const alert = await traerFacturasByFechas(
        props.fecha + "T00:00:00",
        props.fecha + "T23:59:59"
      );
      if (typeof alert === "object")
        setAlert("Facturas encontradas", "success");
      else {
        setAlert(alert, "danger");
        setFacturadas(alert);
      }
      console.log(alert);
    };
    let fecha;
    if (Object.keys(facturas).length > 0) {
      fecha = facturas[0].fecha.slice(0, 10);
      if (fecha !== props.fecha || typeof facturadas === "string")
        traeFacturas();
    } else if (Object.keys(facturas).length === 0) {
      traeFacturas();
    }
    reporteInicial();
  }, [cheques, facturas, props.fecha]);

  return (
    <>
      <div className="xmodalOver" id="tabla">
        <h1>
          <div className="grid-6 noprint"></div>
          Facturas del día {fechaR}
        </h1>
        <div>{loading && <Spinner />}</div>
        <table className="w-100">
          <thead>
            <tr className="W-100">
              <th>FACTURA</th>
              <th>CHEQUE</th>
              <th>FECHA</th>
              <th>RFC</th>
              <th>RAZON SOCIAL</th>
              <th>IMPORTE</th>
              <th>IVA</th>
              <th>TOTAL</th>
              <th>PROPINA</th>
              <th>GRAN TOTAL</th>
              <th>FORMA PAGO</th>
            </tr>
          </thead>
          <tbody>
            {typeof facturadas === "string" ? (
              <tr>
                <td>{facturadas}</td>
              </tr>
            ) : (
              facturadas.map((res) => <Reportetdf key={res.id} factura={res} />)
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="4" />
              <th>Totales</th>
              <th>{moneyForm(imp)}</th>
              <th>{moneyForm(iva)}</th>
              <th>{moneyForm(total)}</th>
              <th>{moneyForm(propina)}</th>
              <th>{moneyForm(total + propina)}</th>
            </tr>
          </tfoot>
        </table>
      </div>
      <button className="btn noprint" onClick={cambioVista}>
        ---VISTA CUENTAS
      </button>
      <button className="btn noprint" onClick={imprimir}>
        IMPRIMIR REPORTE
      </button>
    </>
  );
};

export default Reportethf;
