import React, { useContext, useEffect, useState } from "react";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import Spinner from "../elementos/Spinner.js";
import Descfactd from "./Descfactd.js";

const Descfacth = (props) => {
	const facturacionContext = useContext(FacturacionContext);
	const { facturas } = facturacionContext;
	const [crsocial, setCrsocial] = useState("");
	const [folio, setFolio] = useState("");
	const [apellido, setApellido] = useState("");
	const [factfil, setFactfil] = useState([]);
	const onChangeRsocial = (e) => {
		setCrsocial(e.target.value);
		let filtrado = facturas.filter((row) => {
			return row.rsocial.toLowerCase().includes(e.target.value.toLowerCase());
		});
		setFactfil(filtrado);
		setFolio("");
		setApellido("");
	};
	let lightmode = typeof props.mode !== "undefined" ? props.mode : false;
	const onChangeApellido = (e) => {
		setApellido(e.target.value);
		let filtrado = facturas.filter((row) => {
			if (row.apellido !== null) {
				return row.apellido.toLowerCase().includes(e.target.value.toLowerCase());
			}
		});
		setFactfil(filtrado);
		setFolio("");
		setCrsocial("");
	};
	const onChangeFolio = (e) => {
		setFolio(e.target.value);
		if (e.target.value === "") {
			setFactfil(facturas);
			return;
		}
		let serie = e.target.value.slice(0, 1);
		let folio = e.target.value.substring(1);
		let filtrado = facturas.filter((row) => {
			return row.serie === serie.toUpperCase();
		});
		let filtrado2 = filtrado.filter((row) => {
			return row.folio.toString().includes(folio.toString());
		});
		setFactfil(filtrado2);
		setApellido("");
		setCrsocial("");
	};
	return (
		<>
			{!lightmode ? (
				<div className='grid-3'>
					<div className=''>
						<label htmlFor='crsocial'>Buscar por razón social</label>
						<input
							type='text'
							name='crsocial'
							value={crsocial}
							onChange={onChangeRsocial}
						/>
					</div>
					<div className=''>
						<label htmlFor='folio'>Buscar por folio</label>
						<input type='text' name='folio' value={folio} onChange={onChangeFolio} />
					</div>
					<div className=''>
						<label htmlFor='Apellido'>Buscar por Apellio</label>
						<input
							type='text'
							name='apellido'
							value={apellido}
							onChange={onChangeApellido}
						/>
					</div>
				</div>
			) : (
				<h3>Facuras recientes de serie C y D</h3>
			)}
			<table id='car' className='w-100'>
				<thead>
					<tr>
						<th>Cuenta</th>
						<th>Fecha</th>
						<th>Razón Social</th>
						<th>Cliente</th>
						<th>Folio</th>
						<th>Folio Fiscal</th>
						<th>PDF</th>
						<th>XML</th>
						<th>Enviar</th>
					</tr>
				</thead>
				<tbody>
					{facturas.length == 0 && factfil == 0 ? (
						<tr>
							<td colSpan={8}>
								<Spinner />
							</td>
						</tr>
					) : facturas.length !== 0 && factfil == 0 ? (
						facturas.map((res) => <Descfactd key={res.id} factura={res} />)
					) : (
						factfil.map((res) => <Descfactd key={res.id} factura={res} />)
					)}
				</tbody>
			</table>
		</>
	);
};

export default Descfacth;
