import React, { useContext, useState, useEffect } from "react";
import ReservaContext from "../../contexto/reservacion/reservaContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
import RegistroContext from "../../contexto/registro/registroContext.js";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import tarifas from "../../assets/tarifas";
import Rfcpicker from "../facturacion/Rfcpicker.js";
import Prompt from "../../assets/Prompt";
import Regarr from "./Regarr.js";
import estados from "../../assets/estados";

const RegistroDirecto = ({ togg }) => {
	const hoyrw = new Date();
	const hoyr = new Date(hoyrw.getTime() - hoyrw.getTimezoneOffset() * 60000);
	const hoy = hoyr.toISOString().split("T")[0];
	const mananarw = new Date();
	const mananar = new Date(mananarw.setDate(mananarw.getDate() + 1));
	const manana = mananar.toISOString().split("T")[0];
	////////////////////
	//contexto
	const reservaContext = useContext(ReservaContext);
	const { traerTarifas, reservaChkId, reservacion, traerReservaSingle } =
		reservaContext;
	const facturacionContext = useContext(FacturacionContext);
	const { empresa, limpiarEmpresa, traerPublicoId } = facturacionContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const registroContext = useContext(RegistroContext);
	const { crearRegistro, unSetRegistros, habitaciones, traerVaciasL } =
		registroContext;

	///////////////////////////////////////
	const [prompt, setPrompt] = useState(false);
	const prompteador = (ev) => {
		ev.preventDefault();
		setPrompt(true);
	};
	///////////////////
	//state
	const [nombre, setNombre] = useState("");
	const [apellido, setApellido] = useState("");
	const [ciudad, setCiudad] = useState("");
	const [estado, setEstado] = useState("");
	const [mail, setMail] = useState("");
	const [grupo, setGrupo] = useState("");
	const [llegada, setLlegada] = useState(hoy);
	const [salida, setSalida] = useState(manana);
	const [personas, setPersonas] = useState(1);
	const [sHabitaciones, setsHabitaciones] = useState(1);
	const [info, setInfo] = useState("");
	const [ref, setRef] = useState("");
	const [factura, setFactura] = useState(false); // tooggle clientes
	const [credito, setCred] = useState(0);
	const [empresaCheck, setEmpresaCheck] = useState(null);
	const [folio, setFolio] = useState(0);
	const [folchk, setFolchk] = useState(false);
	const [usr, setUsr] = useState("");
	const [mpago, setMpago] = useState("Efectivo");
	const [tarlist, setTarlist] = useState("");
	const [habsArr, setHabsArr] = useState([
		{
			habitacion: 0,
			tarifa: "NOCD1",
			titular: "titular",
			ciudad: ciudad.length > 0 ? ciudad : "",
			estado: estado.length > 0 ? estado : "",
		},
	]);
	///////////////////
	//onChange inputs
	const onChangeNombre = (e) => setNombre(e.target.value);
	const onChangeApellido = (e) => setApellido(e.target.value);
	const onChangeCiudad = (e) => setCiudad(e.target.value);
	const onChangeEstado = (e) => setEstado(e.target.value);
	const onChangeMail = (e) => setMail(e.target.value);
	const onChangeGrupo = (e) => setGrupo(e.target.value);
	const onChangeLlegada = (e) => setLlegada(e.target.value);
	const onChangeSalida = (e) => setSalida(e.target.value);
	const onChangePersonas = (e) => setPersonas(e.target.value);
	const onChangeInfo = (e) => setInfo(e.target.value);
	const onChangeRef = (e) => setRef(e.target.value);
	const onChangeCred = (e) => setCred(e.target.value);
	const onChangeMpago = (e) => setMpago(e.target.value);
	const onChangeFactura = (e) =>
		e.target.checked ? setFactura(true) : setFactura(false); // toogle clienteszzz
	const OCnnC = (e) => {
		if (e.target.checked) {
			setFolchk(true);
		} else {
			setFolchk(false);
			setFolio(0);
		}
	};
	const regbody = {
		cId: null,
		cNombre: nombre,
		cApellido: apellido,
		cCiudad: ciudad,
		cEstado: estado,
		cMail: mail,
		rId: null,
		rGrupo: grupo,
		rLlegada: llegada,
		rSalida: salida,
		rMpago: mpago,
		tCredito: credito,
		rInfo: info,
		rRef: ref,
		rPersonas: personas,
		rHabitaciones: sHabitaciones,
		rTarifas: tarlist,
		rEmpresa:
			typeof empresa !== "undefined" && empresa.length > 0 ? empresa.id : null,
		usr: null,
		registros: habsArr,
	};

	const onChangeFolio = (e) => {
		setFolio(e.target.value);
		let stripInfo = "";
		let habsarrtemp = new Array();
		const resis = reservaChkId(e.target.value);
		resis
			.then((r1) => {
				console.log(r1);
				if (r1.status === 0) {
					let reserva = traerReservaSingle(e.target.value);
					reserva.then((r) => {
						setAlert("Reserva encontrada", "success");
						let titular = r1.titular;
						setHabsArr([]);
						if (
							typeof r.data.info !== "undefined" &&
							r.data.info !== "" &&
							r.data.info !== null
						) {
							if (r.data.info.includes("\n")) {
								let arrInfo = r.data.info.split("\n");
								if (arrInfo.length > 1) {
									stripInfo = arrInfo[1];
								} else {
									stripInfo = "";
								}
							}
						}
						console.log(r.data.tarifas.length);
						r.data.tarifas.length > 7
							? setsHabitaciones(r.data.tarifas.trim().split(" ").length)
							: setsHabitaciones(1);
						const tarifasArr = r.data.tarifas.trim().split(" ");
						tarifasArr.map((r) => {
							habsarrtemp.push({
								habitacion: 0,
								tarifa: r,
								titular: titular,
								ciudad: "ciudad",
								estado: "Estado",
							});
						});
						setHabsArr(habsarrtemp);
						setNombre(r.data.nombre);
						setApellido(r.data.apellido);
						setCiudad(r.data.ciudad);
						setEstado(r.data.estado);
						setMail(r.data.mail);
						setGrupo(r.data.grupo);
						setSalida(r.data.salida);
						setPersonas(r.data.personas);
						setInfo(stripInfo);
						r.data.ref !== null ? setRef(r.data.ref) : setRef("");
					});
				}
			})
			.catch((r) => {
				setNombre("");
				setApellido("");
				setCiudad("");
				setEstado("");
				setMail("");
				setGrupo("");
				setLlegada(hoy);
				setSalida(manana);
				setPersonas(1);
				setsHabitaciones(1);
				setInfo("");
				setRef("");
				setFactura(false); // tooggle clientes
				setCred(0);
				setEmpresaCheck(null);
				setUsr("");
				setMpago("Efectivo");
				setTarlist("");
				setHabsArr([
					{
						habitacion: 0,
						tarifa: "NOCD1",
						titular: "titular",
						ciudad: ciudad.length > 0 ? ciudad : "",
						estado: estado.length > 0 ? estado : "",
					},
				]);
				console.log(r.data);
			});
	};

	////////////////////////////
	// métodos
	const submitRregistro = async (usr) => {
		const tarsolo = habsArr.map((r) => {
			return r.tarifa;
		});
		regbody.rTarifas = tarsolo.toString().split(",").join(" ");
		regbody.usr = usr;
		console.log(regbody);
		const resp = await crearRegistro(regbody);
		console.log(resp);

		if (resp.success) {
			setAlert("Registro generado con Éxito", "success");
			setUsr("");
			unSetRegistros();
			togg();
		} else {
			setAlert(resp.messages[0], "danger");
		}
		console.log(resp);
	};

	//////////////////
	//useEffect
	useEffect(() => {
		if (tarifas.length === 0) {
			traerTarifas();
		}
		if (habitaciones.length === 0) traerVaciasL();
		//si submiteas usr se vuelve true
		if (sHabitaciones > 0) {
			let habsArrL = habsArr.length;
			console.log(habsArr);

			if (habsArrL > sHabitaciones) {
				console.log(habsArr.length);
				setsHabitaciones(habsArr.length);
			}
			let tartemp = "";
			habsArr.map((r) => {
				tartemp = tartemp + r.tarifa + ", ";
			});
			setTarlist(tartemp.slice(0, -2));
		}
		if (Object.keys(empresa).length !== 0) {
			setEmpresaCheck(true);
		}
		if (reservacion.length > 0) {
			console.log(reservacion);
		}

		//eslint-disable-next-line
	}, [usr, tarifas, sHabitaciones, empresa, habsArr]);

	////////////////////////////////////////////////////////////////////////////
	//return salida return salida return salida
	return (
		<>
			<Prompt
				onClose={setPrompt}
				show={prompt}
				setKey={setUsr}
				onValidKey={submitRregistro}
			/>
			<div id='nuevaReserva'>
				<form action='' onSubmit={prompteador} className='card'>
					<section className='grid-5'>
						{/*Columna Uno*/}
						<article className='columna gspan-2'>
							{/*Campo nombre*/}
							<label htmlFor='nombre'>Nombre</label>
							<input
								name='nombre'
								type='text'
								value={nombre}
								onChange={onChangeNombre}
								className=''
								required={folchk ? false : true}
								disabled={!folchk ? false : true}
							/>
							{/*Campo apellido*/}
							<label htmlFor='apellido'>Apellido</label>
							<input
								name='apellido'
								type='text'
								value={apellido}
								onChange={onChangeApellido}
								className=''
								required={folchk ? false : true}
								disabled={!folchk ? false : true}
							/>
							<label htmlFor='grupo'>Grupo</label>
							<input
								name='grupo'
								type='text'
								value={grupo}
								onChange={onChangeGrupo}
								className=''
								disabled={!folchk ? false : true}
							/>
						</article>
						<article className='columna'>
							{/*Campo nombre*/}
							<label htmlFor='nombre'>Ciudad</label>
							<input
								name='ciudad'
								type='text'
								value={ciudad}
								onChange={onChangeCiudad}
								className=''
								disabled={!folchk ? false : true}
							/>
							{/*Campo estado*/}
							<label htmlFor='estado'>Estado</label>
							<input
								type='text'
								name='estado'
								list='estado'
								value={estado}
								onChange={onChangeEstado}
								placeholder='Estado'
							/>
							<datalist id='estado'>
								{estados.map((row, i) => {
									return (
										<option key={i} value={row.estado}>
											{row.estado}
										</option>
									);
								})}
							</datalist>
							<label htmlFor='mail'>Mail</label>
							<input
								name='mail'
								type='text'
								value={mail}
								onChange={onChangeMail}
								className=''
								disabled={!folchk ? false : true}
							/>
						</article>

						{/*Columna Dos*/}
						<article className='columna'>
							{/*Campo llegada*/}
							<label htmlFor='llegada'>Llegada</label>
							<input
								name='llegada'
								type='date'
								value={llegada}
								onChange={onChangeLlegada}
								min={hoy}
								max='2122-01-01'
								className=''
								required={folchk ? false : true}
								disabled={!folchk ? false : true}
							/>
							{/*Campo salida*/}
							<label htmlFor='salida'>Salida</label>
							<input
								name='salida'
								type='date'
								value={salida}
								onChange={onChangeSalida}
								className=''
								min={hoy}
								max={"2123-01-01"}
								required={folchk ? false : true}
								disabled={!folchk ? false : true}
							/>
							{/*Campo personas*/}
							<label htmlFor='personas'>No de personas</label>
							<input
								type='number'
								name='personas'
								value={personas}
								onChange={onChangePersonas}
								min='1'
								max='300'
								disabled={!folchk ? false : true}
							/>
						</article>
						{/*Columna tress*/}
						<article className='columna'>
							{/*Campo personas*/}
							<input type='checkbox' onChange={OCnnC} />
							<label htmlFor='folio'>
								{" "}
								Folio{" "}
								<span className='mini' style={{ color: "grey" }}>
									{"(Si cuenta con reservación)"}
								</span>
							</label>
							<input
								type='number'
								name='folio'
								value={folio}
								min='1'
								onChange={onChangeFolio}
								disabled={folchk ? false : true}
								required={folchk ? true : false}
							/>
							<label htmlFor='credito'>Credito</label>
							<input
								name='credito'
								type='number'
								value={credito}
								onChange={onChangeCred}
								className=''
								disabled={!folchk ? false : true}
							/>
							<label htmlFor='mpago'>Metodo de pago</label>
							<select name='mpago' onChange={onChangeMpago}>
								<option value='Efectivo'>Efectivo</option>
								<option value='Tarjeta de Credito'>T Credito</option>
								<option value='Tarjeta de Debito'>T Debito</option>
								<option value='Transferencia'>Transferencia</option>
								<option value='Cheque'>Cheque</option>
								<option value='Cuentas por cobrar'>CXC</option>
							</select>
						</article>
					</section>
					<section>
						<Regarr
							setHabsArr={setHabsArr}
							habsArr={habsArr}
							sHabitaciones={sHabitaciones}
							setsHabitaciones={setsHabitaciones}
							ciudad={ciudad}
							estado={estado}
						/>
					</section>
					<section className='my-1'>
						<article>
							<input
								type='checkbox'
								value='1'
								name='factura'
								onChange={onChangeFactura}
							/>
							<label htmlFor='factura' className='m-1'>
								Asignar empresa{" "}
								{empresaCheck !== null && (
									<i className='fas fa-check-circle paloma'></i>
								)}
							</label>
						</article>
					</section>
					{factura ? <Rfcpicker /> : null}
					<section className='grid-2'>
						<div>
							<label htmlFor='info'>Comentarios</label>
							<textarea
								name='info'
								id=''
								rows='3'
								value={info}
								onChange={onChangeInfo}
								disabled={!folchk ? false : true}
							></textarea>
						</div>
						<div>
							<label htmlFor='ref'>Referencia</label>
							<textarea
								name='ref'
								id=''
								rows='3'
								value={ref}
								onChange={onChangeRef}
								disabled={!folchk ? false : true}
							></textarea>
						</div>
						<input
							type='submit'
							className='btn btn-dark btn-block my gspan-2'
							value='Crear Registro'
						/>
					</section>
				</form>
			</div>
		</>
	);
};

export default RegistroDirecto;
