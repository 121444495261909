import React, { useContext, useEffect, useState } from "react";
import CargoContext from "../../contexto/cargo/cargoContext";
import AlertContext from "../../contexto/alerta/alertContext";
import Rentastd from "./Rentastd";

const Rentasth = (props) => {
	const cargoContext = useContext(CargoContext);
	const { rentas, traerRentas } = cargoContext;
	let dommie = [
		{
			habitacion: 0,
			tarifa: "NOC01",
			cuenta: 0,
			nombre: "Sin Nombre",
			rsocial: "Sin Empresa",
			importe: 0,
			saldo: 0,
			reg: "N/A",
			ref: "no ref",
			info: "",
			llegada: "",
			salida: "",
		},
	];
	let fecha = new Date().toISOString().slice(0, 10);
	console.log(fecha);
	///////////////////////////////
	//formato de numero
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};

	let total = 0;
	let huesp = 0;
	rentas.map((row) => {
		total = total + row.cargo;
		let h = 0;
		row.tarifa.slice(0, 1) === "S"
			? (h = 1)
			: row.tarifa.slice(0, 1) === "D"
			? (h = 2)
			: row.tarifa.slice(0, 1) === "T"
			? (h = 3)
			: row.tarifa.slice(0, 1) === "C"
			? (h = 4)
			: (h = 0);
		huesp = huesp + h;
	});
	console.log(rentas);
	console.log(huesp);
	useEffect(() => {
		if (rentas.length === 0) {
			traerRentas(fecha);
		}
	}, [rentas]);
	return (
		<>
			<table className='w-100 tightable'>
				<thead>
					<tr>
						<th>Hab</th>
						<th>Tarifa</th>
						<th>Importe</th>
						<th>Cargo</th>
						<th>Nombre</th>
						<th>Empresa</th>
						<th>llegada</th>
						<th>Salida</th>
						<th>Pago</th>
						<th>Saldo</th>
						<th>Reg</th>
						<th>Referencia</th>
						<th>info</th>
					</tr>
				</thead>
				<tbody>
					{rentas.length > 0
						? rentas.map((row, i) => <Rentastd key={i} renta={row} />)
						: dommie.map((row, i) => <Rentastd key={i} renta={row} />)}
				</tbody>
				<tfoot>
					<tr>
						<td colSpan={3}>
							<h3>Total de habs: {rentas.length}</h3>
						</td>
						<td colSpan={3}>
							<h3>Total de cargos: {moneyForm(total)}</h3>
						</td>
						<td colSpan={3}>
							<h3>No. Aprox. de Huespedes: {huesp}</h3>
						</td>
					</tr>
				</tfoot>
			</table>
		</>
	);
};

export default Rentasth;
