import React, { useContext, useEffect, useState } from "react";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import FacturacionContext from "../../contexto/facturacion/facturacionContext";
import Facturacionth from "../facturacion/facturacionth";
import Rfcpickercyd from "../facturacion/Rfcpickercyd";
import metodosdp from "../../assets/metodosdp";
//import base from "../../assets/FacturaCafe.json";
import base from "../../assets/Factura4.json";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../elementos/Spinnermodal";
import AlertContext from "../../contexto/alerta/alertContext";
import Facturacioncth from "../cnegocios/facturacioncth";
import Descfacth from "../facturas/Descfacth";
import Prompt from "../../assets/Prompt";
import useFacturacion from "../facturacion/useFacturacion";

const Facturacion = (props) => {
	//////////////////////////////////
	// CONTEXT
	const { cheque, cargos, traerCheque } = useContext(CafeteriaContext);
	const {
		empresa,
		factura,
		generarFactura,
		traerFolioB,
		loading,
		setLoading,
		timbrarFactura,
		limpiarEmpresa,
		FacturasGetByCyD,
	} = useContext(FacturacionContext);
	const { setAlert } = useContext(AlertContext);
	const { executeFunction } = useFacturacion();
	const { id } = useParams();
	const navigate = useNavigate();
	const [serie, setSerie] = useState("C");
	const [clear, setClear] = useState(false);
	const [prompt, setPrompt] = useState(false);
	const [usr, setUsr] = useState("");
	const [nrs, setNrs] = useState("");
	const [xaxx, setXaxx] = useState(true);
	const [s01, setS01] = useState(false);
	const onChangeS01 = () => {
		setS01(!s01);
	};
	const onchangeNrs = (e) => {
		setNrs(e.target.value);
	};
	////////////////////////////
	// Check si es publico
	const setXrfc = (rfc) => {
		rfc === "XAXX010101000" && setXaxx(false);
	};
	////////////////////////////
	//propinas
	let propinas = 0;
	if (typeof cargos !== "undefined" && cargos.length >= 1) {
		cargos.forEach((row, i) => {
			if (row.descripcion === "PROPINA" && row.status === 1) {
				propinas += parseFloat(row.importe);
			}
		});
	}
	const propcom = propinas > 0 ? "Propina:$" + propinas : "";
	const totalIva = parseFloat(cheque.importe) + parseFloat(propinas);

	////////////////////////////////
	//CAMPO GENERICO
	const useField = ({ type }) => {
		const [value, setValue] = useState("");
		const onChange = (event) => {
			setValue(event.target.value);
			if (type === "checkbox") setValue(event.target.checked);
		};
		return { type, value, onChange };
	};

	//////////////////////////////////
	//STATE
	const [mp, setMp] = useState("00");
	const comentario = useField({ type: "text" });

	///////////////////////////////////
	// cambio de
	const metododePago = (mp) => {
		setMp(mp);
		console.log(mp);
	};
	/////////////////////////////////
	//METODOS
	///////////////////////////////

	///////////////////////////////
	//CLAVE
	const submitClave = (event) => {
		event.preventDefault();
		if (empresa.rsocial === undefined) {
			setAlert("No se eligió una razón social.", "danger");
			setLoading(false);
			return;
		}
		if (mp === "00") {
			setAlert("No se eligió un método de pago válido", "danger");
			setLoading(false);
			return;
		}
		setPrompt(true);
	};

	//money
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};

	////////////////////////////////////
	//FACTURA CAFETERIA
	const facturacionB = async () => {
		setLoading(true);
		console.log("PROPINAS", propinas);
		const total = parseFloat(cheque.importe);
		let importe = total / 1.16;
		const iva = Math.round(importe * 0.16 * 100) / 100;
		const folio = await traerFolioB();
		importe = total - iva;
		let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
		let fecha = new Date(Date.now() - tzoffset).toISOString().slice(0, 19);
		let fp = "";
		mp !== "99" ? (fp = "PUE") : (fp = "PPD");
		console.log("SUBTOTAL: ", importe + propinas, "TOTAL: ", total + propinas);
		base = {
			...base,
			Comprobante: {
				...base.Comprobante,
				Serie: "B",
				Folio: folio.folio,
				Fecha: fecha,
				FormaPago: mp,
				SubTotal: (importe + propinas).toFixed(2),
				Total: parseFloat(total + propinas).toFixed(2),
				Receptor: {
					Rfc: empresa.rfc,
					Nombre: empresa.rsocial.toUpperCase(),
					DomicilioFiscalReceptor: empresa.cp,
					UsoCFDI: "G03",
					RegimenFiscalReceptor: empresa.regimen,
				},
				Conceptos: [
					{
						ClaveProdServ: "90101500",
						NoIdentificacion: cheque.id,
						Cantidad: "1",
						ClaveUnidad: "E48",
						Unidad: "Servicio",
						Descripcion: "Consumo",
						ValorUnitario: importe,
						Importe: importe,
						ObjetoImp: "02",
						Impuestos: {
							Traslados: [
								{
									Base: importe,
									Impuesto: "002",
									TipoFactor: "Tasa",
									TasaOCuota: "0.160000",
									Importe: iva,
								},
							],
						},
					},
				],
				Impuestos: {
					TotalImpuestosTrasladados: parseFloat(iva),
					Traslados: [
						{
							Base: importe,
							Impuesto: "002",
							TipoFactor: "Tasa",
							TasaOCuota: "0.160000",
							Importe: iva,
						},
					],
				},
			},
		};
		//base.pdf.conceptos = base.Comprobante.Conceptos
		await generarFactura({
			body: base,
			usr: usr,
			mail: empresa.mail,
			cheque_id: cheque.id,
		});
		if (propinas > 0) {
			const conceptoPropina = {
				ClaveProdServ: "90111601",
				NoIdentificacion: cheque.id,
				Cantidad: "1",
				ClaveUnidad: "E48",
				Unidad: "Servicio",
				Descripcion: "Propina",
				ValorUnitario: propinas,
				Importe: propinas,
				ObjetoImp: "02",
				Impuestos: {
					Traslados: [
						{
							Base: propinas,
							Impuesto: "002",
							TipoFactor: "Tasa",
							TasaOCuota: "0.000000",
							Importe: 0,
						},
					],
				},
			};
			const trasladoPropina = {
				Base: propinas,
				Impuesto: "002",
				TipoFactor: "Tasa",
				TasaOCuota: "0.000000",
				Importe: 0,
			};
			base.Comprobante.Conceptos.push(conceptoPropina);
			base.Comprobante.Impuestos.Traslados.push(trasladoPropina);
		}

		const fact = {
			body: base,
			usr: usr,
			mail: empresa.mail,
			cheque_id: [cheque.id],
			pdf: {
				nombre: null,
				rsocial: empresa.rsocial,
				rfc: empresa.rfc,
				regimen: empresa.regimen,
				direccion: empresa.direccion,
				cp: empresa.cp,
				entrada: "",
				salida: fecha,
				fpago: mp,
				ciudad: empresa.ciudad,
				estado: empresa.estado,
				pais: "MÉXICO",
				mpago: fp,
				conceptos: [
					{
						fecha: cheque.cierre,
						clave: "90101501",
						cantidad: "1",
						punitario: importe.toFixed(2),
						concepto: "Consumo de Alimentos",
						importe: importe,
						iva: iva,
						ieh: 0,
					},
					{
						fecha: cheque.cierre,
						clave: "90111601",
						cantidad: "1",
						punitario: propinas.toFixed(2),
						concepto: "Propina",
						importe: propinas,
						iva: 0,
						ieh: 0,
					},
				],
				hab: "",
				uso: "03 Gastos en General",
				recibe: usr,
				cerro: usr,
				hospedaje: "0",
				cafeteria: moneyForm(importe),
				bar: "0",
				lavanderia: "0",
				cnegocios: "0",
				propinas: "",
				subtotal: moneyForm(importe),
				iva: moneyForm(iva),
				ieh: "0",
				total: moneyForm(total),
				totalIva: moneyForm(totalIva),
				comentarios: comentario.value + propcom,
			},
		};
		console.log(fact);
		fact.body.Comprobante.Serie = "B";
		let respuesta = await timbrarFactura(fact);

		if (respuesta.success) {
			setAlert(respuesta.messages[0], "success");
			navigate(`/cafeteria`);
		} else {
			executeFunction("error", respuesta.data.data);
		}
	};

	const facturacionC = async () => {
		setLoading(true);
		let fp = "";
		if (mp === "00") {
			setAlert("No se eligió un método de pago válido", "danger");
			setLoading(false);
			return;
		}
		mp !== "99" ? (fp = "PUE") : (fp = "PPD");
		console.log(fp);
		let fact = factura;
		let conceptos = [];
		const iva = fact.Comprobante.Impuestos.TotalImpuestosTrasladados;
		const ieh = fact.Comprobante.Complemento[0].ImpuestosLocales.TotaldeTraslados;
		let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
		let fecha = new Date(Date.now() - tzoffset).toISOString().slice(0, 19);
		let timbre;
		fact.Comprobante.Serie = serie;
		fact.Comprobante.FormaPago = mp;
		fact.Comprobante.MetodoPago = fp;
		fact.Comprobante.Fecha = fecha;
		fact.Comprobante.Receptor = {
			Rfc: empresa.rfc,
			Nombre: nrs !== "" ? nrs : empresa.rsocial,
			DomicilioFiscalReceptor: empresa.cp,
			UsoCFDI: nrs !== "" || s01 ? "S01" : "G03",
			RegimenFiscalReceptor: empresa.regimen,
		};
		fact.CamposPDF.Comentarios = comentario.value;
		fact.CamposPDF.calleReceptor = empresa.direccion;
		fact.CamposPDF.codigoPostalReceptor = empresa.cp;
		fact.CamposPDF.localidadReceptor = "";
		fact.CamposPDF.municipioReceptor = empresa.ciudad;
		fact.CamposPDF.estadoReceptor = empresa.estado;
		console.log(fact.Comprobante);
		if (typeof empresa.mail !== "undefined" && mp !== "00") {
			fact.Comprobante.Conceptos.forEach((res, i) => {
				let obj = {};
				obj.fecha = fecha;
				obj.concepto = res.Descripcion;
				obj.clave = res.ClaveProdServ;
				obj.importe = res.Importe;
				obj.cantidad = res.Cantidad;
				obj.punitario = res.ValorUnitario;
				obj.iva = res.Impuestos.Traslados[0].Importe;
				if (res.ClaveProdServ === "90111800")
					//SI ES HOSPEDAJE
					obj.ieh = Math.trunc(res.Importe * 3) / 100;
				else obj.ieh = "0";
				conceptos.push(obj);
			});

			timbre = {
				body: fact,
				usr: usr,
				mail: empresa.mail,
				cuenta_id: 0,
				pdf: {
					...fact.pdf,
					nombre: null,
					rsocial: nrs !== "" ? nrs : empresa.rsocial,
					rfc: empresa.rfc,
					regimen: empresa.regimen,
					direccion: empresa.direccion,
					cp: empresa.cp,
					entrada: "",
					salida: fecha,
					fpago: mp,
					ciudad: empresa.ciudad,
					estado: empresa.estado,
					pais: "MÉXICO",
					mpago: fp,
					conceptos: conceptos,
					hab: "",
					recibe: usr,
					cerro: usr,
					hospedaje: "",
					cafeteria: "",
					bar: "",
					uso:
						nrs !== "" || s01 ? "S01 Sin efectos fiscales" : "03 Gastos en General",
					lavanderia: "",
					cnegocios: "",
					subtotal: moneyForm(fact.Comprobante.SubTotal - fact.propina),
					iva: moneyForm(iva),
					ieh: moneyForm(ieh),
					total: moneyForm(fact.Comprobante.Total - fact.propina),
					totalIva: moneyForm(fact.Comprobante.Total),
					comentarios: comentario.value,
				},
			};
		} else {
			setAlert("Revisa la información proporcionada", "danger");
			return;
		}
		console.log("fact", timbre);

		setLoading(true);
		let respuesta = await timbrarFactura(timbre);

		if (respuesta.success) {
			//setAlert(respuesta.messages[0], "success");
			//navigate(`/`)
			setAlert("Factura procesada con éxito", "success");
			limpiarEmpresa();
			FacturasGetByCyD();
			setClear(true);
			comentario.value = "";
			//window.location.reload();
		} else {
			executeFunction("error", respuesta.data.data);
		}
	};

	useEffect(() => {
		const cheques = async () => {
			if (cheque.id === undefined || Object.keys(cheque).length === 0) {
				traerCheque(id);
			}
		};
		if (id !== undefined) {
			setSerie("B");
			cheques();
		}
		if (serie === "B") cheques();
		if (typeof empresa !== "undefined") {
			if (empresa.rfc === "XAXX010101000") {
				setXaxx(false);
			} else {
				setXaxx(true);
				setNrs("");
			}
		}
		if (typeof factura.Comprobante === "undefined") generarFactura(base);
		FacturasGetByCyD();
	}, [cargos, cheque, empresa]);

	return (
		<>
			<Prompt
				onValidKey={serie === "B" ? facturacionB : facturacionC}
				show={prompt}
				setKey={setUsr}
				onClose={setPrompt}
				mensaje='Para generar la factura, introduzca su clave de usuario:'
			/>
			<header>
				<h1>
					{serie === "B" ? "El Mezzanine" : "Real Plaza Aguascalientes"}- Facturación
					Serie {serie}
				</h1>
				<p style={{ color: "red" }}>
					{!xaxx &&
						'En 4.0 "PUBLICO EN GENERAL" solo es válido para facturas globales inserte una nueva razón para su factura a XAXX010101000'}
				</p>
			</header>
			<section className='grid-3 noprint'>
				<article>
					<label htmlFor='nrs'>Selecciones Serie</label>
					{serie === "B" ? null : (
						<select className='noprint' onChange={(e) => setSerie(e.target.value)}>
							<option value='C'>Serie C</option>
							<option value='D'>Serie D</option>
						</select>
					)}
				</article>
				<article>
					<label htmlFor='nrs'>Selcciones Empresa</label>
					<Rfcpickercyd setXrfc={setXrfc} />
				</article>
				<article className='w-100'>
					<label htmlFor='nrs'>Nueva Razón Social</label>
					<input
						type='text'
						name='nrs'
						onChange={onchangeNrs}
						value={nrs}
						disabled={xaxx}
					/>
				</article>
			</section>
			<section id='fdata' className='grid-2 my-1'>
				<div>
					<p className='medium'>
						RFC: <strong>{empresa.rfc}</strong>
					</p>
					<p className='medium'>
						Ciudad: <strong>{empresa.ciudad}</strong>
					</p>
					<p className='medium'>
						Dirección: <strong>{empresa.direccion}</strong>
					</p>
					<p className='medium'>
						Tel: <strong>{empresa.tel}</strong>
					</p>
				</div>
				<div>
					<p className='medium'>
						Razón S.:<strong> {nrs !== "" ? nrs : empresa.rsocial}</strong>
					</p>
					<p className='medium'>
						Estado: <strong>{empresa.estado}</strong>
					</p>
					<p className='medium'>
						C.P.: <strong>{empresa.cp}</strong>
					</p>
					<p className='medium'>
						Email: <strong>{empresa.mail}</strong>
					</p>
				</div>
			</section>
			{loading && <Spinner />}
			{serie === "B" ? (
				<Facturacionth />
			) : serie === "C" || serie === "D" ? (
				<Facturacioncth serie={serie} clear={clear} setClear={setClear} />
			) : (
				<div />
			)}
			<div>
				<label>
					<input
						type='checkbox'
						checked={s01}
						onChange={onChangeS01}
						style={{ margin: "10px" }}
					/>
					Sin Efectos Fiscales
				</label>
			</div>
			<div className='grid-3 noprint my-1'>
				<div>
					<label>Metodo de Pago:</label>
					<select
						value={mp}
						onChange={(e) => {
							metododePago(e.target.value);
						}}
					>
						<option value='00'>-Seleccione una forma de pago-</option>
						{metodosdp.map((res, i) => {
							return (
								<option key={i} value={res.claveSat}>
									{res.nombre}
								</option>
							);
						})}
					</select>
				</div>

				<div>
					<label>Comentario:</label>
					<input {...comentario}></input>
				</div>
				<button
					className='btn-block btn-primary noprint'
					//onClick={serie === "B" ? facturacionB : modalUp}
					onClick={submitClave}
				>
					Facturar
				</button>
			</div>
			{serie !== "B" && <Descfacth mode={true} />}
		</>
	);
};

export default Facturacion;
