import React, { useContext, useEffect, useState } from "react";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import { catalogo } from "./catalogo";
import { useParams } from "react-router-dom";
import Prompt from "../../assets/Prompt";
import alertContext from "../../contexto/alerta/alertContext";

const NuevoRcargo = () => {
	////////////////////////////////
	// CONTEXTO
	const { nuevoCargo, traerCheque, cheque, setLoading } =
		useContext(CafeteriaContext);
	const { setAlert } = useContext(alertContext);

	////////////////////////////////
	//STATE
	const [concepto, setConcepto] = useState({
		clave: "",
		descripcion: "",
		precio: 0,
		room: "",
		cantidad: 1,
	});
	const [cata, setCata] = useState([]);
	const [prompt, setPrompt] = useState(false);
	const [cajero, setCajero] = useState("");
	const { id } = useParams();

	////////////////////////////////
	//METODOS
	const onChangeField = (event) => {
		const { name, value } = event.target;
		setConcepto({ ...concepto, [name]: value });
	};

	const submitCargo = async (event) => {
		setLoading(true);
		event.preventDefault();
		const nCargo = {
			cant: parseInt(concepto.cantidad),
			clave: concepto.clave,
			descripcion: concepto.descripcion,
			precio: parseFloat(concepto.precio),
			importe: parseFloat(concepto.cantidad * concepto.precio),
			cajero: cheque?.aperturo ?? "-",
			cheque_id: parseInt(id),
		};
		await nuevoCargo(JSON.stringify(nCargo));
		await traerCheque(id);
		selector({ target: { value: "" } });
	};

	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "$0.00";
		}
	};

	const cambioPrecios = async () => {
		let cat = [];
		if (cheque.roomservice) {
			catalogo.forEach((res, index) => {
				cat[index] = {
					clave: res.clave,
					descripcion: res.descripcion,
					precio: res.room,
				};
			});
			await setCata(cat);
		} else await setCata(catalogo);
	};

	//////////////////////////////////////
	//selector de clave
	const selector = async (event) => {
		onChangeField(event);
		cata.forEach((res) => {
			if (res.clave === event.target.value) {
				//if (index === parseInt(event.target.value)) {
				setConcepto({ ...concepto, ...res });
				if (res.clave > 500 && res.clave < 505) {
					setConcepto({
						...concepto,
						...res,
						precio: (parseFloat(cheque.importe) + parseFloat(cheque.propina)).toFixed(
							2
						),
					});
				}
			}
		});
		if (event.target.value === "") {
			setConcepto({
				clave: "",
				descripcion: "",
				precio: 0,
				room: "",
				cantidad: 1,
			});
		}
	};
	if (cata.length === 0) cambioPrecios();

	useEffect(() => {
		if (cheque.roomservice !== undefined) cambioPrecios();
	}, [cheque]);
	return (
		<div className='noprint'>
			<Prompt
				onClose={setPrompt}
				show={prompt}
				setKey={setCajero}
				onValidKey={submitCargo}
			/>
			<form action='' onSubmit={submitCargo} className='grid-3 my'>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<div style={{ width: "40%", paddingRight: "15px" }}>
						<label>Cantidad:</label>
						<br />
						<input
							min={1}
							type='number'
							name='cantidad'
							value={concepto.cantidad}
							required
							step='1'
							onChange={onChangeField}
						/>
					</div>
					<div>
						<label>Clave:</label>
						<input
							id='menuinp'
							name='clave'
							list='menu'
							value={concepto.clave}
							type='text'
							onChange={selector}
							maxlength='3'
						/>
						<datalist id='menu'>
							{catalogo.map((option, index) => {
								return (
									<option key={index} value={option.clave}>
										{option.clave}-{option.descripcion}
									</option>
								);
							})}
						</datalist>
					</div>
				</div>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<div style={{ width: "100%", paddingRight: "15px" }}>
						<label>Descripcion:</label>
						<input
							type='text'
							name='descripcion'
							onChange={onChangeField}
							value={concepto.descripcion}
							required
						/>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-evenly",
					}}
				>
					<div style={{ width: "30%", paddingRight: "1rem" }}>
						<label>Precio:</label>
						<input
							name='precio'
							type='number'
							onChange={onChangeField}
							value={concepto.precio}
							min={0}
							required
							step='.01'
						/>
					</div>
					<div>
						<label>Importe</label>
						<br />
						<label>
							{moneyForm(concepto.cantidad * parseFloat(concepto.precio))}
						</label>
					</div>
					<button
						style={{ width: "25%" }}
						type='submit'
						className='btn-block btn-primary mzero'
						disabled={cheque.status === "abierta" ? false : true}
					>
						Agregar Cargo
					</button>
				</div>
			</form>
			<hr />
		</div>
	);
};

export default NuevoRcargo;
