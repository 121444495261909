import React, { useContext, useState, useEffect } from "react";
import CuentaContext from "../../contexto/cuenta/cuentaContext.js";
import Salidastd from "./Salidastd.js";
const Salidashuesp = () => {
	const cuentaContext = useContext(CuentaContext);
	const { salidas, traerSalidas } = cuentaContext;
	const [inicial, setInicial] = useState(false);

	let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
	let hoy = new Date(Date.now() - tzoffset).toISOString().slice(0, 10);
	const [fecha, setFecha] = useState(hoy);
	const [filist, setFilist] = useState([]);
	const meses = [
		"dud",
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];
	const fechaFormat = (fstring) => {
		const piezas = fstring.split("-");
		const mesindex = parseInt(piezas[1]);
		return piezas[2] + " de " + meses[mesindex] + " de " + piezas[0];
	};

	const dummy = {
		id: 1,
		hab: 0,
		tarifa: "n/a",
		nombre: "n/a",
		cuenta: "0.0000",
		info: "n/a",
		usr: "n/a",
		hora: "0000-01-01 00:00:00",
		razon: "Sin salidas",
	};

	if (!inicial) {
		traerSalidas(hoy + " 00:00:01", hoy + " 23:59:59");
		setInicial(true);
	}
	let filas = [];

	///////////////////////////
	/// onDayChange
	const onDayChange = (e) => {
		setFecha(e.target.value);
		traerSalidas(e.target.value + "T00:00:01", e.target.value + "T23:59:59");
	};
	////////////////////////////
	//use effect
	useEffect(() => {
		if (salidas.length > 0) {
			salidas.map((row) => {
				let fila = {
					hab: row.hab,
					tarifa: row.tarifa,
					nombre: row.nombre,
					cuenta: row.cuenta,
					info: row.info,
					usr: row.usr,
					hora: row.hora,
					razon: row.razon,
				};
				filas.push(fila);
			});
			setFilist(filas);
		}
	}, [salidas]);
	console.log(filist);
	return (
		<>
			<div className='grid-2'>
				<h2>Salidas del : {fechaFormat(fecha)}</h2>
				<form className='text-right noprint'>
					<label htmlFor='mes'>Buscar por Día</label>
					<input
						className='datemes'
						name='mes'
						type='date'
						value={fecha}
						style={{ display: "inline-block" }}
						onChange={onDayChange}
					/>
				</form>
			</div>
			<table className='w-100 printabla'>
				<thead className='stickiehead'>
					<tr>
						<th>Hab.</th>
						<th>Tarifa</th>
						<th>Huesped</th>
						<th>cuenta</th>
						<th>Comentarios</th>
						<th>Hora</th>
						<th>Registró</th>
						<th>Razón</th>
					</tr>
				</thead>
				<tbody>
					{filist.length > 0 ? (
						filist.map((fila) => {
							return <Salidastd filas={fila} />;
						})
					) : (
						<Salidastd filas={dummy} />
					)}
				</tbody>
				<tfoot>
					<tr>
						<td colSpan={3}>
							<h3>Total de Salidas: {filist.length}</h3>
						</td>
					</tr>
				</tfoot>
			</table>
		</>
	);
};

export default Salidashuesp;
