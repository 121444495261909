import React, { useContext, useEffect, useState } from "react";
import RegistroContext from "../../contexto/registro/registroContext.js";
import CargosRAtd from "./CargosRAtd";
import Spinner from "../elementos/Spinner.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
import clavesctrl from "../../assets/clavesctrl.js";

const CargosRAth = () => {
	const registroContext = useContext(RegistroContext);
	const { rentasa, traerCargosAuditoria, aplicarCargosAuditoria } =
		registroContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const nonoc = rentasa.filter((row) => row.comentario !== "NOCD1");
	const [sndd, setSndd] = useState(false);
	let tz = new Date(Date.now()).getTimezoneOffset();
	const hoy = new Date(Date.now() - tz);
	console.log(hoy);
	let current = hoy.getDate();
	hoy.setDate(current - 1);

	let mes = hoy.getMonth() + 1;
	let dia = hoy.getDate();
	let ano = hoy.getFullYear();

	let fechaCargo = dia + "/" + mes + "/" + ano;
	///////////////////////////////////
	//state modal
	const [usr, setUsr] = useState("");
	const dialogo = document.getElementById("diag");
	const onChangeUsr = (e) => setUsr(e.target.value);
	/////////////////////////////////////
	// validador
	const usrValidator = (usr, set) => {
		return new Promise((resolve, reject) => {
			let resp = set.find((row) => parseInt(usr) === row.clave);
			console.log(resp);
			if (typeof resp !== "undefined") {
				dialogo.close();
				resolve(resp.usuario);
			} else {
				dialogo.close();
				reject(Error("No tiene privilegios para realizar esta operación"));
			}
			setUsr("");
		});
	};
	///////////////////////////////////
	//modal tog
	const modalUp = (e) => {
		e.preventDefault();
		dialogo.showModal();
	};
	//////////////////////////////
	// aplicarCargos
	const aplicarCargos = async () => {
		usrValidator(usr, clavesctrl)
			.then((r) => {
				const resp = aplicarCargosAuditoria(r);
				resp
					.then((r2) => {
						setAlert("Cargos realizados con éxito", "success");
						setSndd(false);
					})
					.catch((e) => {
						setAlert("Fallo al realizar los cargos", "danger");
					});
			})
			.catch((E) => {
				setAlert("no tiene privilegios para realizar esta operación", "danger");
			});
	};

	//////////////////////////////////
	//check o traer cargos
	const checkoTraer = async () => {
		const resp = await traerCargosAuditoria();
		console.log(resp);
		if (resp.success) {
			setAlert("registros encontrados para cargo automatico", "success");
			setSndd(true);
		} else {
			setAlert(resp.messages, "danger");
		}
	};
	///////////////////////////////
	//use effect
	useEffect(() => {
		if (rentasa.length === 0) {
			checkoTraer();
		}
	}, [rentasa]);
	return (
		<>
			<h2>Cargo Automático de rentas del dia: {fechaCargo}</h2>
			<table className='w-100 printabla'>
				<thead className='stickiehead'>
					<tr>
						<th>Hab</th>
						<th>Concepto</th>
						<th>Importe</th>
						<th>IVA</th>
						<th>IEH</th>
						<th>Total</th>
						<th>Coment.</th>
						<th>Ref.</th>
					</tr>
				</thead>
				<tbody>
					{nonoc.length === 0 ? (
						<tr className='w-100'>
							<td colSpan={8}>
								<Spinner />
							</td>
						</tr>
					) : (
						nonoc.map((row, i) => {
							return <CargosRAtd key={i} cargo={row} />;
						})
					)}
				</tbody>
			</table>
			{sndd && (
				<button className='btn btn-dark floatRight my-1' onClick={modalUp}>
					Aplicar Cargos
				</button>
			)}
			<dialog id='diag' className='modal-xs'>
				<form method='dialog'>
					<p className='medium text-center'>
						Está por Cargos Automáticos a las habitaciones, ingrese su clave para
						continuar
					</p>
					<div className='my'>
						<input
							type='password'
							name='clave'
							value={usr}
							onChange={onChangeUsr}
							autoComplete='off'
						/>
						<button className='btn btn-dark w-100' onClick={aplicarCargos}>
							Validar
						</button>
					</div>
					<p className='mini text-center' style={{ color: "grey" }}>
						Presione Esc para cancelar
					</p>
				</form>
			</dialog>
		</>
	);
};

export default CargosRAth;
