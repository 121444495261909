import React, { useContext, useState } from "react";
import CafeteriaContext from "../../contexto/cafeteria/cafeteriaContext";
import alertContext from "../../contexto/alerta/alertContext";
import Prompt from "../../assets/Prompt";
import { useNavigate } from "react-router-dom";

const NuevoCheque = (props) => {
  ///////////////////////////////////////////
  //CONTEXTO
  const { nuevoCheque, setLoading } = useContext(CafeteriaContext);
  const { setAlert } = useContext(alertContext);
  const navigate = useNavigate();
  ////////////////////////////////////////////
  /// STATES
  const [cajero, setCajero] = useState("");
  const [prompt, setPrompt] = useState(false);

  ////////////////////////////////////////////
  //CAMPO GENERICO
  const useField = ({ type }) => {
    const [value, setValue] = useState("");
    const [checked, setChecked] = useState(false);

    const onChange = (event) => {
      const newValue = event.target.value;
      if (event.target.type === "number") {
        if (/^[1-9]$|^[1-4][0-9]$|^5[0-5]$/.test(newValue) || newValue === "")
          setValue(newValue);
        return;
      }
      setValue(newValue);
      if (type === "checkbox") setChecked(event.target.checked);
    };
    return {
      type,
      value,
      checked,
      onChange,
    };
  };

  ////////////////////////////////////////////
  // DECLARACION DE CAMPOS
  const mesa = useField({ type: "number" });
  const room = useField({ type: "checkbox" });
  const habitacion = useField({ type: "text" });

  //////////////////////////////////////////////
  //Métodos
  const submitClave = (event) => {
    event.preventDefault();
    if (habitacion.value === "" && mesa.value === "")
      return setAlert("Falta el número de mesa o habitación.", "danger");
    setPrompt(true);
  };

  const submitCheque = async (user) => {
    if (habitacion.value !== "" || mesa.value !== "") {
      setLoading(true);
      const nCheque = await nuevoCheque(
        JSON.stringify({
          mesa: parseInt(mesa.value),
          roomservice: room.checked ? 1 : 0,
          habitacion: parseInt(habitacion.value),
          aperturo: user,
        })
      );
      if (nCheque !== undefined) {
        setAlert("Nuevo Cheque Abierto", "success");
        navigate(`/rcargos/${nCheque.id}`);
      } else {
        setAlert("Nuevo Cheque Error", "danger");
      }
    }
  };

  return (
    <>
      <Prompt
        onClose={setPrompt}
        show={prompt}
        setKey={setCajero}
        onValidKey={submitCheque}
      />
      <form onSubmit={submitClave} className="grid-6">
        <div>
          <label>R. SERVICE</label>
          <br />
          <input {...room} className="w-100" />
        </div>
        <div>
          <label>HABITACION</label>
          <input {...habitacion} disabled={room.checked ? false : true} />
        </div>
        <div>
          <label>N° MESA</label>
          <input
            {...mesa}
            disabled={room.checked ? true : false}
            min="1"
            max="55"
          />
        </div>
        <div>
          <button type="submit" className="btn-primary">
            Nuevo Cheque
          </button>
        </div>
      </form>
    </>
  );
};

export default NuevoCheque;
