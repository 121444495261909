import React, { useReducer } from "react";
import axios from "axios";
import AutContext from "./autContext.js";
import AutReducer from "./autReducer.js";
import { LOG_FEEDBACK, LIMPIAR_LOG_FEEDBACK, SET_LOADING } from "../types.js";

const AutState = (props) => {
	//state inicial
	const initialState = {
		logstatus: { loged: false, role: null },
		autloading: false,
	};
	//declaracion de reducer
	const [state, dispatch] = useReducer(AutReducer, initialState);

	//funcion de logueo
	const login = (user, password) => {
		setautLoading();
		const body = {
			username: user,
			password: password,
		};
		axios
			.post("/api/sesiones", body, {
				headers: { "Content-Type": "application/json" },
			})
			.then(async (resp) => {
				if (resp.data.success) {
					await window.localStorage.setItem(
						"access_token",
						resp.data.data.access_token
					);
					await window.localStorage.setItem(
						"refresh_token",
						resp.data.data.refresh_token
					);
					console.log(resp.data.data);

					const accessXpire = new Date();
					accessXpire.setSeconds(
						accessXpire.getSeconds() + resp.data.data.access_token_expires_in - "120"
					);
					const refreshXpire = new Date();
					refreshXpire.setSeconds(
						refreshXpire.getSeconds() + resp.data.data.refresh_token_expires_in - "60"
					);
					window.localStorage.setItem("access_token_expires_in", accessXpire);
					window.localStorage.setItem("refresh_token_expires_in", refreshXpire);
					window.localStorage.setItem("session", resp.data.data.session_id);
					window.localStorage.setItem("usrtag", resp.data.data.usrtag);
					window.localStorage.setItem("user", resp.data.data.user_id);
					window.localStorage.setItem("loged", true);
					window.localStorage.setItem("role", resp.data.data.role);
				}
				//construccion de la salida al state
				const logStatus = {
					loged: true,
					role: resp.data.data.admin,
					mensaje: "Ha ingresado con éxito",
					color: "success",
				};
				dispatch({ type: LOG_FEEDBACK, payload: logStatus });
			})
			.catch((err) => {
				console.log(err.response);

				const logStatus = {
					loged: false,
					mensaje: err.response.data.messages,
					color: "danger",
				};
				dispatch({ type: LOG_FEEDBACK, payload: logStatus });
			});
	};

	// decraración de la función de deslogueo
	const logout = () => {
		const sessionId = window.localStorage.getItem("session");
		const accessToken = window.localStorage.getItem("access_token");
		axios
			.delete(`api/sesiones/${sessionId}`, {
				headers: {
					Authorization: `${accessToken}`,
				},
			})
			.then((resp) => {
				const logStatus = {
					loged: false,
					role: null,
					mensaje: resp.data.messages[0],
					color: "success",
				};
				window.localStorage.clear();
				window.localStorage.setItem("loged", false);
				dispatch({ type: LOG_FEEDBACK, payload: logStatus });
			})
			.catch((err) => {
				const msj = err.response.data.messages;
				const logStatus = {
					loged: false,
					role: null,
					mensaje: msj,
					color: "danger",
				};
				dispatch({ type: LOG_FEEDBACK, payload: logStatus });
			});
	}; //termina login

	//refresh
	const refreshSession = async () => {
		let respuesta = {};
		try {
			const now = new Date();
			const ahora = Date.parse(now);
			const accessXpire = Date.parse(
				localStorage.getItem("access_token_expires_in")
			);
			const refreshXpire = Date.parse(
				localStorage.getItem("refresh_token_expires_in")
			);
			if (localStorage.getItem("access_token_expires_in") === null) {
				respuesta = {
					mensaje: "su session ha expirado vuelva a ingresar",
					status: false,
				};
			} else if (ahora > refreshXpire) {
				respuesta = {
					mensaje: "su session ha expirado vuelva a ingresar",
					status: false,
				};
				window.localStorage.setItem("loged", false);
			} else if (ahora > accessXpire) {
				const patchaccessToken = localStorage.getItem("access_token");
				const patchrefreshToken = localStorage.getItem("refresh_token");
				const session = localStorage.getItem("session");
				const body = { refresh_token: patchrefreshToken };
				const resp = await axios.patch(`/api/sesiones/${session}`, body, {
					headers: {
						Authorization: `${patchaccessToken}`,
						"Content-Type": "application/json",
					},
				});
				if (resp.data.success) {
					window.localStorage.setItem("access_token", resp.data.data.accesstoken);
					window.localStorage.setItem("refresh_token", resp.data.data.refreshtoken);
					const newaccessXpire = new Date();
					newaccessXpire.setSeconds(
						newaccessXpire.getSeconds() + resp.data.data.access_token_expiry - "120"
					);
					window.localStorage.setItem("access_token_expires_in", newaccessXpire);
					const newrefreshXpire = new Date();
					newrefreshXpire.setSeconds(
						newrefreshXpire.getSeconds() + resp.data.data.refresh_token_expiry - "60"
					);
					window.localStorage.setItem("refresh_token_expires_in", newrefreshXpire);
					window.localStorage.setItem("session", resp.data.data.sessionid);
					window.localStorage.setItem("user", resp.data.data.user_id);
					window.localStorage.setItem("usrtag", resp.data.data.usrtag);
					window.localStorage.setItem("role", resp.data.data.role);
					//construccion de la salida al state
					const logStatus = {
						loged: true,
						role: resp.data.data.role,
						mensaje: "sesion actualizada",
						color: "success",
					};
					dispatch({ type: LOG_FEEDBACK, payload: logStatus });
					respuesta = { mensaje: "Session actualizada", status: true };
				}
			} else {
				respuesta = { mensaje: "Session activa", status: true };
			}
		} catch (error) {
			respuesta = error.response;
		}
		console.log(respuesta);

		return respuesta;
	}; // termina refresh

	// limpiar feedback
	const limpiarLogFeed = () => {
		dispatch({ type: LIMPIAR_LOG_FEEDBACK });
	};

	// loading
	const setautLoading = () => {
		dispatch({ type: SET_LOADING });
	};

	//isloged
	const isloged = () => {
		const accessx = localStorage.getItem("access_token_expires_in");
		const role = localStorage.getItem("role");
		if (accessx !== null) {
			const expi = Date.parse(accessx);
			const now = Date.now();
			console.log(now);
			if (now > expi) {
				refreshSession();
			} else {
				//construccion de la salida al state
				const logStatus = {
					loged: true,
					role: role,
					mensaje: "Ha ingresado con éxito",
					color: "success",
				};
				dispatch({ type: LOG_FEEDBACK, payload: logStatus });
			}
		} else {
			//construccion de la salida al state
			const logStatus = {
				loged: false,
				role: false,
				mensaje: "bienvendio por favor Ingrese o registrese",
				color: "success",
			};
			dispatch({ type: LOG_FEEDBACK, payload: logStatus });
		}
	};

	//return provider
	return (
		<AutContext.Provider
			value={{
				logstatus: state.logstatus,
				autloading: state.autloading,
				login,
				logout,
				limpiarLogFeed,
				setautLoading,
				refreshSession,
				isloged,
			}}
		>
			{props.children}
		</AutContext.Provider>
	);
};

export default AutState;
