import React, { useContext, useEffect, useState } from "react";
import CargoContext from "../../contexto/cargo/cargoContext";
import Abonostdlight from "./Abonostdlight";

const Abonosthlight = ({ fecha, turno }) => {
	///////////////////////////////////////////////
	// CONTEXT
	const cargoContext = useContext(CargoContext);
	const { traerAbonos, abonos } = cargoContext;
	let totalCargos = 0;
	let totalAbonos = 0;
	let totalFacturas = 0;
	let totalEfectivo = 0;
	let totalTransfer = 0;
	let totalTarjetas = 0;
	let totalCXC = 0;
	let totalReembolsos = 0;
	let totalCreditos = 0;
	let totalDebitos = 0;
	let dummie = [
		{
			id: 1,
			folio: 0,
			modo_pago: "sin abonos",
			factura: "sin facturas",
			suma: 0,
			hab: "S/H",
			cajero: "sin cajero",
			nombre: "sin registros",
			fecha: "sin fechas",
		},
	];

	///////////////////////////////
	//formato de numero
	const moneyForm = (raw) => {
		let numero = parseFloat(raw);
		if (numero < 0) numero = Math.abs(numero);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};
	console.log(totalEfectivo);
	useEffect(() => {
		if (Object.keys(abonos).length === 0) {
		}
		console.log(abonos);
	}, [abonos]);

	return (
		<>
			<h3 className='pCarta'>
				Corte de Caja {fecha + (turno.length > 0 ? " Turno" + turno : "")}
			</h3>
			<table
				className='w-100 pCarta printabla'
				style={{ pageBreakAfter: "always" }}
			>
				<tr className='stickiehead'>
					<th>Fact.</th>
					<th>Consec.</th>
					<th>Fecha</th>
					<th>Hab</th>
					<th>Nombre</th>
					<th>Facturado</th>
					<th>Efectivo</th>
					<th>Tarjeta</th>
					<th>CxC</th>
					<th>Rembolsos</th>
					<th>Debitos</th>
					<th>Creditos</th>
					<th>Cajero</th>
				</tr>
				{abonos.length > 0
					? abonos.map((res, i) => {
							if (
								res.modo_pago == "Efectivo" ||
								res.modo_pago == "Tarjeta de Credito" ||
								res.modo_pago == "Tarjeta de Debito" ||
								res.modo_pago == "Transferencia" ||
								res.modo_pago == "Reembolso" ||
								res.factura !== ""
							) {
								return <Abonostdlight key={i} abono={res} />;
							}
					  })
					: dummie.map((res, i) => <Abonostdlight key={i} abono={res} />)}

				<tr>
					<td colSpan='4' />
					<th scope='row' className='small'>
						Totales
					</th>
					<th scope='row' className='small'>
						Facturado
					</th>
					<th scope='row' className='small'>
						Efectivo
					</th>
					<th scope='row' className='small'>
						Tarjetas
					</th>
					<th scope='row' className='small'>
						CxC
					</th>
					<th scope='row' className='small'>
						Reembolsos
					</th>
					<th scope='row' className='small'>
						Debitos
					</th>
					<th scope='row' className='small'>
						Creditos
					</th>
					<th></th>
				</tr>
				<tr>
					<td colSpan='5' />
					<td>
						{abonos.map((row, i) => {
							row.factura !== "" && (totalFacturas = totalFacturas + row.suma);
						})}
						{moneyForm(totalFacturas)}
					</td>
					<td>
						{abonos.map((row, i) => {
							if (
								row.modo_pago === "Efectivo" &&
								row.factura === "" &&
								row.status !== 1
							) {
								totalEfectivo = +totalEfectivo + +row.suma;
							} else if (
								row.factura !== "" &&
								row.alcierre &&
								typeof row.efectivo !== "undefined"
							) {
								totalEfectivo = +totalEfectivo + +row.efectivo;
							}
						})}
						{moneyForm(totalEfectivo)}
					</td>
					<td>
						{abonos.map((row, i) => {
							if (
								(row.modo_pago === "Tarjeta de Credito" ||
									row.modo_pago === "Tarjeta de Debito") &&
								row.factura == "" &&
								row.status !== 1 &&
								!row.alcierre
							) {
								totalTarjetas = +totalTarjetas + +row.suma;
							} else if (
								row.factura !== "" &&
								row.status !== 1 &&
								row.alcierre &&
								typeof row.tarjetas !== "undefined"
							) {
								totalTarjetas = +totalTarjetas + row.tarjetas;
							}
						})}
						{moneyForm(totalTarjetas)}
					</td>
					<td>
						{abonos.map((row, i) => {
							row.modo_pago === "CXC" &&
								row.status !== 1 &&
								(totalCXC = +totalCXC + +row.cxc);
						})}
						{moneyForm(totalCXC)}
					</td>
					<td>
						{abonos.map((row, i) => {
							row.modo_pago === "Reembolso" &&
								row.status !== 1 &&
								(totalReembolsos = +totalReembolsos + +row.suma);
							if (typeof row.abonos !== "undefined") {
								row.abonos.map((row2, i) => {
									row2.metodo_pago === "Reembolso" &&
										row2.status !== 1 &&
										(totalReembolsos = +totalReembolsos + +row2.abono);
								});
							}
						})}
						{moneyForm(totalReembolsos)}
					</td>
					<td>
						{/*tDebitos*/}

						{abonos.map((row, i) => {
							if (
								row.factura !== "" &&
								//row.modo_pago !== "cuentas por cobrar" &&
								//row.modo_pago !== "CXC" &&
								row.status !== 1 &&
								typeof row.debito !== "undefined"
							) {
								totalDebitos = totalDebitos + row.debito;
							}
						})}
						{moneyForm(totalDebitos)}
					</td>
					<td>
						{/*tcreditos*/}
						{abonos.map((row, i) => {
							if (
								(row.modo_pago === "Tarjeta de Credito" &&
									row.factura == "" &&
									row.status !== 1) ||
								(row.modo_pago === "Tarjeta de Debito" &&
									row.factura == "" &&
									row.status !== 1) ||
								(row.modo_pago === "Efectivo" && row.factura == "" && row.status !== 1)
							) {
								totalCreditos = +totalCreditos + +row.suma;
							}
						})}
						{moneyForm(totalCreditos)}
					</td>
					<td />
				</tr>
			</table>
		</>
	);
};

export default Abonosthlight;
