import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import "./Realstyle.scss";
//componentes
import Header from "./componentes/elementos/Header.js";
import Alert from "./componentes/elementos/Alert";
//contextos
import AutContext from "./contexto/autentificacion/autContext";
//rutas
import PrivateRoutes from "./componentes/elementos/PrivateRoutes";
import Inicio from "./componentes/rutas/Inicio.js";
import Reservaciones from "./componentes/rutas/Reservaciones.js";
import Registros from "./componentes/rutas/Registros.js";
import Cuentas from "./componentes/rutas/Cuentas.js";
import Consultas from "./componentes/rutas/Consultas.js";
import Cargos from "./componentes/rutas/Cargos.js";
import Facturas from "./componentes/rutas/Facturas.js";
import Facturacion from "./componentes/rutas/Facturacion.js";
import Cafeteria from "./componentes/rutas/Cafeteria.js";
import Rcargos from "./componentes/rutas/Rcargos.js";
import Cortes from "./componentes/rutas/Corte.js";
import Rack from "./componentes/elementos/Rack.js";
import Descargafacturas from "./componentes/rutas/Descargafacturas.js";
import Auditoria from "./componentes/rutas/Aditoria.js";
import Empresas from "./componentes/rutas/Empresas";
import Complemento from "./componentes/rutas/Complemento.js";
import Login from "./componentes/rutas/Login.js";
import Cancelaciones from "./componentes/rutas/Cancelaciones.js";
import Tutoriales from "./componentes/rutas/Tutoriales.js";
//states
import ReservaState from "./contexto/reservacion/ReservaState.js";
import AlertState from "./contexto/alerta/AlertState.js";
import CuentaState from "./contexto/cuenta/CuentaState.js";
import RegistroState from "./contexto/registro/RegistroState.js";
import CargoState from "./contexto/cargo/CargoState.js";
import CafeteriaState from "./contexto/cafeteria/cafeteriaState.js";
import FacturacionSatate from "./contexto/facturacion/FacturacionState.js";
import AutState from "./contexto/autentificacion/AutState.js";
import UserState from "./contexto/usuario/UserState.js";

function App() {
	let prox = require("../package.json");
	return (
		<AutState>
			<UserState>
				<AlertState>
					<FacturacionSatate>
						<CafeteriaState>
							<CuentaState>
								<CargoState>
									<ReservaState>
										<RegistroState>
											<Router>
												<div className='App'>
													<Header />
													<div className='container noprint'>
														<p style={{ color: "grey", fontSize: "10px", float: "right" }}>
															v.1 {prox.proxy}
														</p>
													</div>
													<div className='container main'>
														<Alert />
														<Routes>
															<Route element={<PrivateRoutes />}>
																<Route exact path='/' element={<Inicio />} />
																<Route exact path='/empresas' element={<Empresas />} />
																<Route
																	exact
																	path='/reservaciones'
																	element={<Reservaciones />}
																/>
																<Route exact path='/Registros' element={<Registros />} />
																<Route exact path='/cuentas' element={<Cuentas />} />
																<Route exact path='/consultas' element={<Consultas />} />
																<Route exact path='/rack' element={<Rack />} />
																<Route exact path='/cargos/:folio' element={<Cargos />} />
																<Route exact path='/auditoria' element={<Auditoria />} />
																<Route
																	exact
																	path='/facturacion/:folio'
																	element={<Facturas />}
																/>
																<Route exact path='/Descargas' element={<Descargafacturas />} />
																<Route
																	exact
																	path='/cfacturacion/:id'
																	element={<Facturacion serie='B' />}
																/>
																<Route
																	exact
																	path='/cfacturacion'
																	element={<Facturacion serie='C' />}
																/>
																<Route exact path='/cafeteria' element={<Cafeteria />} />
																<Route exact path='/rcargos/:id' element={<Rcargos />} />
																<Route exact path='/Cortes' element={<Cortes />} />
																<Route exact path='/Complemento' element={<Complemento />} />
																<Route exact path='/tutoriales' element={<Tutoriales />} />
																<Route
																	exact
																	path='/cancelaciones'
																	element={<Cancelaciones />}
																/>
															</Route>
															<Route exact path='/login' element={<Login />} />
														</Routes>
													</div>
												</div>
											</Router>
										</RegistroState>
									</ReservaState>
								</CargoState>
							</CuentaState>
						</CafeteriaState>
					</FacturacionSatate>
				</AlertState>
			</UserState>
		</AutState>
	);
}

export default App;
