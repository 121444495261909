import React, { useReducer } from "react";
import AlertContext from "./alertContext.js";
import AlertReducer from "./alertReducer.js";
import { SET_ALERT, REMOVE_ALERT } from "../types.js";

const AlertState = (props) => {
	const initialState = null;
	const [state, dispatch] = useReducer(AlertReducer, initialState);

	//metodo de definir alerta
	const setAlert = (msg, type) => {
		dispatch({ type: SET_ALERT, payload: { msg, type } });
		setTimeout(() => dispatch({ type: REMOVE_ALERT }), 6000);
	};

	return (
		<AlertContext.Provider
			value={{
				alert: state,
				setAlert,
			}}
		>
			{props.children}
		</AlertContext.Provider>
	);
};
export default AlertState;
