export const conceptos = [
  {
    ClaveProdServ: "90111603",
    NoIdentificacion: "",
    Cantidad: "1",
    ClaveUnidad: "E48",
    Unidad: "Servicio",
    Descripcion: "SERVICIO DE COFFEE BREAK",
    ValorUnitario: "",
    Importe: "",
    ObjetoImp: "02",
    Impuestos: {
      Traslados: [
        {
          Base: "",
          Impuesto: "",
          TipoFactor: "Tasa",
          TasaOCuota: "0.160000",
          Importe: "",
        },
      ],
    },
  },
  {
    ClaveProdServ: "90101501",
    NoIdentificacion: "",
    Cantidad: "1",
    ClaveUnidad: "E48",
    Unidad: "Servicio",
    Descripcion: "ALIMENTOS",
    ValorUnitario: "",
    Importe: "",
    ObjetoImp: "02",
    Impuestos: {
      Traslados: [
        {
          Base: "",
          Impuesto: "",
          TipoFactor: "Tasa",
          TasaOCuota: "0.160000",
          Importe: "",
        },
      ],
    },
  },
  {
    ClaveProdServ: "90101501",
    NoIdentificacion: "",
    Cantidad: "1",
    ClaveUnidad: "E48",
    Unidad: "Servicio",
    Descripcion: "CENA",
    ValorUnitario: "",
    Importe: "",
    ObjetoImp: "02",
    Impuestos: {
      Traslados: [
        {
          Base: "",
          Impuesto: "",
          TipoFactor: "Tasa",
          TasaOCuota: "0.160000",
          Importe: "",
        },
      ],
    },
  },
  {
    ClaveProdServ: "90111603",
    NoIdentificacion: "",
    Cantidad: "1",
    ClaveUnidad: "E48",
    Unidad: "Servicio",
    Descripcion: "COMIDA",
    ValorUnitario: "",
    Importe: "",
    ObjetoImp: "02",
    Impuestos: {
      Traslados: [
        {
          Base: "",
          Impuesto: "",
          TipoFactor: "Tasa",
          TasaOCuota: "0.160000",
          Importe: "",
        },
      ],
    },
  },
  {
    ClaveProdServ: "90111603",
    NoIdentificacion: "",
    Cantidad: "1",
    ClaveUnidad: "E48",
    Unidad: "Servicio",
    Descripcion: "COCKTAIL DE BIENVENIDA",
    ValorUnitario: "",
    Importe: "",
    ObjetoImp: "02",
    Impuestos: {
      Traslados: [
        {
          Base: "",
          Impuesto: "",
          TipoFactor: "Tasa",
          TasaOCuota: "0.160000",
          Importe: "",
        },
      ],
    },
  },
  {
    ClaveProdServ: "90101501",
    NoIdentificacion: "",
    Cantidad: "1",
    ClaveUnidad: "E48",
    Unidad: "Servicio",
    Descripcion: "CONSUMOS",
    ValorUnitario: "",
    Importe: "",
    ObjetoImp: "02",
    Impuestos: {
      Traslados: [
        {
          Base: "",
          Impuesto: "",
          TipoFactor: "Tasa",
          TasaOCuota: "0.160000",
          Importe: "",
        },
      ],
    },
  },
  {
    ClaveProdServ: "90101501",
    NoIdentificacion: "",
    Cantidad: "1",
    ClaveUnidad: "E48",
    Unidad: "Servicio",
    Descripcion: "DESAYUNO",
    ValorUnitario: "",
    Importe: "",
    ObjetoImp: "02",
    Impuestos: {
      Traslados: [
        {
          Base: "",
          Impuesto: "",
          TipoFactor: "Tasa",
          TasaOCuota: "0.160000",
          Importe: "",
        },
      ],
    },
  },
  {
    ClaveProdServ: "90101501",
    NoIdentificacion: "",
    Cantidad: "1",
    ClaveUnidad: "E48",
    Unidad: "Servicio",
    Descripcion: "EVENTO",
    ValorUnitario: "",
    Importe: "",
    ObjetoImp: "02",
    Impuestos: {
      Traslados: [
        {
          Base: "",
          Impuesto: "",
          TipoFactor: "Tasa",
          TasaOCuota: "0.160000",
          Importe: "",
        },
      ],
    },
  },
  {
    ClaveProdServ: "90111800",
    NoIdentificacion: "",
    Cantidad: "1",
    ClaveUnidad: "E48",
    Unidad: "Servicio",
    Descripcion: "HOSPEDAJE",
    ValorUnitario: "",
    Importe: "",
    ObjetoImp: "02",
    Impuestos: {
      Traslados: [
        {
          Base: "",
          Impuesto: "",
          TipoFactor: "Tasa",
          TasaOCuota: "0.160000",
          Importe: "",
        },
      ],
    },
  },
  {
    ClaveProdServ: "90111601",
    NoIdentificacion: "",
    Cantidad: "1",
    ClaveUnidad: "E48",
    Unidad: "Servicio",
    Descripcion: "Propina",
    ValorUnitario: "",
    Importe: "",
    ObjetoImp: "02",
    Impuestos: {
      Traslados: [
        {
          Base: "",
          Impuesto: "",
          TipoFactor: "Tasa",
          TasaOCuota: "0.000000",
          Importe: "0",
        },
      ],
    },
  },
];
